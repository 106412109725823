import React, { Fragment } from 'react';
import { connect } from 'dva';
import { Spin } from 'antd';
import { routeMapStateToProps } from '@/util/helper';

@connect(routeMapStateToProps)
export default class BaseLayout extends React.Component<any> {
    state = {
        init: false
    };

    async componentDidMount() {
        const {
            login,
            history,
            location = {},
            dispatch
        } = this.props;

        const loginPath = '/login';
        const redirectUrl = location.pathname === loginPath
            ? `/login${location.search}`
            : (location.pathname + location.search);
        const host = window.location.host;
        if (login.status !== 1) {
            try {
                const res = await dispatch({
                    type: 'login/getLoginStatus'
                });

                this.setState({
                    init: res
                });

                history.push(redirectUrl);
            } catch (err) {
                if (err.code === 301) {
                    history.push(`/login${location.search}`, {
                        redirectUrl: location.pathname,
                        host
                    });
                    this.setState({
                        init: err
                    });
                }
            }
        }
    }

    render() {
        if (this.state.init) {
            return <Fragment>{this.props.children}</Fragment>;
        }
        return <Spin style={{
            padding: '100px 50%'
        }} />;
    }
}
