import { API_BASE } from '@/util/const';
import fetch from '@/util/fetch';

export const getLoginStatus = () => {
    return fetch('/api/pms/login/status');
};

export const getUserPersission = (data: any) => fetch(`${API_BASE}/user/getUserInfo`, {
    method: 'post',
    data: {
        userId: data.id,
        name: data.name,
        nickName: data.nickName,
        mailAddress: data?.mailAddress,
        hzNumber: data.hzNumber,
    },
});

export const pmsLogin = (data: any) => {
    return fetch('/api/pms/login', {
        data: {
            redirectUrl: data.redirectUrl,
            fromHost: data.fromHost
        }
    });
};

export const musicLogin = (data: any) => {
    return fetch('/api/pms/music/login/redirect', {
        data: {
            account: data.account,
            password: data.password,
            captchaId: data.captchaId,
            captchavalue: data.captchavalue
        }
    });
};

export const verifyCaptcha = (data: any) => {
    return fetch('/api/image/captcha/verify/v1', {
        data: {
            captchaId: data.captchaId,
            captchaValue: data.captchaValue
        }
    });
};

export const getCaptcha = () => {
    return fetch('/api/image/captcha/get/v1');
};

export const logout = () => {
    return fetch('/api/pms/logout');
};
