import { API_BASE } from '@/util/const';
import fetch from '@/util/fetch';
import {
    IServiceResult,
    IAddUserSceneModal,
    IApply,
    Irepeal,
} from '@/typings/IServiceModel';

// 申请应用权限
export const applyUserScene = (data: IAddUserSceneModal): PromiseLike<IServiceResult> => fetch(`${API_BASE}/apply/applyScene`, {
    method: 'post',
    data
});

// 审核
export const updateApply = (data: IApply): PromiseLike<IServiceResult> => fetch(`${API_BASE}/apply/updateApply`, {
    method: 'post',
    data
});
// 重新申请
export const againApply = (data: IApply): PromiseLike<IServiceResult> => fetch(`${API_BASE}/apply/againApply`, {
    method: 'post',
    data
});

// 获取单个用户所有权限列表包括审核未通过
export const getUserApplyList = (data: { userId?: number, status?: number }): PromiseLike<IServiceResult> => fetch(`${API_BASE}/apply/getList`, {
    data
});

// 审核中心获取待审核列表
export const getApplyCheckList = (data: { userId?: number, status?: number, userInfo?: string }): PromiseLike<IServiceResult> => fetch(`${API_BASE}/apply/getApplyCheckList`, {
    data
});

// 撤销申请
export const deleteApply = (data: Irepeal): PromiseLike<IServiceResult> => fetch(`${API_BASE}/apply/deleteApply`, {
    method: 'delete',
    data
});
