import * as service from '@/service/log';
import {
    ILogListModelState,
    ILogListModel,
    ISceneModelState,
    IServiceListResult,
    ILog,
} from '@/typings/IServiceModel';
import { pick } from 'lodash';

const logList: ILogListModel = {
    namespace: 'logList',

    state: {
        data: [],
        limit: 10,
        offset: 0,
        total: 0,
    },

    effects: {
        * get(action, { call, put, select }) {
            try {
                const payload = action.payload;
                const { limit, offset } = yield select((state: { logList: ILogListModelState }) => state.logList);
                const sceneId = yield select((state: { scene: ISceneModelState }) => state.scene.id);
                const params = Object.assign({
                    limit,
                    offset,
                    id: sceneId
                }, payload);
                const result: IServiceListResult = yield call(service.getList, params);

                if (!result.data) {
                    throw new Error('数据获取错误');
                }
                yield put({
                    type: 'setState',
                    payload: {
                        ...pick(params, ['offset', 'limit']),
                        data: result.data.list as ILog[],
                        total: result.data.total as number,
                    },
                });
                return true;
            } catch (err) {
                yield put({
                    type: 'setState',
                    payload: {
                        data: [],
                    },
                });
                throw err;
            }
        },
    },

    reducers: {
        setState(state, { payload }) {
            return { ...state, ...payload };
        },
    },
};

export default logList;
