/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
    Modal, Button, Table, message, Popconfirm
} from 'antd';
import { IUser } from '@/typings/IServiceModel';
import * as userService from '@/service/user';
import DebounceSelect, { UserValue } from './components/debounceSelect';

import styles from './editModal.module.less';

interface IEditModalProps {
    login: any;
    modalVisible: boolean;
    setModalVisible: (visible: boolean) => void;
}

const EditSupAdmModal: React.FC<IEditModalProps> = ({
    login, modalVisible, setModalVisible
}) => {
    const [selectUser, setSelectUser] = useState<number>();
    const [listLoading, setListLoading] = useState(false);
    const [superAdmList, setSuperAdmAppList] = useState<IUser[]>([]);

    // 获取超管列表
    const getSupAdmList = async () => {
        setListLoading(true);
        const res = await userService.getSuperAdminList();
        setSuperAdmAppList(res?.data?.result || []);
        setListLoading(false);
    };

    // type 0 移除权限 1 添加权限
    const handleEditSupAdmPermission = async (type = 1, userId = -1) => {
        if (userId === -1) {
            message.error('请先选择用户！');
            return;
        }

        try {
            const res = await userService.editSuperAdmin({
                userId,
                permission: type === 1 ? 300 : 100,
            });

            if (res?.data?.result) {
                message.success(type === 1 ? '添加成功' : '移除成功');
                getSupAdmList();
            }
        } catch (err) {
            message.error('操作失败');
        }

    };

    // 获取用户自己的应用添加权限
    useEffect(() => {
        if (!modalVisible) return;
        getSupAdmList();
    }, [modalVisible]);

    const columns = [
        {
            title: '姓名',
            key: 'nickName',
            dataIndex: 'nickName',
        },
        {
            title: '邮箱',
            key: 'mailAddress',
            dataIndex: 'mailAddress',
        },
        {
            title: '操作',
            render: (it: IUser) => {
                const disabled = it.userId === login.userInfo.userId;
                return (
                    <Popconfirm
                        disabled={disabled}
                        placement="topRight"
                        title="是否确认移除该用户超管权限？"
                        onConfirm={() => handleEditSupAdmPermission(0, it.userId)}
                        okText="是"
                        cancelText="否">
                        <Button disabled={disabled} type="link">移除超管权限</Button>
                    </Popconfirm>
                );
            }
        },
    ];

    async function fetchUserList(username: string): Promise<UserValue[]> {
        const res = await userService.getUserList({
            pms: -1,
            sceneId: -1,
            userInfo: username,
            limit: 10,
            offset: 0,
        });
        return (res?.data?.result || []).map((item: IUser) => ({
            label: item.nickName,
            value: item.userId
        }));
    }

    return (
        <Modal
            title="超管权限编辑"
            width={800}
            visible={modalVisible}
            onCancel={() => setModalVisible(false)}
            footer={null}>
            <div className={styles.modalContainer}>
                <div style={{ paddingBottom: 20 }}>
                    <span>用户：</span>
                    <DebounceSelect
                        showSearch
                        placeholder="请输入用户姓名或邮箱"
                        value={selectUser}
                        fetchOptions={fetchUserList}
                        onChange={(newValue) => {
                            setSelectUser(newValue as number);
                        }}
                        style={{ width: 220 }} />
                    <Button type="danger" style={{ marginLeft: 10 }} onClick={() => handleEditSupAdmPermission(1, selectUser || -1)}>添加超管</Button>
                </div>
                <h3>超管列表：</h3>
                <Table
                    rowKey="userId"
                    pagination={false}
                    loading={listLoading}
                    columns={columns}
                    dataSource={superAdmList} />
            </div>
        </Modal>
    );
};

export default EditSupAdmModal;
