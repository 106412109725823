import * as sceneService from '@/service/scene';
import { IScene, ISceneListModel, IServiceListResult } from '@/typings/IServiceModel';
import { IAction } from '@/typings/IDva';

// 曲库标签数据
const sceneList: ISceneListModel = {
    namespace: 'sceneList',

    state: {
        data: [],
        limit: 1000,
        offset: 0,
        total: 0,
    },

    effects: {
        * get(action: IAction, { call, put, select }) {
            try {
                const payload = action.payload;
                const { limit, offset } = yield select((state: { sceneList: any; }) => state.sceneList);
                const result: IServiceListResult = yield call(sceneService.getList, {
                    limit,
                    offset,
                    ...payload,
                });

                if (!result.data) {
                    throw new Error('数据获取错误');
                }

                yield put({
                    type: 'setState',
                    payload: {
                        data: result.data.list as IScene[],
                        total: result.data.total as number,
                    },
                });
                return true;
            } catch (err) {
                yield put({
                    type: 'setState',
                    payload: {
                        data: [],
                        total: 0,
                    },
                });
                throw err;
            }
        },
    },

    reducers: {
        setState(state, { payload }) {
            return { ...state, ...payload };
        },
    },
};

export default sceneList;
