import * as service from '@/service/package';
import {
    IServiceListResult, IPackageListModel, ISceneModelState, IPackage, IdParams,

} from '@/typings/IServiceModel';
import { pick } from 'lodash';
import { IAction } from '@/typings/IDva';

// 曲库标签数据
const sceneList: IPackageListModel = {
    namespace: 'packageList',

    state: {
        data: [],
        limit: 10,
        offset: 0,
        total: 0,
    },

    effects: {
        * get(action: IAction, { call, put, select }) {
            try {
                const payload = action.payload;
                const { limit, offset } = yield select((state: { packageList: IPackageListModel}) => state.packageList);
                const sceneId = yield select((state: { scene: ISceneModelState}) => state.scene.id);
                const params = Object.assign({
                    limit,
                    offset,
                    id: sceneId,
                }, payload);
                const result: IServiceListResult = yield call(service.getList, params);

                if (!result.data) {
                    throw new Error('数据获取错误');
                }

                yield put({
                    type: 'setState',
                    payload: {
                        ...pick(params, ['offset', 'limit']),
                        data: result.data.list as IPackage[],
                        total: result.data.total as number,
                    },
                });
                return true;
            } catch (err) {
                yield put({
                    type: 'setState',
                    payload: {
                        data: [],
                        // total: 0,
                    },
                });
                throw err;
            }
        },
        * create(action, { call, select }) {
            const sceneId = yield select((state: { scene: ISceneModelState}) => state.scene.id);
            const payload = action.payload as IdParams;
            const params: IdParams = Object.assign({
                id: sceneId,
            }, payload);
            return yield call(service.create, params);
        },
    },

    reducers: {
        setState(state, { payload }) {
            return { ...state, ...payload };
        },
    },
};

export default sceneList;
