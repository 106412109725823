import React from 'react';
import { Button, message, Modal } from 'antd';
import I18nReact from '@music/mobile-i18n';
import * as documentSetService from '@/service/documentSet';
import * as sceneService from '@/service/scene';

import { downloadXLSX } from '@/util/helper';
import { ExcelDocumentMap } from '@/util/const';
import { IGroup, IDocumentSet } from '@/typings/IServiceModel';

interface IExportExcelProps {
    sceneId: number | undefined;
    groupId?: number | undefined;
    languages: any;
    i18n: any;
    selectedRows?: any[];
    groupList?: IGroup[];
    btnText?: string;
    filterLanguageCode?: string | undefined;
    isExportUnTransDocs?: boolean;
    isExportUnMatchVariable?: boolean;
}

interface IExportExcelState {
    loading: boolean;
}

export const exportFile = async ({
    list, languages, sceneId, isGoogle, callback
}: {
    list: any[], languages: any[], sceneId: number, isGoogle?: boolean, callback?: () => void
}) => {
    const trans = I18nReact.i18n.getFixedT(null, ['textManage']);
    const cols = ExcelDocumentMap.map((item) => item.key);
    if (languages.length) {
        languages.forEach((language: any) => {
            cols.push(`${language.desc}(${language.code})`);
        });
    }
    const data: any[] = [];
    if (list.length) {
        list.forEach((item: any) => {
            // 需对应 ExcelDocumentMap
            const document = [
                item.desc,
                item.imgDesc,
                item.group.key,
                item.key,
                item.type,
                item.client,
                item.creator,
            ];
            // 对应上方 languages
            languages.forEach((language: any) => {
                let text = '';
                item.documents.forEach((doc: any) => {
                    if (doc.languageCode === language.code) {
                        text = doc.text;
                    }
                });
                document.push(text);
            });
            data.push(document);
        });
    }
    const currentScene = await sceneService.getById({ id: sceneId });

    const exportName = `${trans('translate')}_${currentScene?.data?.name || ''}_${Date.now()}`;

    if (isGoogle) {
        documentSetService.exportGoogleSheets({
            columnNames: cols,
            rowDatas: data,
            fileName: exportName
        }).then((res: any) => {
            if (callback) callback();
            window.open(res.data.url, '_blank');
        });
    } else {
        if (callback) callback();
        downloadXLSX({
            columnNames: cols,
            rowDatas: data,
            fileName: exportName
        });
    }

    return {
        columnNames: cols,
        rowDatas: data,
        fileName: exportName
    };
};

const { confirm } = Modal;
export default class ExportExcel extends React.PureComponent<IExportExcelProps, IExportExcelState> {
    constructor(props: any) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    trans = () => I18nReact.i18n.getFixedT(null, ['textManage']);

    stopLoading = () => {
        message.destroy();
        Modal.destroyAll();
        this.setState({
            loading: false,
        });
    }

    exportExcel = (isGoogle = false) => {
        const {
            sceneId,
            languages,
            groupId,
            selectedRows,
            groupList,
            isExportUnTransDocs,
            filterLanguageCode,
            isExportUnMatchVariable,
        } = this.props;
        const { loading } = this.state;
        if (loading) return;
        message.loading('文档正在导出中，请勿重复点击');
        this.setState({
            loading: true,
        });
        const trans = this.trans();
        const isDownExcelFromAddress = /exportExcel=1/.test(window?.location?.search);
        const urlGroupId = window?.location?.pathname?.match(/\d{1,}$/);
        let finalGroupId: string | number;
        if (groupId) {
            finalGroupId = groupId;
        } else {
            finalGroupId = urlGroupId ? parseInt(urlGroupId[0], 10) : 0;
        }
        if (!sceneId) {
            message.error(trans('appIllegal'));
            this.stopLoading();
            return;
        }

        const formatRows = (row: IDocumentSet) => ({
            ...row,
            imgDesc: row.imgDesc ? `http://${row.imgDesc}` : '',
            group: {
                id: row.groupId,
                key: groupList?.find((group: IGroup) => group.id === row.groupId)?.key || ''
            }
        });

        if (isExportUnMatchVariable) {
            documentSetService.getUnMatchVariable({
                id: sceneId,
                client: 0,
                groupId: 0,
            }).then((res) => {
                const list = (res?.data?.list || []).map(formatRows);
                if (!list.length) {
                    message.info('不存在变量未匹配文案');
                } else {
                    exportFile({
                        list,
                        languages,
                        sceneId,
                        isGoogle,
                        callback: () => {
                            this.stopLoading();
                        }
                    });
                }
            });
        } else if (isExportUnTransDocs) {
            documentSetService.filter({
                id: sceneId,
                client: 0,
                isAll: true,
                filterLanguageCode: filterLanguageCode || 'all',
                groupId: 0
            }).then((res) => {
                const list = res?.data?.list || [];
                const unTransList = list.map(formatRows);
                exportFile({
                    list: unTransList,
                    languages,
                    sceneId,
                    isGoogle,
                    callback: () => {
                        this.stopLoading();
                    }
                });
            });
        } else if (selectedRows?.length) {
            const selectedList = selectedRows.map(formatRows);
            exportFile({
                list: selectedList,
                languages,
                sceneId,
                isGoogle,
                callback: () => {
                    this.stopLoading();
                }
            });
        } else {
            documentSetService.getAll({
                sceneId,
                groupId: isDownExcelFromAddress ? finalGroupId : groupId
            }).then((documentSet) => {
                const list = (documentSet && documentSet.data) ? documentSet.data.list : [];
                exportFile({
                    list,
                    languages,
                    sceneId,
                    isGoogle,
                    callback: () => {
                        this.stopLoading();
                    }
                });
            }).then(() => {
                if (isDownExcelFromAddress) {
                    window.location.href = `${window.location.origin}/${sceneId}/document/${finalGroupId}`;
                }
            });
        }
    }

    showConfirm = () => {
        confirm({
            title: '请选择导出方式',
            content: (
                <div>
                    <Button
                        style={{ marginRight: 20 }}
                        type="default"
                        onClick={() => { this.exportExcel(true); }}>
                        导出google sheets
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => { this.exportExcel(); }}>
                        导出excel
                    </Button>
                </div>
            ),
            okText: 'Yes',
            okType: 'danger',
            okButtonProps: {
                disabled: true,
            },
            cancelText: 'Cancel',
            onCancel() {},
        });
    };

    render() {
        const trans = this.trans();
        const { btnText } = this.props;
        const { loading } = this.state;
        return (
            <Button
                type="primary"
                disabled={loading}
                onClick={this.showConfirm}>
                {btnText || trans('exportExcel')}
            </Button>
        );
    }
}
