/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Input } from 'antd';
import {
    IDocumentSet
} from '@/typings/IServiceModel';

interface IKeyInputState {
    value: string;
    isEdit: boolean;
}

interface IKeyInputProp {
    value: string;
    style: any;
    onBlur: (record: IDocumentSet, values: { key: any }) => Promise<any>;
    record: IDocumentSet;
    disabled: boolean;
    highLightValue?: string;
}
class KeyInput extends React.PureComponent<IKeyInputProp, IKeyInputState> {
    state = {
        value: this.props.value,
        isEdit: false,
    };

    componentDidUpdate(prevProps: IKeyInputProp) {
        if (this.props.value !== prevProps.value) {
            this.setState({ value: this.props.value });
        }
    }

    onChange = (e: any) => {
        this.setState({
            value: e.currentTarget.value
        });
    }

    onEdit = () => {
        this.setState({
            isEdit: true,
            value: this.props.value,
        });
    }

    onBlur = (e: any) => {
        const { record, onBlur } = this.props;
        const key = e.currentTarget.value;
        this.setState({ isEdit: false });
        if (key !== this.props.value) {
            onBlur(record, { key }).catch(() => {
                this.setState({ value: record.key });
            });
        }
    }

    renderValue = ({
        value,
        highLightValue,
    }: {
        value?: string, highLightValue?: string
    }) => {
        if (highLightValue && value) {
            const arr = value.split(highLightValue);
            const length = arr.length;
            return arr.map((item, index) => (
                <>
                    {item}
                    {index < length - 1 ? (
                        <span style={{ backgroundColor: '#d16666', color: '#ffffff' }}>
                            {highLightValue}
                        </span>
                    ) : null}
                </>
            ));
        }
        return value;
    }

    render() {
        const {
            style,
            disabled,
            highLightValue,
        } = this.props;
        const { value, isEdit } = this.state;
        if (!isEdit) {
            return (
                <div
                    style={style}
                    // className={styles.textContainer}
                    title={value}
                    onClick={this.onEdit}>
                    {this.renderValue({
                        value, highLightValue
                    })}
                </div>
            );
        }
        return (
            <Input.TextArea
                ref={(input) => {
                    if (input) {
                        input.focus();
                    }
                }}
                autoSize={{ minRows: 1, maxRows: 6 }}
                disabled={disabled}
                style={style}
                value={this.state.value}
                // dangerouslySetInnerHTML={{
                //     __html: highLightValue ? value.replace(new RegExp(highLightValue, 'gi'), (regValue) => `<span style="background-color: #d8d8f6">${regValue}</span>`) : value
                // }}
                onChange={this.onChange}
                onBlur={this.onBlur} />
        );
    }
}

export default KeyInput;
