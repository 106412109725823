import { API_BASE } from '@/util/const';
import fetch from '@/util/fetch';
import {
    IServiceResult, IPageParams
} from '@/typings/IServiceModel';

export const getList = (data: IPageParams): PromiseLike<IServiceResult> => {
    const {
        id,
        ...rest
    } = data;
    return fetch(`${API_BASE}/groups/${id}/documentversions`, {
        data: {
            ...rest,
        },
    });
};

export const create = () => {};
