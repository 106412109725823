import React from 'react';
import { connect } from 'dva';
import { IRouteProps } from '@/typings/IRoute';
import { routeMapStateToProps } from '@/util/helper';
import { IScene } from '@/typings/IServiceModel';
import { Button, Select, Input, Table } from 'antd';
import moment from 'moment';
import * as userService from '@/service/user';
import EditModal from './editModal';
import EditSupAdmModal from './editSupAdmModal';
import styles from './style.module.less';

export interface IUserItem {
    userId: number;
    nickName: string;
    permission: number;
    mailAddress: string | null;
    lastModifyUser: number;
    scenes: any[];
}
interface IAuthorityProps {
    userApp: number; // 所属应用
    userPms: number; // 搜索权限
    clickUserApp: number;
    userInfoInput: string;
    editModalVisible: boolean; // 弹窗开关
    editSupAdmModalVisible: boolean; // 超管弹窗开关
    userList: IUserItem[]; // 列表用户
    editUserId: number; // 编辑弹窗用户id
    listLoading: boolean; // 列表加载状态
    isThird: boolean;
    userListPagination: {
        total: number,
        limit: number,
        offset: number,
    };
}

interface IUserIdentity {
    [key: number]: string;
}

@connect(routeMapStateToProps)
export default class Authority extends React.PureComponent<IRouteProps, IAuthorityProps> {

    state = {
        userApp: -1, // 选择应用-全部
        userPms: -1, // 选择权限-全部
        clickUserApp: -1, // 是否选择全部应用
        userInfoInput: '',
        editModalVisible: false,
        editSupAdmModalVisible: false,
        userList: [],
        editUserId: -1,
        listLoading: false,
        isThird: false,
        userListPagination: {
            total: 0,
            limit: 10,
            offset: 0,
        }
    };

    userIdentity: IUserIdentity = {
        100: '成员',
        200: '管理员',
        300: '超级管理员',
        50: '翻译人员',
    };

    columns = [
        {
            title: '姓名',
            key: 'nickName',
            dataIndex: 'nickName',
        },
        {
            title: '邮箱',
            key: 'mailAddress',
            dataIndex: 'mailAddress',
        },
        {
            title: '应用权限',
            render: (it: any): JSX.Element => {
                // 过滤默认应用 '0'
                const userAppList = it.scenes.filter((el: any) => (
                    el.aliasName !== '0'
                ));
                if (this.state.clickUserApp !== -1) {
                    return (
                        it.permission === 300 ? '超级管理员' : it.scenes.map((val: any) => (
                            <div key={val.id}>
                                {this.userIdentity[val.scene_user.pms]}
                            </div>
                        ))
                    );
                }
                return (
                    <div>
                        {
                            it.permission === 300 ? '超级管理员' : userAppList.length === 0 ? '无' : userAppList.map((val: any) => (
                                <div key={val.id}>
                                    {val.name}--{this.userIdentity[val.scene_user.pms as number]}
                                </div>
                            ))
                        }
                    </div>
                );
            }
        },
        {
            title: '最后修改人',
            key: 'lastModifyUser',
            dataIndex: 'lastModifyUser',
        },
        {
            title: '修改时间',
            key: 'updatedAt',
            dataIndex: 'updatedAt',
            render: (time: string): string => moment(time).format('YYYY-MM-DD HH:mm:ss'),
        },
        {
            title: '权限管理',
            render: (it: any): JSX.Element => {
                const { userId = 0, permission = 100, scenes = [] } = this.props.login.userInfo;
                const isAdmin = permission === 300 ? true : scenes.some((item) => item.scene_user.pms === 200); // 判断登录账户是否为管理员或超管
                const isSuperAdimin = it.permission === 300; // 用户为超管则不可编辑
                const isMine = it.userId === userId;
                return (
                    <div>
                        <Button disabled={isMine || isSuperAdimin ? true : !isAdmin} onClick={() => this.handleEditAuthority(it)} type="link">权限编辑</Button>
                    </div>
                );
            }
        },
    ];

    getUserInfoList = async (offset = -1, limit = -1) => {
        const { userPms, userApp, userInfoInput, userListPagination } = this.state;
        this.setState({
            listLoading: true
        });
        const res = await userService.getUserList({
            pms: userPms,
            sceneId: userApp,
            userInfo: userInfoInput,
            status: 2,
            limit: limit === -1 ? userListPagination.limit : limit,
            offset: offset === -1 ? userListPagination.offset : offset,
        });
        const userList = res?.data?.result || [];
        this.setState({
            userList: [...userList],
            listLoading: false,
            userListPagination: {
                ...userListPagination,
                offset: offset === -1 ? userListPagination.offset : offset,
                limit: limit === -1 ? userListPagination.limit : limit,
                total: res?.data?.total || 0
            }
        });
    }

    handleResetSearch = () => {
        this.setState({
            userApp: -1,
            userPms: -1,
            userInfoInput: ''
        });
    }

    setEditModalVisible = (visible: boolean) => {
        if (!visible) {
            const { offset, limit } = this.state.userListPagination;
            this.getUserInfoList(offset, limit);
        }
        this.setState({
            editModalVisible: visible
        });
    }

    setEditSupAdmModalVisible = (visible: boolean) => {
        if (!visible) {
            const { offset, limit } = this.state.userListPagination;
            this.getUserInfoList(offset, limit);
        }
        this.setState({
            editSupAdmModalVisible: visible
        });
    }

    // 打开编辑弹窗
    private handleEditAuthority = (it: any) => {
        this.setState({
            editUserId: it.userId,
            isThird: it.isThird,
        });
        this.setEditModalVisible(true);
    }
    // 所属应用select搜索内容框
    private renderPmsSelect = (): JSX.Element | null => {
        const { userApp, userPms } = this.state;
        const supAdmDisabled = userApp !== -1;
        const appAdmDisabled = userApp === -1;
        const userAdmDisabled = userApp === -1;
        return (
            <Select
                value={userPms}
                onChange={(value: number) => {
                    this.setState({
                        userPms: value
                    });
                }}
                style={{
                    width: 200,
                    marginRight: 20,
                }}>
                <Select.Option key="全部" value={-1}>全部</Select.Option>
                <Select.Option key="全部" disabled={supAdmDisabled} value={300}>超级管理员</Select.Option>
                <Select.Option key="全部" disabled={appAdmDisabled} value={200}>应用管理员</Select.Option>
                <Select.Option key="全部" disabled={userAdmDisabled} value={100}>成员</Select.Option>
            </Select>
        );
    }

    // 所属应用select搜索内容框
    private renderClientSelect = (): JSX.Element | null => {
        const { sceneList } = this.props;
        return (
            <Select
                value={this.state.userApp}
                onChange={(value: number) => {
                    const { userPms } = this.state;
                    let changedPms;
                    // 用于控制所属应用和用户权限的选择关系
                    if (value === -1 && (userPms === 200 || userPms === 100)) changedPms = -1;
                    else if (value !== -1 && userPms === 300) changedPms = -1;
                    else changedPms = userPms;
                    this.setState({
                        userApp: value,
                        userPms: changedPms
                    });
                }}
                style={{
                    width: 200,
                    marginRight: 20,
                }}>
                <Select.Option key="全部" value={-1}>
                    全部
                </Select.Option>
                {sceneList.map((item: IScene) => (
                    <Select.Option key={item.id} value={item.id}>
                        {item.name}
                    </Select.Option>
                ))}
            </Select>
        );
    }

    private handleSearch = async () => {
        await this.getUserInfoList(0);
        this.setState({
            clickUserApp: this.state.userApp
        });
    }

    componentDidMount() {
        this.getUserInfoList();
    }

    render() {
        const { login, sceneList } = this.props;
        const { userList, userInfoInput, editUserId, editModalVisible, editSupAdmModalVisible, listLoading, userListPagination, isThird } = this.state;
        return (
            <div className={styles.ctn}>
                <div className={styles.header}>
                    <div>
                        <span>所属应用: </span>
                        {this.renderClientSelect()}
                        <span>用户权限：</span>
                        {this.renderPmsSelect()}
                        <span>用户: </span>
                        <Input
                            value={userInfoInput}
                            style={{ marginRight: 5, width: 220 }}
                            onChange={(e) => this.setState({
                                userInfoInput: e.target.value
                            })}
                            placeholder="请输入用户名或邮箱" />
                        <Button style={{ marginRight: 10 }} onClick={this.handleSearch} type="primary">搜索</Button>
                        <Button onClick={this.handleResetSearch}>重置</Button>
                    </div>
                    {login.userInfo.permission === 300 && <Button onClick={() => this.setEditSupAdmModalVisible(true)} type="danger">超管管理</Button>}
                </div>
                <Table
                    loading={listLoading}
                    rowKey="userId"
                    columns={this.columns}
                    dataSource={userList}
                    pagination={{
                        pageSize: userListPagination.limit,
                        total: userListPagination.total,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showSizeChanger: true,
                        onChange: (current) => {
                            const offset = (current - 1) * userListPagination.limit;
                            this.getUserInfoList(offset);
                        },
                        onShowSizeChange: (current, pageSize) => {
                            const offset = (current - 1) * pageSize;
                            const limit = pageSize;
                            this.getUserInfoList(offset, limit);
                        }
                    }} />
                <EditModal
                    login={login}
                    isThird={isThird}
                    sceneList={sceneList}
                    editUserId={editUserId}
                    userIdentity={this.userIdentity}
                    modalVisible={editModalVisible}
                    setModalVisible={this.setEditModalVisible} />
                <EditSupAdmModal
                    login={login}
                    modalVisible={editSupAdmModalVisible}
                    setModalVisible={this.setEditSupAdmModalVisible} />
            </div>
        );
    }
}
