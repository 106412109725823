import * as groupService from '@/service/group';
import {
    IGroup,
    IGroupListModel,
    IGroupModelState,
    IServiceListResult,
} from '@/typings/IServiceModel';
import { IAction } from '@/typings/IDva';

// 曲库标签数据
const sceneList: IGroupListModel = {
    namespace: 'groupList',

    state: {
        data: [],
        limit: 1000,
        offset: 0,
        total: 0,
    },

    effects: {
        * get(action: IAction, { call, put, select }) {
            try {
                const payload = action.payload;
                const { limit, offset } = yield select((state: { groupList: IGroupModelState}) => state.groupList);
                const result: IServiceListResult = yield call(groupService.getList, {
                    limit,
                    offset,
                    ...payload,
                });

                if (!result.data) {
                    throw new Error('数据获取错误');
                }

                yield put({
                    type: 'setState',
                    payload: {
                        data: result.data.list as IGroup[],
                        total: result.data.total as number,
                    },
                });
                return true;
            } catch (err) {
                yield put({
                    type: 'setState',
                    payload: {
                        data: [],
                        total: 0,
                    },
                });
                throw err;
            }
        },
        * create(action: IAction, { call }) {
            const payload = action.payload;
            yield call(groupService.create, payload);
        },
    },

    reducers: {
        setState(state, { payload }) {
            return { ...state, ...payload };
        },
    },
};

export default sceneList;
