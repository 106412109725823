import React, { Fragment } from 'react';
import { connect } from 'dva';
import { IRouteProps } from '@/typings/IRoute';
import { routeMapStateToProps } from '@/util/helper';
import GroupFormModal from '@/component/GroupFormModal';
import ExportExcel from '@/component/ExportExcel';
import ImportExcel from '@/component/ImportExcel';
import { IGroup, IGroupCreate } from '@/typings/IServiceModel';
import { withTranslation } from '@music/mobile-i18n';
import { Table, Button, message, Popconfirm } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import styles from './style.module.less';

interface IGroupState {
    isShowAddGroupModal: boolean;
    currentEditGroup: IGroup | null;
}

@connect(routeMapStateToProps)
class Group extends React.PureComponent<IRouteProps, IGroupState> {
    isSubmitting: boolean = false;

    columns: Array<ColumnProps<IGroup>> = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '模块名',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'key',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: '创建者',
            dataIndex: 'creator',
            key: 'creator',
        },
        {
            title: '操作',
            dataIndex: 'id',
            key: 'operate',
            render: (text, record: IGroup) => {
                let EditButton = null;
                let DeleteButton = null;
                if (record.name === '未分配' && record.creator === '系统') {
                    EditButton = undefined;
                } else {
                    DeleteButton = <Popconfirm
                        onConfirm={() => this.deleteGroup(text)}
                        title="确定删除该模块？">
                        <Button>删除</Button>
                    </Popconfirm>;
                    EditButton = <Button type="primary" onClick={() => this.onEdit(record)} style={{ marginRight: 10 }}>编辑</Button>;
                }
                return (
                    <Fragment>
                        <Button
                            style={{ marginRight: 10 }}
                            type="primary"
                            onClick={() => {
                                const { dispatch, history } = this.props;
                                dispatch({
                                    type: 'group/getById',
                                    payload: {
                                        id: record.id,
                                    },
                                }).then(() => {
                                    history.push(`/${record.sceneId}/document/${record.id}${window.location.search}`);
                                });
                            }}>
                            详细
                        </Button>
                        {EditButton}
                        {DeleteButton}
                    </Fragment>
                );
            },
        },
    ];
    state = {
        isShowAddGroupModal: false,
        currentEditGroup: null,
    };

    onEdit = (record: IGroup): void => {
        const { currentEditGroup } = this.state;
        if (currentEditGroup) {
            return;
        }

        this.setState({
            currentEditGroup: record,
        });
    }

    updateGroup = (value: IGroupCreate): void => {

        if (this.isSubmitting) {
            return;
        }
        const { dispatch, } = this.props;
        const { currentEditGroup } = this.state;
        if (currentEditGroup !== null && !currentEditGroup) { // 编辑态已退出
            return;
        }

        const { sceneId, id, }: { sceneId?: number; id?: number; } = currentEditGroup || {};

        if (!sceneId || !id) {
            message.error('当前模块信息不正确');
            return;
        }

        this.isSubmitting = true;

        dispatch({
            type: 'group/update',
            payload: {
                id,
                name: value.name,
                key: value.key,
            },
        }).then(() => {
            return dispatch({
                type: 'groupList/get',
                payload: {
                    id: sceneId,
                },
            });
        }).then(() => {
            message.success('模块更新成功');
            this.setState({
                currentEditGroup: null,
            });
            this.isSubmitting = false;
        }).catch((err) => {
            message.error(err.message || '操作失败，请重试');
            this.isSubmitting = false;
        });
    }

    addGroup = (value: IGroupCreate): void => {
        if (this.isSubmitting) {
            return;
        }
        const { scene, dispatch, } = this.props;
        if (!scene) {
            message.error('非法应用，创建失败');
            return;
        }
        this.isSubmitting = true;
        dispatch({
            type: 'group/create',
            payload: value,
        }).then(() => {
            return dispatch({
                type: 'groupList/get',
                payload: {
                    id: scene.id,
                },
            });
        }).then(() => {
            this.isSubmitting = false;
            message.success('模块创建成功');
            this.setState({
                isShowAddGroupModal: false,
            });
        }).catch((err) => {
            this.isSubmitting = false;
            message.error(err.message || '操作失败，请重试');
        });
    }

    deleteGroup = (id: number): void => {
        if (this.isSubmitting) {
            return;
        }
        this.isSubmitting = false;
        const { scene, dispatch, } = this.props;
        dispatch({
            type: 'group/delete',
            payload: {
                id,
            },
        }).then(() => {
            return dispatch({
                type: 'groupList/get',
                payload: {
                    id: scene!.id,
                },
            });
        }).then(() => {
            this.isSubmitting = false;
            message.success('模块删除成功');
        }).catch((err) => {
            this.isSubmitting = false;
            message.error(err.message || '操作失败，请重试');
        });
    }

    render() {
        const { isShowAddGroupModal, currentEditGroup, } = this.state;
        const { groupList, scene, i18n } = this.props;
        return (<div className={styles.ctn}>
            <div className={styles.addGroupBtnCtn}>
                <Button
                    type="primary"
                    onClick={() => {
                        this.setState({
                            isShowAddGroupModal: true,
                        });
                    }}>添加模块</Button>
                <ExportExcel
                    i18n={i18n}
                    sceneId={scene ? scene.id : undefined}
                    languages={scene ? scene.scene.languages : []}
                />
                <ImportExcel
                    i18n={i18n}
                    sceneId={scene ? scene.id : undefined}
                    onSuccess={() => {
                        const { dispatch } = this.props;
                        dispatch({
                            type: 'groupList/get',
                            payload: {
                                id: scene ? scene.id : undefined,
                            },
                        });
                    }}
                />
            </div>
            <Table
                rowKey="id"
                pagination={false}
                dataSource={groupList}
                columns={this.columns}
            />
            <GroupFormModal
                visible={isShowAddGroupModal}
                onCancel={() => {
                    this.setState({
                        isShowAddGroupModal: false,
                    });
                }} onSubmit={this.addGroup} />
            <GroupFormModal
                dataSource={currentEditGroup || undefined}
                visible={!!currentEditGroup}
                onCancel={() => {
                    this.setState({
                        currentEditGroup: null,
                    });
                }} onSubmit={this.updateGroup} />
        </div>);
    }
}

export default withTranslation()(Group);
