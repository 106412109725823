import { API_BASE } from '@/util/const';
import fetch from '@/util/fetch';
import {
    IServiceResult, IdParams, IDocumentSetPageParams, IDocumentSet,
    IDocumentSetFilter, IDocumentSetCreate, IDocumentSetCreateBatch
} from '@/typings/IServiceModel';

export const getUnMatchVariable = (data: { id: string | number, groupId: number, client: number }): PromiseLike<IServiceResult> => {
    const {
        id,
        ...rest
    } = data;
    return fetch(`${API_BASE}/documentsets/${id}/unMatchVariable`, {
        method: 'post',
        data: rest,
    });
};

export const exportGoogleSheets = (data: {
    columnNames: any[],
    rowDatas: any[],
    fileName: string
}): PromiseLike<IServiceResult> => {
    return fetch(`${API_BASE}/documentsets/sheets`, {
        method: 'post',
        data,
    });
};

export const importGoogleSheets = (data: {
    spreadsheetId: string;
}): PromiseLike<any> => {
    return fetch(`${API_BASE}/documentsets/getSheets`, {
        method: 'post',
        data,
    });
};

export const filter = (data: IDocumentSetFilter): PromiseLike<IServiceResult> => {
    const {
        id,
        ...rest
    } = data;
    return fetch(`${API_BASE}/documentsets/${id}/untranslatedDocs`, {
        method: 'post',
        data: rest,
    });
};

export const getList = (data: IDocumentSetPageParams): PromiseLike<IServiceResult> => {
    const {
        id,
        client,
        searchValue,
        searchRange,
        search,
        ...rest
    } = data;
    return fetch(`${API_BASE}/groups/${id}/documentsets`, {
        data: {
            ...rest,
            client: client || undefined,
            search: search || undefined,
            searchValue: searchValue || undefined,
            searchRange: searchRange || []
        },
    });
};

export const getAll = ({ sceneId, groupId }: { sceneId: number | string, groupId?: number | string }): PromiseLike<IServiceResult> => fetch(`${API_BASE}/documentsets/all`, {
    data: {
        sceneId,
        groupId
    }
});

export const createBatch = (id: number, data: IDocumentSetCreateBatch): PromiseLike<IServiceResult> => fetch(`${API_BASE}/scenes/${id}/documentsetlist`, {
    method: 'post',
    data,
});

export const updateDocument = (data: {
    id: number, dataInside: IDocumentSetCreateBatch
}): PromiseLike<IServiceResult> => {
    const { id, dataInside } = data;
    return fetch(`${API_BASE}/scenes/${id}/documentsetlist`, {
        method: 'post',
        data: dataInside,
    });
};

export const getById = (data: IdParams): PromiseLike<IServiceResult> => {
    const { id } = data;
    return fetch(`${API_BASE}/documentsets/${id}`);
};

export const create = (data: IDocumentSetCreate): PromiseLike<IServiceResult> => {
    const {
        id,
        groupId,
        ...rest
    } = data;
    const finalGroupId = groupId || data[0]?.groupId || id;
    return fetch(`${API_BASE}/groups/${finalGroupId}/documentsets`, {
        method: 'post',
        data: rest,
    });
};
export const update = (data: IDocumentSet): PromiseLike<IServiceResult> => {
    const {
        id,
        ...rest
    } = data;
    return fetch(`${API_BASE}/documentsets/${id}`, {
        method: 'put',
        data: rest,
    });
};

export const bulkUpdate = (data: IDocumentSet): PromiseLike<IServiceResult> => {
    const {
        ...rest
    } = data;
    return fetch(`${API_BASE}/documentsets/bulkupdate`, {
        method: 'put',
        data: rest,
    });
};

export const remove = (data: IDocumentSet): PromiseLike<IServiceResult> => {
    const {
        id,
    } = data;
    return fetch(`${API_BASE}/documentsets/${id}`, {
        method: 'delete',
    });
};

export const bulkRemove = (data: IDocumentSet): PromiseLike<IServiceResult> => {
    const {
        ...rest
    } = data;
    return fetch(`${API_BASE}/documentsets/bulkDelete`, {
        method: 'post',
        data: rest,
    });
};

export const sendNeedTranslatePopoMsg = (data: IDocumentSet): PromiseLike<IServiceResult> => {
    const {
        ...rest
    } = data;
    return fetch(`${API_BASE}/documentsets/sendNeedTranslatePopoMsg`, {
        method: 'post',
        data: rest,
    });
};

export const sendPopoNotice = (data: { receiver: string; content: string }): PromiseLike<IServiceResult> => {
    const {
        receiver,
        content
    } = data;
    return fetch(`${API_BASE}/documentsets/sendPopoNotice`, {
        method: 'post',
        data: {
            receiver,
            content
        },
    });
};

export const checkKeyUnique = (data: { id: number; key: string; groupId?: string; }): PromiseLike<IServiceResult> => {
    const {
        id,
        key,
        groupId,
    } = data;
    return fetch(`${API_BASE}/scenes/${id}/documentsets/keycheck`, {
        method: 'get',
        data: {
            key,
            groupId
        },
    });
};
export const generateKey = (data: IdParams): PromiseLike<IServiceResult> => {
    const {
        id,
    } = data;
    return fetch(`${API_BASE}/scenes/${id}/documentsets/key`, {
        method: 'post',
    });
};

export const translate = (data: {
    translateWord: string, toLang: string, id?: number
}): PromiseLike<IServiceResult> => {
    const {
        translateWord,
        toLang,
        id,
    } = data;
    return fetch(`${API_BASE}/documentsets/translate`, {
        method: 'post',
        data: {
            translateWord, toLang, id
        },
    });
};

// 获取和描述一样的记录
export const getMatchDesc = (data:
    { ids?: string, groupId: number, client: number, desc?: string, sceneLanguage?: string
}): PromiseLike<IServiceResult> => {
    const {
        ...rest
    } = data;

    return fetch(`${API_BASE}/documentsets/matchDesc`, {
        method: 'post',
        data: rest,
    });
};
