/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Input } from 'antd';
import styles from './textinput.module.less';

interface ITextInputState {
    inputValue: string;
    isEdit: boolean;
}

interface ITextInputProp {
    style: any;
    value: string;
    disabled?: boolean;
    highLightValue?: string;
    client?: string;
    onPressEnter: (value: string) => void;
}
class TextInput extends React.PureComponent<ITextInputProp, ITextInputState> {
    state = {
        inputValue: this.props.value,
        isEdit: false,
    };

    componentDidUpdate(prevProps: ITextInputProp) {
        if (this.props.value !== prevProps.value) {
            this.setState({ inputValue: this.props.value });
        }
    }

    onEdit = () => {
        this.setState({
            isEdit: true,
            inputValue: this.props.value,
        });
    }

    onBlur = () => {
        const { inputValue } = this.state;
        const { value, onPressEnter } = this.props;
        this.setState({ isEdit: false });
        if (inputValue !== value) {
            onPressEnter(inputValue);
        }
    }

    renderValue = ({
        value,
        highLightValue,
        client
    }: {
        value?: string, highLightValue?: string, client?: string
    }) => {
        if (highLightValue && value) {
            const arr = value.split(highLightValue);
            const length = arr.length;
            return arr.map((item, index) => (
                <>
                    {item}
                    {index < length - 1 ? (
                        <span style={{ backgroundColor: '#d16666', color: '#ffffff' }}>
                            {highLightValue}
                        </span>
                    ) : null}
                </>
            ));
        }
        if (value) {
            // 区分客户端以及其他端，服务端为大括号，客户端及前端为%N
            const reg = client === '4' ? /(\{.+?\})/g : /(%\d)/g;
            const regValues = value.match(reg);
            if (regValues?.length) {
                const formatValue = value.replace(reg, (match) => (
                    `<span style="background-color: #a0c8c2; color: #ffffff">
                        ${match}
                    </span>`));
                return (
                    <div dangerouslySetInnerHTML={{ __html: formatValue }} />
                );
            }
            return value;
        }
        return value;
    }

    render() {
        const { isEdit, inputValue } = this.state;
        const {
            style, value, disabled, highLightValue, client
        } = this.props;
        if (!isEdit) {
            return (
                <div
                    style={style}
                    className={styles.textContainer}
                    title={value}
                    onClick={this.onEdit}>
                    <span
                        id="valueNode"
                        className={styles.defaluttext}>
                        {this.renderValue({
                            value, highLightValue, client
                        })}
                    </span>
                </div>
            );
        }
        return (
            <Input.TextArea
                ref={(input) => {
                    if (input) {
                        input.focus();
                    }
                }}
                style={style}
                value={inputValue}
                autoSize={{ minRows: 2, maxRows: 4 }}
                onChange={(e) => {
                    this.setState({
                        inputValue: e.currentTarget.value
                    });
                }}
                disabled={disabled}
                onBlur={this.onBlur} />
        );
    }
}

export default TextInput;
