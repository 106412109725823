import React from 'react';
import { connect } from 'dva';
import md5 from 'md5';
import {
    Button, Input, Form, Icon, Row, Col, message, Tabs, Select
} from 'antd';
import { FormComponentProps } from 'antd/lib/form/Form';
import { Trans, withTranslation } from '@music/mobile-i18n';
import { parse } from '@music/mobile-url';
import Config from '@/config/index';
import { IRouteProps } from '@/typings/IRoute';
import { routeMapStateToProps } from '@/util/helper';

import styles from './style.module.less';

const FormItem = Form.Item;
const { TabPane } = Tabs;
const { Option } = Select;

const params: any = parse(window.location.search);
// const lngOptions = [
//     {
//         label: '中文',
//         value: 'zh-CN',
//     },
//     {
//         label: 'english',
//         value: 'en-US',
//     },
//     {
//         label: 'bahasa Indonesia',
//         value: 'id-ID',
//     },
// ];

@connect(routeMapStateToProps)
class Login extends React.PureComponent<IRouteProps & FormComponentProps> {
    private trans: (...args: any[]) => string;
    private transLang: (...args: any[]) => string;
    private lngOptions: any[];

    constructor(props: IRouteProps & FormComponentProps) {
        super(props);

        this.trans = props.i18n.getFixedT(null, ['login']);
        this.transLang = props.i18n.getFixedT(null, ['textManage']);
        this.lngOptions = [
            {
                label: 'zh_CN',
                value: 'zh-CN',
            },
            {
                label: 'en_US',
                value: 'en-US',
            },
            {
                label: 'id_ID',
                value: 'id-ID',
            },
        ];
    }

    state = {
        validateStatus: {},
        lng: params.lng || window.navigator.language || 'zh-CN'
    };

    componentDidMount(): void {
        const { dispatch, login, history } = this.props;
        const { state: { redirectUrl = '/' } = {} as any } = history.location;
        if (login.status === 1) {
            history.push(redirectUrl);
        } else {
            dispatch({
                type: 'login/getLoginStatus'
            }).then(() => {
                history.push(redirectUrl);
            });
        }
    }

    private pmsLogin = () => {
        const { dispatch, history } = this.props;

        const { state: { redirectUrl = '/', search = '?lng=zh-CN' } = {} as any } = history.location;

        dispatch({
            type: 'login/pmsLogin',
            payload: {
                redirectUrl: `${redirectUrl}${search}`,
                fromHost: window.location.host
            }
        }).then(({ data }) => {
            if (data) {
                window.location.href = data;
            }
        });
    };

    private onLogin = (event: any) => {
        event.preventDefault();
        const { dispatch, history, form, login } = this.props;
        const { captcha } = login;

        const { state: { search = '?lng=zh-CN', redirectUrl = '/' } = {} as any } = history.location;

        form.validateFields(async () => {
            const { account, password = '', captchavalue } = form.getFieldsValue();
            const { validateStatus = {} } = this.state;

            if (!validateStatus || (validateStatus as any).validateStatus === 'error') {
                return;
            }

            dispatch({
                type: 'login/musicLogin',
                payload: {
                    account,
                    password: md5(password),
                    captchaId: captcha.captchaId,
                    captchavalue
                }
            }).then((res) => {
                if (res) {
                    window.location.href = `${redirectUrl}${search}`;
                }
            }, () => {
                this.onRefreshCaptcha();
                message.error(this.trans('loginerror'));
            });
        });
    };

    private verifyCaptcha = async (data: any) => {
        const { target: { value } } = data;
        const { dispatch, login } = this.props;
        const { captcha = {} } = login;
        const res = await dispatch({
            type: 'login/verifyCaptcha',
            payload: {
                captchaId: captcha.captchaId,
                captchaValue: value,
            },
        });
        if (res.success) {
            this.setState({
                validateStatus: { hasFeedback: true, validateStatus: 'success' }
            });
        } else {
            this.setState({
                validateStatus: { hasFeedback: true, validateStatus: 'error', help: this.trans('retrycaptcha') }
            });
        }
    };

    private genTitle = (): JSX.Element | null => {
        return (
            <span className={styles.title}>千语</span>
        );
    }

    private register = () => {
        window.open(Config.registerHost);
    };

    private onRefreshCaptcha = () => {
        this.props.dispatch({ type: 'login/getCaptcha' });
    };

    private loginContent = (): JSX.Element | null => {
        const { login, form } = this.props;
        const { getFieldDecorator } = form;
        const { captcha = {} } = login;
        const { validateStatus = {} } = this.state;
        return (
            <Form>
                <FormItem>
                    {getFieldDecorator('account', {
                        rules: [
                            {
                                required: true,
                                message: this.trans('enterphone'),
                            },
                            {
                                pattern: /^\d{1,}$/,
                                message: this.trans('invalidphone'),
                            },
                        ],
                    })(
                        <Input
                            placeholder={this.trans('account')}
                            prefix={(
                                <Icon
                                    type="mobile"
                                    style={{ color: 'rgba(0, 0, 0, 0.25)' }} />
)} />
                    )}
                </FormItem>
                <FormItem>
                    {getFieldDecorator('password', {
                        rules: [
                            {
                                required: true,
                                message: this.trans('enterpassword'),
                            },
                        ],
                    })(
                        <Input
                            placeholder={this.trans('password')}
                            type="password"
                            prefix={(
                                <Icon
                                    type="lock"
                                    style={{ color: 'rgba(0, 0, 0, 0.25)' }} />
                            )} />
                    )}
                </FormItem>
                <Row>
                    <Col span={14} style={{ verticalAlign: 'middle', width: '65%' }}>
                        <FormItem {...validateStatus}>
                            {getFieldDecorator('captchavalue', {
                                rules: [
                                    {
                                        required: true,
                                        message: this.trans('entercaptcha'),
                                    },
                                ],
                            })(
                                <Input
                                    onBlur={this.verifyCaptcha}
                                    placeholder={this.trans('entercaptcha')}
                                    prefix={(
                                        <Icon
                                            type="lock"
                                            style={{ color: 'rgba(0, 0, 0, 0.25)' }} />
                                    )} />
                            )}
                        </FormItem>
                    </Col>
                    <Col span={4} style={{ margin: '5px ' }}>
                        <img style={{ width: '90%' }} src={captcha.imgUrl} />
                    </Col>
                    <Col span={2} style={{ margin: '8px 0' }}>
                        <Icon type="sync" onClick={this.onRefreshCaptcha} style={{ color: '#fff' }} />
                    </Col>
                </Row>
                <FormItem>
                    <div className={styles.btns}>
                        <Button onClick={this.register}>{ this.trans('signup') }</Button>
                        <Button type="primary" onClick={this.onLogin}>{ this.trans('signin') }</Button>
                    </div>
                </FormItem>
            </Form>
        );
    }

    private pmsLoginContent = (): JSX.Element | null => {
        return (
            <Button
                onClick={this.pmsLogin}
                type="primary">
                    { this.trans('loginbyopenid') }
            </Button>
        );
    }

    render() {
        return (
            <div className={`${styles.login} ${styles.layer}`}>
                <div className={styles['top-bar']}>{this.genTitle()}</div>
                <div className={styles.container}>
                    <div className={styles.lng}>
                        <span className={styles.changelng}>{ this.trans('lan') }</span>
                        <Select
                            value={this.state.lng}
                            onChange={(value: string) => {
                                const { state: { redirectUrl = '/', host } = {} as any } = this.props.history.location;
                                this.props.history.replace(`/login?lng=${value}`, {
                                    host,
                                    redirectUrl,
                                    search: `?lng=${value}`
                                });
                                this.setState({ lng: value });
                            }}
                            style={{
                                width: 200,
                                height: 50,
                                marginRight: 20
                            }}>
                            {this.lngOptions.map((item: any) => (
                                <Option key={item.value} value={item.value}>
                                    {this.transLang(item.label)}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <Tabs defaultActiveKey="1" onChange={() => {}}>
                        <TabPane tab={ this.trans('openid') } key="1">
                            <div className={styles.tabcontent}>{ this.pmsLoginContent() }</div>
                        </TabPane>
                        <TabPane tab={ this.trans('phone') } key="2">
                            <div className={styles.tabcontent}>{ this.loginContent() }</div>
                        </TabPane>
                    </Tabs>
                </div>
                <div className={styles.copyright}>{
                    <Trans
                        ns="login"
                        values={{
                            1: new Date().getFullYear()
                        }}
                        i18nKey="copyright" />
                }</div>
            </div>
        );
    }
}

export default withTranslation()(Form.create({})(Login));
