import React from 'react';
import { Button, message, Modal } from 'antd';
import I18nReact from '@music/mobile-i18n';
import * as documentSetService from '@/service/documentSet';
import { IDocument, ILanguageMap, IDocumentSet } from '@/typings/IServiceModel';
import DocumentMatchModal from '@/component/DocumentMatchModal';
import { uniqWith, isEqual, groupBy } from 'lodash';
const { confirm } = Modal;

interface IBatchMatchBtnProps {
    onClose?: () => void;
    disabled: boolean;
    selectedRowKeys: number[];
    documentSetList: IDocumentSet[];
    sceneLanguage: string[];
    languageMap: ILanguageMap;
    i18n: any;
}
interface IDataSource {
    source?: IDocumentSet;
    desc: string;
    documents: IDocument[][];
}

export default class ExportExcel extends React.PureComponent<IBatchMatchBtnProps> {
    state = {
        matchDataSource: [], // 相同描述的项的搜索结果
        isShowDocumentMatchModal: false
    };

    trans = () => I18nReact.i18n.getFixedT(null, ['textManage']);

    onClose = () => {
        const {
            onClose
        } = this.props;
        this.setState({
            isShowDocumentMatchModal: false,
        });
        if (onClose) {
            onClose();
        }
    }

    onSearch = () => {
        const {
            sceneLanguage,
            documentSetList,
            selectedRowKeys
        } = this.props;

        const desc: string[] = [];
        const selectList: IDocumentSet[] = [];
        // 获取所选id项的完整信息
        for (const key of selectedRowKeys) {
            for (const item of documentSetList) {
                if (item.id === key && item.type === 'text') {
                    desc.push(item.desc);
                    selectList.push(item);
                }
            }
        }
        documentSetService.getMatchDesc({
            client: 0,
            groupId: 0,
            ids: selectedRowKeys.join(','),
            desc: JSON.stringify([...new Set(desc)]),
            sceneLanguage: JSON.stringify(sceneLanguage)
        }).then((res) => {
            if (res?.code === 200 && res?.data?.total > 0) {
                const list = res?.data?.list || [];
                // 按描述进行分组
                const group = groupBy(list, 'desc');
                const matchDataSource: IDataSource[] = [];
                for (const descKey of Object.keys(group)) {
                    const list1 = group[descKey];
                    // 用于去除重复的项
                    const tempList = uniqWith(list1, (a: IDocumentSet, b: IDocumentSet) => {
                        return isEqual(a.documents, b.documents);
                    });
                    const tempDocument: IDocument[][] = [];
                    for (const item of tempList) {
                        tempDocument.push(item.documents);
                    }
                    for (const item of selectList) {
                        if (item.desc === descKey) {
                            matchDataSource.push({
                                source: item,
                                desc: descKey,
                                documents: tempDocument
                            });
                        }
                    }
                }
                if (matchDataSource.length) {
                    this.setState({
                        isShowDocumentMatchModal: true,
                        matchDataSource
                    });
                } else {
                    message.info('不存在已翻译的描述文案');
                }
            } else {
                message.info('不存在已翻译的描述文案');
                this.setState({ isShowDocumentMatchModal: false });
            }
        });
    }

    doUpdate = (data: IDocument[], id?: number) => {
        const arr = data.map((item) => {
            return {
                languageCode: item.languageCode,
                text: item.text
            };
        });
        return new Promise((resolve, reject) => {
            documentSetService.update({
                id,
                documents: JSON.stringify(arr)
            } as never).then((res) => {
                if (res?.code === 200) {
                    resolve(res);
                } else {
                    reject(res);
                }
            });
        });
    }

    onImport = (data: IDocument[], source?: IDocumentSet) => {
        const result = data.filter((item) => {
            const found = source?.documents?.find((element) => element.languageCode === item.languageCode);
            return !found || found?.text === '';
        });
        if (!result.length) {
            message.info('匹配项不存在未翻译的语言，不需要导入');
            return;
        }
        this.doUpdate(result, source?.id).then(() => {
            message.success('导入成功');
        }).catch((e) => {
            message.info(e?.message || '导入失败');
        });
    }

    onSubmit = (data: IDataSource[], radioSelect: number[]) => {
        const proArr: Array<PromiseLike<any>> = [];
        for (const [i, v] of data.entries()) {
            const idx = radioSelect[i] || 0;
            const select = v.documents[idx];
            const source = v?.source;
            const result = select.filter((item) => {
                const found = source?.documents?.find((element) => element.languageCode === item.languageCode);
                return !found || found?.text === '';
            });
            if (result.length) {
                proArr.push(this.doUpdate(select, source?.id));
            }
        }
        if (!proArr.length) {
            message.info('匹配项不存在未翻译的语言，不需要导入');
            return;
        }
        Promise.all(proArr).then(() => {
            message.success('导入成功');
            this.onClose();
        }).catch((e) => {
            message.info(e?.message || '导入失败');
        });
    }

    render() {
        const { disabled } = this.props;
        const {
            i18n,
            sceneLanguage,
            languageMap,
            // keyGenerator,
        } = this.props;
        const { isShowDocumentMatchModal, matchDataSource } = this.state;
        return (
            <>
                <Button
                    type="primary"
                    disabled={disabled}
                    onClick={() => { this.onSearch(); }}>
                    批量匹配
                </Button>
                <DocumentMatchModal
                    i18n={i18n}
                    sceneLanguage={sceneLanguage}
                    dataSource={matchDataSource}
                    languageMap={languageMap}
                    visible={isShowDocumentMatchModal}
                    onSubmit={(data, radioSelect) => {
                        confirm({
                            title: '确定要全部导入吗?',
                            content: '',
                            onOk: () => {
                                this.onSubmit(data, radioSelect);
                            },
                        });
                    }}
                    onImport={(data: IDocument[], source?: IDocumentSet) => this.onImport(data, source)}
                    onCancel={() => {
                        this.onClose();
                    }} />
            </>
        );
    }
}
