/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react';
import {
    Modal, Select, Button, Table, message, Popconfirm
} from 'antd';
import * as userService from '@/service/user';
import { IScene } from '@/typings/IServiceModel';
import styles from './editModal.module.less';

interface IUserIdentity {
    [key: number]: string;
}

interface IEditModalProps {
    login: any;
    sceneList: any[];
    editUserId: number;
    modalVisible: boolean;
    setModalVisible: (visible: boolean) => void;
    userIdentity: IUserIdentity;
    isThird?: boolean;
}

const EditModal: React.FC<IEditModalProps> = ({
    login, editUserId, modalVisible, setModalVisible, sceneList, userIdentity, isThird
}) => {
    const [userApp, setUserApp] = useState(1);
    const [listLoading, setListLoading] = useState(false);
    const [userAppList, setUserAppList] = useState<IScene[]>([]);
    const [userPermissionList, setUserPermissionList] = useState([]);

    // 获取用户权限列表
    const getUserPermissionList = async () => {
        setListLoading(true);
        const res = await userService.getUserPermission({
            status: 2,
            userId: editUserId
        });
        setUserPermissionList(res?.data?.result?.scenes || []);
        setListLoading(false);
    };

    // 添加权限
    const handleAddUserPermission = async (pms = 100) => {
        const res = await userService.addUserScene({
            sceneId: userApp,
            userId: editUserId,
            pms
        });
        if (res?.data?.result) {
            getUserPermissionList();
            message.success('添加成功');
        } else {
            message.error('用户已存在当前应用');
        }
    };

    // 移除权限
    const handleDelUserPermission = async (appId: number) => {
        const res = await userService.delUserPermission({
            userId: editUserId,
            sceneId: appId
        });
        if (res?.data?.result) {
            message.success('移除成功');
            getUserPermissionList();
        }
    };

    const renderClientSelect = () => (
        <Select
            value={userApp}
            onChange={(value: number) => {
                setUserApp(value);
            }}
            style={{
                width: 200,
                marginRight: 20,
            }}>
            {userAppList.map((item: IScene) => (
                <Select.Option key={item.id} value={item.id}>
                    {`${item.name}(${item.aliasName})`}
                </Select.Option>
            ))}
        </Select>
    );

    // 获取用户自己的应用添加权限
    useEffect(() => {
        if (login.userInfo.permission === 300) {
            if (!sceneList.length) return;
            setUserAppList([...sceneList]);
            setUserApp(sceneList[0].id);
        } else {
            const list = login.userInfo.scenes.filter((el: IScene) => el?.scene_user?.pms === 200);
            setUserAppList([...list]);
            setUserApp(list[0]?.id);
        }
    }, [sceneList]);

    // 获取选择用户所属应用列表
    useEffect(() => {
        if (!modalVisible || editUserId === -1) return;
        getUserPermissionList();
    }, [editUserId, modalVisible]);

    const columns = [
        {
            title: 'ID',
            key: 'id',
            dataIndex: 'id',
        },
        {
            title: '所属应用',
            render: (it: any) => (`${it.name}(${it.aliasName})`)
        },
        {
            title: '权限',
            render: (it: any) => (userIdentity[it.scene_user.pms])
        },
        {
            title: '操作',
            render: (it: any) => {
                let isDisabled;
                if (login.userInfo.permission === 300) {
                    isDisabled = false;
                } else {
                    isDisabled = !login.userInfo.scenes.some(
                        (item: IScene) => item.scene_user.pms === 200 && item.id === it.id
                    );
                }

                // 默认应用不可编辑
                if (it.aliasName === '0') {
                    isDisabled = true;
                }

                return (
                    <Popconfirm
                        placement="topRight"
                        title="是否确认删除该应用访问权限？"
                        onConfirm={() => handleDelUserPermission(it.id)}
                        okText="是"
                        cancelText="否"
                        disabled={isDisabled}>
                        <Button disabled={isDisabled} type="link">删除</Button>
                    </Popconfirm>
                );
            }
        },
    ];

    return (
        <Modal
            title="用户权限编辑"
            width={800}
            visible={modalVisible}
            onCancel={() => setModalVisible(false)}
            footer={null}>
            <div className={styles.modalContainer}>
                <div style={{ paddingBottom: 20 }}>
                    <span>选择应用：</span>
                    {renderClientSelect()}
                    <Button disabled={isThird} type="primary" style={{ marginRight: 10 }} onClick={() => handleAddUserPermission(100)}>添加为成员</Button>
                    <Button type="primary" style={{ marginRight: 10 }} onClick={() => handleAddUserPermission(50)}>添加为翻译人员</Button>
                    {login.userInfo.permission === 300 && <Button disabled={isThird} type="danger" onClick={() => handleAddUserPermission(200)}>添加为管理员</Button>}
                </div>
                <Table
                    rowKey="id"
                    pagination={false}
                    loading={listLoading}
                    columns={columns}
                    dataSource={userPermissionList} />
            </div>
        </Modal>
    );
};

export default EditModal;
