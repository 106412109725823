import React from 'react';
import { connect } from 'dva';
import { IRouteProps } from '@/typings/IRoute';
import { routeMapStateToProps, downloadXLSXOfJson } from '@/util/helper';
import { ILanguage } from '@/typings/IServiceModel';
import { Table, Button, Upload, message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import * as languageService from '@/service/language';
import { tableMap } from './constant';
import AddEditDialog from './components/dialog';
import XLSX from 'xlsx';

import styles from './style.module.less';

interface ILanguageState {
    createBatchLoaidng: boolean;
    isNew: boolean;
    title: string;
    currentLng: any;
    isModalVisible: boolean;
}
@connect(routeMapStateToProps)
export default class Language extends React.PureComponent<IRouteProps, ILanguageState> {
    state = {
        createBatchLoaidng: false,
        isNew: false,
        currentLng: {},
        title: '',
        isModalVisible: false
    };

    onEdit = (record: any) => {
        this.setState({
            isNew: false,
            title: '编辑语言包',
            currentLng: record,
            isModalVisible: true
        });
    }

    onDelete = (record: any) => {
        const { dispatch } = this.props;
        languageService.deleteLang(record).then(() => {
            message.success('删除成功');
            dispatch({
                type: 'language/get',
            });
        }).catch((e) => {
            message.success(e?.message);
        });
    };

    columns: Array<ColumnProps<ILanguage>> = [
        {
            title: '描述',
            dataIndex: 'desc',
            key: 'desc',
        },
        {
            title: '通用语言简码',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'ios简码',
            dataIndex: 'iosCode',
            key: 'iosCode',
        },
        {
            title: '安卓简码',
            dataIndex: 'androidCode',
            key: 'androidCode',
        },
        {
            title: '服务端简码',
            dataIndex: 'backendCode',
            key: 'backendCode',
        },
        {
            title: '前端简码',
            dataIndex: 'frontendCode',
            key: 'frontendCode',
        },
        {
            title: 'Flutter简码',
            dataIndex: 'flutterCode',
            key: 'flutterCode',
        },
        {
            title: '创建人',
            dataIndex: 'creator',
            key: 'creator',
        },
        // {
        //     title: '修改人',
        //     dataIndex: 'lastModifyUser',
        //     key: 'lastModifyUser',
        // },
        {
            title: '更新时间',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (updatedAt: any) => {
                const updateAtSplits = updatedAt.split(/T|\./);

                return updateAtSplits.slice(0, 2).join(' ');
            }
        },
        {
            title: '操作',
            key: 'action',
            render: (_: any, record: any) => {
                return (
                    <span className={styles.actionDone}>
                        <a style={{ marginRight: '10px' }} onClick={() => this.onEdit(record)}>编辑</a>
                        <a onClick={() => this.onDelete(record)}>删除</a>
                    </span>
                );
            },
        },
    ];

    importExcel = (file: any): boolean => {
        const { dispatch, scene } = this.props;
        const { id: sceneId } = scene || {};
        if (!sceneId) {
            message.error('非法应用，导入失败');
            return false;
        }
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = () => {
            const result = reader.result;
            if (typeof result === 'string' || !result) {
                message.error('FileReader error');
                return;
            }
            const data = new Uint8Array(result);
            const workbook = XLSX.read(data, { type: 'array' });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const list: any = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

            const th = list[0]; // 表头
            const languageList = list.slice(1).map((item: any) => {
                return {
                    code: item[th.indexOf('code')],
                    desc: item[th.indexOf('desc')],
                    iosCode: item[th.indexOf('iosCode')],
                    androidCode: item[th.indexOf('androidCode')],
                    backendCode: item[th.indexOf('backendCode')],
                    frontendCode: item[th.indexOf('frontendCode')],
                    flutterCode: item[th.indexOf('flutterCode')]
                };
            });
            languageService.createBatch({
                languageList: JSON.stringify(languageList)
            }).then(() => {
                message.success('上传成功');
                dispatch({
                    type: 'language/get',
                });
                this.setState({ createBatchLoaidng: false });
            }, (err) => {
                message.error(`上传失败：${err.message}`);
                this.setState({ createBatchLoaidng: false });
            });
        };
        return false;
    }

    exportExcel = () => {
        languageService.getList().then((res) => {
            const data: any = res.data;
            const cols = tableMap.map((item) => item.key);
            const newData: any[] = [];
            if (data.length) {
                data.forEach((item: any) => {
                    const document = {
                        [cols[0]]: item.desc,
                        [cols[1]]: item.code,
                        [cols[2]]: item.iosCode,
                        [cols[3]]: item.androidCode,
                        [cols[4]]: item.backendCode,
                        [cols[5]]: item.frontendCode,
                        [cols[6]]: item.flutterCode,
                    };
                    newData.push(document);
                });
            }

            downloadXLSXOfJson({
                rowDatas: newData,
                fileName: '多语言简码表_' + Date.now()
            });
        });
    }

    onSubmit = (values: any) => {
        const { dispatch } = this.props;
        const { isNew } = this.state;

        if (isNew) {
            languageService.create(values).then(() => {
                dispatch({
                    type: 'language/get',
                });
                this.onCancel();
            }).catch((e) => {
                message.error(e.message);
            });
        } else {
            languageService.edit(values).then(() => {
                dispatch({
                    type: 'language/get',
                });
                this.onCancel();
            }).catch((e) => {
                message.error(e.message);
            });
        }
    };

    onCancel = () => {
        this.setState({
            isModalVisible: false
        });
    };

    render() {
        const { createBatchLoaidng, isModalVisible, currentLng, isNew } = this.state;
        const { language } = this.props;
        return (<div className={styles.ctn}>
            <AddEditDialog
                isNew={isNew}
                isModalVisible={isModalVisible}
                onCancel={this.onCancel}
                currentLng={currentLng}
                onSubmit={this.onSubmit} />
            <div className={styles.addGroupBtnCtn}>
                <Button
                    type="primary"
                    onClick={() => {
                        this.setState({
                            isModalVisible: true,
                            isNew: true,
                            currentLng: {},
                            title: '新增语言包'
                        });
                    }}>新增语言包</Button>
                <Button
                    type="primary"
                    onClick={() => { this.exportExcel(); }}>导出简码表</Button>
                <Upload
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,.xlsx"
                    showUploadList={false}
                    disabled={createBatchLoaidng}
                    beforeUpload={this.importExcel}>
                    <Button type="primary" disabled={createBatchLoaidng}>导入简码表</Button>
                </Upload>
            </div>
            <Table
                rowKey="code"
                pagination={false}
                dataSource={language}
                columns={this.columns}
            />
        </div>);
    }
}
