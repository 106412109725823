import { API_BASE } from '@/util/const';
import fetch from '@/util/fetch';
import {
    IServiceResult, IdParams, IPageParams, IScene,
} from '@/typings/IServiceModel';

export const getList = (data: IPageParams): PromiseLike<IServiceResult> => fetch(`${API_BASE}/publish/list`, {
    data,
});

export const getVersions = (data: IPageParams): PromiseLike<IServiceResult> => fetch(`${API_BASE}/publish/versions`, {
    data,
});

export const getById = (data: IdParams): PromiseLike<IServiceResult> => {
    const { id } = data;
    return fetch(`${API_BASE}/scenes/${id}`);
};

export const query = (data: IdParams): PromiseLike<IServiceResult> => fetch(`${API_BASE}/publish/query`, {
    method: 'post',
    data,
});

const syncDevAddress = [
    'https://qa.igame.163.com', // 'http://qa.meety.my'
    [
        {
            platform: 'iphone', // 平台名称
            clientVersionOld: '', // 配置项支持的最老板版本的版本号
            clientVersionNew: '', // 配置项支持的最新版本的版本号
            configValue: '' // 'http://z1.baecdn.com/gmoyi-languages-aws-cdn/frontend-310.zip'
        },
        {
            platform: 'android',
            clientVersionOld: '',
            clientVersionNew: '',
            configValue: '' // http://z1.baecdn.com/gmoyi-languages-aws-cdn/frontend-310.zip'
        },
    ],
    /* tslint:disable-next-line */
    '88B63C1D4B03A68121898B1AE28CEFF83203B90EB776FD5F10C24DA4E45B5C0FE7B6BE965355950211F87C6BB80D9718B00227A82EA9218426E257F2E0A2D6184080CFCE7D4364A421238CB01D15603A83D6016DD18CD837D476F2C0B1988EFFAB8178C3DABAA285892A3078E39B665E2468413F0DEF77031189FFD6D039A745',
];

const sycnAddresses: any = {
    '127.0.0.1:7204': syncDevAddress,
    'i18n.qa.meety.my': syncDevAddress,
    'i18n.baechat.my': [
        'https://music.163.com',
        [
            {
                platform: 'iphone',
                clientVersionOld: '',
                clientVersionNew: '',
                configValue: ''
            },
            {
                platform: 'android',
                clientVersionOld: '',
                clientVersionNew: '',
                configValue: ''
            },
        ],
        /* tslint:disable-next-line */
        '5D91326956F9CF40C574DAC907E857511E03EDE42ADA0711A8C0C2C41252C9708980EF56D8AE53E3DBE71CF12C9DE9EB15DE2A65F8028BE45D9606D6F887BE3EA534FFAB86C730D511D169356797E8AD0E7E8769D8C9001A255DEDF919B1CFA8E965C1491047511BB56EA04F73C66DA93640B74D9C9222CB01280459F2BA99C7',
    ],
};

export const sync = (data: IdParams): PromiseLike<any> => {
    const syncAddress = Object.keys(sycnAddresses)
        .find((item: any) => window.location.host.indexOf(item) !== -1);
    if (syncAddress) {
        const syncTarget = sycnAddresses[syncAddress];
        return fetch(`${API_BASE}/publish/clientcfg/item/value/update`, {
            method: 'post',
            data: {
                appName: 'gmoyi',
                moduleName: 'i18n',
                configKey: 'i18n_zip',
                configValueType: 1,
                configValueList: [
                    {
                        ...syncTarget[1][0],
                        configValue: data
                    },
                    {
                        ...syncTarget[1][1],
                        configValue: data
                    },
                ],
                targetUrl: `${syncTarget[0]}/api/middle/clientcfg/openapi/support/item/value/batch/update`,
                targetToken: syncTarget[2]
            }
        }).then((res) => {
            if (!(res && res.data && res.data.success)) {
                return {
                    code: -1,
                    message: '同步失败'
                };
            }
            return fetch(`${API_BASE}/publish/clientcfg/item/value/push`, {
                method: 'post',
                data: {
                    appName: 'gmoyi',
                    keyDetails: [
                        {
                            moduleName: 'i18n',
                            configKeys: ['i18n_zip']
                        },
                    ],
                    platforms: [
                        'iphone',
                        'android',
                    ],
                    pushAllPlatform: false,
                    pushDesc: '多语言信息地址推送',
                    targetUrl: syncTarget[0],
                    targetToken: syncTarget[2]
                }
            }).then(() => ({
                code: 200,
                message: 'success'
            })).catch((err) => ({
                code: -1,
                message: err?.msg || '同步失败'
            }));
        }).catch((err) => ({
            code: -1,
            message: err?.msg || '同步失败'
        }));
    }

    return Promise.reject(new Error('未找对对应的同步目标'));
};

export const create = (data: any): PromiseLike<IServiceResult> => {
    const { sceneId } = data;
    return fetch(`${API_BASE}/publish/${sceneId}/create`, {
        method: 'post',
        data,
    });
};
export const update = (data: IScene): PromiseLike<IServiceResult> => {
    const { id } = data;
    return fetch(`${API_BASE}/scenes/${id}`, {
        method: 'put',
        data: {
            ...data,
            language: (data.languages || []).join(','),
        },
    });
};

export const getFebaseApps = (): PromiseLike<IServiceResult> => fetch(`${API_BASE}/publish/getFebaseApps`, {
    method: 'get',
});

export const getProjectBranch = (data: any): PromiseLike<IServiceResult> => fetch(`${API_BASE}/publish/getProjectBranch`, {
    method: 'get',
    data
});

export const publishApp = (data: any): PromiseLike<IServiceResult> => fetch(`${API_BASE}/publish/publishApp`, {
    method: 'post',
    data
});
