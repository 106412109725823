import React from 'react';
import { ModalProps } from 'antd/lib/modal';
import {
    Modal, Card, Button, Divider, Radio
} from 'antd';
import { IDocument, IDocumentSet, ILanguageMap } from '@/typings/IServiceModel';
import styles from './style.module.less';

interface IDataSource {
    source?: IDocumentSet;
    id?: number;
    desc: string;
    documents: IDocument[][];
}

interface IDocumentMatchModalProps extends ModalProps {
    onSubmit: (data: IDataSource[], select: number[]) => void;
    onImport?: (value: IDocument[], source?: IDocumentSet) => void;
    sceneLanguage: string[];
    languageMap: ILanguageMap;
    dataSource: IDataSource[];
    i18n: any;
}

export default class DocumentMatchModal extends React.PureComponent<IDocumentMatchModalProps> {
    state = {
        radioSelect: [], // 单选框选择项
    };

    trans = () => {
        const {
            i18n
        } = this.props;
        return i18n.getFixedT(null, ['textManage']);
    };

    onOk = (): void => {
        const { onSubmit, dataSource } = this.props;
        const { radioSelect } = this.state;

        if (onSubmit) {
            onSubmit(dataSource, radioSelect);
        }
    }

    modalTitle = (): string => {
        const trans = this.trans();
        return trans('textEdit');
    }

    onImport = (index: number, source?: IDocumentSet): void => {
        const {
            dataSource,
            onImport
        } = this.props;
        const { radioSelect } = this.state;
        const select = radioSelect[index] || 0;
        if (onImport) {
            onImport(dataSource[index].documents[select], source);
        }
    }

    onChange = (e: any, index: number) => {
        const { radioSelect } = this.state;
        const arr: number[] = [...radioSelect];
        arr[index] = e.target.value;
        this.setState({ radioSelect: arr });
    };

    render() {
        const {
            onOk,
            onSubmit,
            sceneLanguage,
            languageMap,
            dataSource,
            i18n,
            ...rest
        } = this.props;

        const length = dataSource.length;
        return (
            <Modal
                keyboard={false}
                destroyOnClose
                maskClosable={false}
                onOk={this.onOk}
                width={700}
                title="匹配文案"
                okText="全部导入"
                {...rest}>
                <div>
                    {dataSource.map((item, index: number) => (
                        <div key={index}>
                            <Card
                                title={`匹配结果${item.source?.id ? `(id:${item.source?.id})` : ''}`}
                                extra={(
                                    <Button
                                        type="primary"
                                        onClick={() => this.onImport(index, item?.source)}>
                                        导入
                                    </Button>
                                )}>
                                <b>描述：</b>
                                {item.desc}
                                <Divider />
                                <Radio.Group defaultValue={0} onChange={(e) => this.onChange(e, index)}>
                                    {item?.documents?.map((item1, idx) => (
                                        <>
                                        <Radio value={idx} key={idx} className={styles.documentRadioGroup}>
                                            <div className={styles.documentRadio}>
                                                {item1.map((data) => (
                                                    <div key={data.languageCode}>
                                                        <b>{`${languageMap[data.languageCode]}: `}</b>
                                                        <br />
                                                        {data.text}
                                                    </div>
                                                ))}
                                            </div>
                                        </Radio>
                                        {idx < item?.documents.length - 1 ? <Divider /> : null}
                                        </>
                                    ))}
                                </Radio.Group>
                            </Card>
                            {index < length - 1 ? <Divider /> : null}
                        </div>
                    ))}
                </div>
            </Modal>
        );
    }
}
