import React, { Fragment } from 'react';
import { Layout, Spin, Button, Icon, Select, message } from 'antd';
import { connect } from 'dva';
import memoizeFn from '@music/tl-memoize-fn';
import AddSceneModal from '@/component/AddSceneModal';
import PageSider from '@/page/PageSider';
import WorkShop from '@/page/WorkShop';
import { isEmpty, get } from 'lodash';
import { IScene, ISceneCreate } from '@/typings/IServiceModel';
import { IRouteProps, } from '@/typings/IRoute';
import { routeMapStateToProps } from '@/util/helper';
import { Route } from 'dva/router';
import styles from './style.module.less';

const { Header, Content } = Layout;

interface HomeState {
    isShowAddSceneModal: boolean;
    currentScene: IScene | null;
    title: string;
    isNew: boolean;
    helpModalVisible: boolean;
}

@connect(routeMapStateToProps)
export default class Home extends React.PureComponent<IRouteProps, HomeState> {
    state = {
        helpModalVisible: true,
        isNew: false,
        title: '应用创建',
        currentScene: null,
        isShowAddSceneModal: false,
    };

    componentDidMount(): void {
        const {
            dispatch,
        } = this.props;

        Promise.all([
            this.getSceneList(),
            dispatch({
                type: 'language/get',
            }),
        ]).then(() => {
            const { match, sceneList, history, login } = this.props;
            if (isEmpty(sceneList) && !login?.userInfo?.isThird) {
                dispatch({
                    type: 'scene/create',
                    payload: {
                        name: '请选择',
                        aliasName: '0',
                        languages: ['zh-CN'],
                        receiver: ''
                    },
                }).then(({ data }) => {
                    this.getSceneList();
                    history.push(`/${data?.id}/authority/apply${history.location.search}`);
                });
            }
            if (match.path === '/' && !match.params.sceneId && sceneList.length > 0) {
                const redirectUrl = login?.userInfo?.scenes?.length ? login.userInfo.scenes[0].id : sceneList[0].id;
                history.push(`/${redirectUrl}/authority/apply${history.location.search}`);
            }
        }).catch((err) => {
            message.error(err.message || '请求失败，请重试');
        });
    }

    private getSceneList = (): Promise<any> => {
        const {
            dispatch,
        } = this.props;

        return dispatch({
            type: 'sceneList/get',
        });
    }

    private renderUser = (): JSX.Element | null => {
        const { login, dispatch } = this.props;

        if (!login?.userInfo?.nickName) {
            return null;
        }
        return (
            <span className={styles.user}>
                <span>{login.userInfo.nickName}</span>
                <span>|</span>
                <span className={styles.logout} onClick={() => {
                    dispatch({
                        type: 'login/logout'
                    }).then(() => {
                        window.location.reload();
                    });
                }}>
                    <Icon type="logout" />
                </span>
            </span>
        );
    }

    @memoizeFn(
        ['props', 'loading', 'effects', 'sceneList/get'],
        ['props', 'loading', 'effects', 'user/get'],
        ['props', 'loading', 'effects', 'scene/create']
    )
    private isLoading(...args: boolean[]): boolean {
        return args.includes(true);
    }

    private renderSceneSelect = (): JSX.Element | null => {
        const { sceneList, scene, history, login } = this.props;
        // if (isEmpty(sceneList)) {
        //     return null;
        // }

        const sceneItemList = login.userInfo.permission === 300 ? [...sceneList] : [...(login?.userInfo?.scenes || [])];

        return (
            <Select
                showSearch
                style={{
                    width: 180,
                    marginRight: 20,
                }}
                value={scene ? scene.id : undefined}
                filterOption={(input, option) => {
                    if (!option) return false;
                    const children = option!.props!.children;
                    return (children as string).toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0;
                }}
                onChange={(value: number) => {
                    const path = this.props.location.pathname + this.props.location.search;
                    // 修改:sceneId 并充值:groupId
                    history.push(path.replace(/\/\d{1,}\//g, `/${value}/`).replace(/\/\d{1,}$/, '/0'));
                }}>
                {sceneItemList.map((item: IScene) => (
                    <Select.Option key={item.id} value={item.id}>
                        {item.name}
                    </Select.Option>
                ))}
            </Select>
        );
    }

    private createScene = (value: ISceneCreate) => {
        const { dispatch, scene } = this.props;
        const { isNew } = this.state;
        dispatch({
            type: isNew ? 'scene/create' : 'scene/update',
            payload: value,
        }).then(() => {
            return Promise.all([
                dispatch({
                    type: 'scene/get',
                    payload: {
                        id: scene!.id,
                    },
                }),
                dispatch({
                    type: 'sceneList/get',
                }),
            ]);
        }).then(() => {
            message.success(isNew ? '创建成功' : '编辑成功');
            this.setState({
                isShowAddSceneModal: false,
            });
        }).catch((err) => {
            message.error(err.message || '请求失败，请重试');
        });
    }

    private renderAddSceneBtn = (): JSX.Element => {
        const { isShowAddSceneModal, currentScene, title, isNew } = this.state;
        const { language, scene, login } = this.props;
        return (
            <Fragment>
                {
                    login?.userInfo?.permission === 300 ? <Button type="primary" onClick={() => {
                        this.setState({
                            title: '应用创建',
                            isNew: true,
                            currentScene: null,
                            isShowAddSceneModal: true,
                        });
                    }}>
                        <Icon type="plus" />
                        {'添加应用'}
                    </Button> : null
                }
                <Button disabled={login?.userInfo?.isThird} type="primary" style={{ marginLeft: '10px' }} onClick={() => {
                    this.setState({
                        title: '应用编辑',
                        isNew: false,
                        currentScene: scene,
                        isShowAddSceneModal: true,
                    });
                }}>
                    <Icon type="edit" />
                    {'编辑应用'}
                </Button>
                <AddSceneModal
                    isNew={isNew}
                    title={title}
                    login={login}
                    dataSource={currentScene || undefined}
                    languageList={language}
                    visible={isShowAddSceneModal}
                    onSubmit={this.createScene}
                    onCancel={() => {
                        this.setState({
                            currentScene: null,
                            isShowAddSceneModal: false,
                        });
                    }}
                />
            </Fragment>
        );
    }

    private layoutWith = (): string => {
        return 'calc(100% - 200px)';
    }

    private renderHelpModal = () => {
        const { helpModalVisible } = this.state;
        return (
            <div style={helpModalVisible ? { transform: 'translateX(0px)' } : {}} className={styles.helpModal}>
                {/* <span onClick={this.handleSetHelpModal} className={styles.leftHelpBtn}>
                    <i>
                        <Icon style={helpModalVisible ? { transform: 'translateX(3px)' } : {}} className={styles.leftHepBtnRow} type="right" />
                    </i>
                </span> */}
                <div className={styles.helpList}>
                    <a href="https://g.hz.netease.com/cloudmusic-frontend/bcontent/music-i18n/-/issues" target="_blank">
                        <span className={styles.helpItemLeft}>
                            问题反馈
                        </span>
                        <span className={styles.helpItemRight}>
                            <Icon type="double-right" />
                        </span>
                    </a>
                    <a href="https://music-rtfm.hz.netease.com/i18n" target="_blank">
                        <span className={styles.helpItemLeft}>
                            帮助文档
                        </span>
                        <span className={styles.helpItemRight}>
                            <Icon type="double-right" />
                        </span>
                    </a>
                </div>
            </div>
        );
    }

    render() {
        const { match } = this.props;
        const sceneId = get(match, 'params.sceneId', 0);
        return (
            <Spin spinning={this.isLoading()}>
                <Layout style={{ height: '100%' }}>
                    <Header className={styles.header}>
                        <div className={styles.headerLeft}>
                            <div className={styles.logo}>
                                <img src="https://p5.music.126.net/obj/wonDlsKUwrLClGjCm8Kx/14409615635/51bc/1a3c/9d6e/ecfa058d21263b4ab32420db89f07174.png" />
                            </div>
                            <div className={styles.title}>千语</div>
                            {this.renderSceneSelect()}
                            {this.renderAddSceneBtn()}
                        </div>
                        {this.renderUser()}
                    </Header>
                    <Layout style={{
                        flexDirection: 'row'
                    }}>
                        <Route
                            path={[
                                '/:sceneId/language',
                                '/:sceneId/group',
                                '/:sceneId/document',
                                '/:sceneId/package',
                                '/:sceneId/publish',
                                '/:sceneId/authority/check',
                                '/:sceneId/authority/userlist',
                                '/:sceneId/authority/apply',
                                '/:sceneId/log',
                                '/:sceneId/apps',
                            ]}
                            component={PageSider} />
                        <Layout style={{ padding: 24, width: this.layoutWith() }}>
                            <Content
                                style={{
                                    background: '#fff',
                                    padding: 24,
                                    margin: 0,
                                    overflow: 'auto',
                                    minHeight: 400,
                                }}
                            >
                                <Route
                                    key={sceneId}
                                    path={'/:sceneId'}
                                    component={WorkShop} />
                            </Content>
                        </Layout>
                    </Layout>
                </Layout>

                {this.renderHelpModal()}
            </Spin>
        );
    }
}
