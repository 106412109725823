import { API_BASE } from '@/util/const';
import fetch from '@/util/fetch';
import { IServiceResult, IdParams, IPageParams } from '@/typings/IServiceModel';

export const getList = (data: IPageParams): PromiseLike<IServiceResult> => {
    const {
        id,
        ...rest
    } = data;
    return fetch(`${API_BASE}/scenes/${id}/packages`, {
        data: rest,
    });
};

export const create = (data: IdParams): PromiseLike<IServiceResult> => {
    const {
        id,
    } = data;
    return fetch(`${API_BASE}/scenes/${id}/packages`, {
        method: 'post',
    });
};
