import ReactDOM from 'react-dom';

export const formatDate = (d: any, f = 'yyyy-MM-dd') => {
    let date = d;
    let fmt: string = f;
    if (!date) return '';
    if (typeof date === 'string') {
        date = new Date(date);
    }
    if (!(date instanceof Date)) {
        date = new Date(date);
    }

    const o: any = {
        'M+': date.getMonth() + 1, // 月份
        'd+': date.getDate(), // 日
        'h+': date.getHours(), // 小时
        'm+': date.getMinutes(), // 分
        's+': date.getSeconds(), // 秒
        'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
        S: date.getMilliseconds() // 毫秒
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1,
            (`${date.getFullYear()}`).substr(4 - RegExp.$1.length));
    }
    Object.keys(o).forEach((k: string) => {
        if (new RegExp(`(${k})`).test(fmt)) {
            fmt = fmt.replace(RegExp.$1,
                (RegExp.$1.length === 1)
                    ? (o[k]) : ((`00${o[k]}`).substr((`${o[k]}`).length)));
        }
    });
    return fmt;
};

export const withBatch = (callback: any): void => ReactDOM.unstable_batchedUpdates(callback);

export const loadImageAsync = (file: any) => new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onload = (v) => {
        const img = new Image();
        img.onload = () => {
            resolve({
                width: img.width,
                height: img.height
            });
        };
        img.onerror = () => {
            // eslint-disable-next-line prefer-promise-reject-errors
            reject('图片加载失败');
        };

        const csv: string | ArrayBuffer = v?.target?.result || '';
        img.src = typeof csv === 'string' ? csv : Buffer.from(csv).toString();
    };
    fr.readAsDataURL(file);
});
export const getDocumnetLen = (str: string, fontSize = '12px') => {
    let result = 0;

    const ele = document.createElement('span');
    ele.innerText = str;
    ele.style.cssText = `font-size: ${fontSize}; white-space: nowrap;`;

    document.documentElement.append(ele);

    result = ele.offsetWidth;

    document.documentElement.removeChild(ele);
    return result;
};

export default {};
