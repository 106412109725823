export const clientEnum: any = {
    1: {
        client: 'ios',
        enum: ['%s']
    },
    2: {
        client: 'android',
        enum: []
    },
    3: {
        client: 'frontend',
        enum: []
    },
    4: {
        client: 'backend',
        enum: []
    },
    5: {
        client: 'flutter',
        enum: []
    },
};

export const docsValid = (client: number, str: any) => {
    const clientArr = clientEnum[client]?.enum || [];
    for (const key in clientArr) {
        if (Object.prototype.hasOwnProperty.call(clientArr, key)) {
            const el = clientArr[key];
            if ((str || '').toString().includes(el)) {
                return el;
            }
        }
    }
    return false;
};
