import { API_BASE } from '@/util/const';
import fetch from '@/util/fetch';
import {
    IServiceResult,
    IAddUserSceneModal,
    IUserList,
    IUserPermission,
    IUserEditPermission,
    IEditSupAdmPermission
} from '@/typings/IServiceModel';

export const get = (): PromiseLike<IServiceResult> => fetch(`${API_BASE}/user`);

// 添加用户应用权限
export const addUserScene = (data: IAddUserSceneModal): PromiseLike<IServiceResult> => fetch(`${API_BASE}/user/addScene`, {
    method: 'post',
    data
});

// 获取用户列表
export const getUserList = (data: IUserList): PromiseLike<IServiceResult> => fetch(`${API_BASE}/user/getUserList`, {
    data
});

// 获取单个用户权限列表
export const getUserPermission = (data: IUserPermission): PromiseLike<IServiceResult> => fetch(`${API_BASE}/user/userScenesList`, {
    data
});

// 删除用户应用权限
export const delUserPermission = (data: IUserEditPermission): PromiseLike<IServiceResult> => fetch(`${API_BASE}/user/deleteUserScene`, {
    method: 'post',
    data
});

// 获取超管列表
export const getSuperAdminList = (): PromiseLike<IServiceResult> => fetch(`${API_BASE}/user/getSuperAdminList`, {
    data: {}
});

// 编辑超管（添加、删除）
export const editSuperAdmin = (data: IEditSupAdmPermission): PromiseLike<IServiceResult> => fetch(`${API_BASE}/user/editSuperAdmin`, {
    method: 'post',
    data
});
