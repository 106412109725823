import React from 'react';
import {
    Button,
    Table,
    Form,
    Modal,
    Checkbox,
    message,
    Select,
    Popconfirm,
    Icon,
    Divider,
} from 'antd';
import { IRouteProps } from '@/typings/IRoute';
import { IFebaseItem } from '@/typings/IServiceModel';
import { connect } from 'dva';
import PublishForm from './form';
import { routeMapStateToProps } from '@/util/helper';
import styles from './style.module.less';

const { Option } = Select;

interface IPublishPageState {
    isInit: boolean;
    modalVisible: boolean;
    downloadModalVisible: boolean;
    confirmLoading: boolean;
    previewModalVisible: boolean;
    languages: any;
    previewLanguages: any;
    loading: boolean;
    febaseModalVisible: boolean;
    febaseList: IFebaseItem[];
    febaseSelectOptions: any[];
    branchList: any[];
    selectBranchName: string | undefined;
    selectProject: IFebaseItem | undefined;
    showPublishSuccessModal: boolean;
    febaseAppUrl: string;
}

// 0=未发布，1=发布中，2=成功，3=失败
const statusMap = [
    '未发布',
    '发布中',
    '成功',
    '失败',
];
// 文案
const labelMap = {
    STATUS_SUCESS: '发布成功',
    STATUS_FAILURE: '发布失败',
    MESSAGE_UNSELECTED_LANGUAGE: '未选择下载语言',
};

// 语言包映射
const languageOptions = [
    { label: 'iOS', value: 'ios' },
    { label: 'Android', value: 'android' },
    { label: '前端', value: 'frontend' },
    { label: '服务端', value: 'backend' },
    { label: 'Flutter', value: 'flutter' },
];

const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000; // 时区相差，单位为秒

// 下载文件方法
function downloadFile(url: string) {
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none'; // 防止影响页面
    iframe.style.height = '0'; // 防止影响页面
    iframe.src = url;
    document.body.appendChild(iframe);  // 这一行必须，iframe挂在到dom树上才会发请求
    setTimeout(() => {
        iframe.remove();
    }, 5 * 60 * 1000);
}

type iPublishPage = IRouteProps & {
    [key: string]: any;
};

@connect(routeMapStateToProps)
export default class PublishPage extends React.PureComponent<iPublishPage, IPublishPageState> {
    state = {
        isInit: true,
        modalVisible: false,
        confirmLoading: false,
        downloadModalVisible: false,
        previewModalVisible: false,
        languages: [],
        currentVersion: '',
        previewLanguages: [],
        loading: false,
        febaseModalVisible: false,
        febaseList: [],
        branchList: [],
        febaseSelectOptions: [],
        selectBranchName: '',
        showPublishSuccessModal: false,
        febaseAppUrl: '',
        selectProject: {
            name: ''
        },
    };

    current = null; // 记录当前发布记录

    PublishWrapperForm = Form.create({ name: 'publish_form' })(PublishForm);

    viewActionHelp = () => {
        Modal.info({
            title: '同步操作仅前端需要',
            content: (
                <div>
                    <h4>配置中心地址</h4>
                    <ul>
                        <li>测试：https://cms.qa.igame.163.com/clientcfg/config/1094002/module/2334003</li>
                        <li>线上：https://music-cms.hz.netease.com/clientcfg/config/1065801/module/1095002</li>
                        </ul>
                </div>
            )
        });
    }

    columns = [
        {
            title: '发布版本',
            key: 'version',
            dataIndex: 'version',
            render: (_: any, record: any) => {
                const address = JSON.parse(record.address) || {};

                if (address.version) {
                    return `v${address.version}`;
                } else {
                    return '-';
                }
            }
        },
        {
            title: '发布描述',
            key: 'description',
            dataIndex: 'description',
        },
        {
            title: '发布时间',
            key: 'updatedAt',
            dataIndex: 'updatedAt',
            render: (updatedAt: any) => {
                const operateTime = new Date(new Date(updatedAt).getTime() - timezoneOffset);
                const updateAtSplits = operateTime.toISOString().split(/T|\./);

                return updateAtSplits.slice(0, 2).join(' ');
            }
        },
        {
            title: '发布状态',
            key: 'status',
            dataIndex: 'status',
            render: (status: any) => statusMap[status],
        },
        {
            title: '发布人',
            key: 'operator',
            dataIndex: 'operator',
        },
        {
            title: <div>操作<Icon onClick={this.viewActionHelp} type="question-circle" /></div>,
            key: 'action',
            render: (_: any, record: any) => {
                if (record.status === 2) {
                    return (
                        <span className={styles.actionDone}>
                            <a onClick={() => this.onPreview(record)}>预览</a>
                            <a onClick={() => this.onDownload(record)}>下载</a>
                            <Popconfirm
                                title="【仅前端使用】确认同步到客户端配置中心么？"
                                onConfirm={() => this.onSync(record)}
                                okText="确认"
                                cancelText="取消"
                            >
                                <a href="#">同步</a>
                            </Popconfirm>
                        </span>
                    );
                } else if (record.status === 3) {
                    return (
                        <span>
                            <a onClick={() => this.onViewLog(record)}>查看日志</a>
                        </span>
                    );
                } else {
                    return (
                        <span>
                            -
                        </span>
                    );
                }
            },
        },
    ];

    onPreview = (record: any) => {
        this.current = record;

        if (this.current) {
            this.setState({
                previewModalVisible: true,
            });
        }
    }

    // 传入语言对象，获取地址对象，用来兼容老数据结构
    getForeignAddressObj = (obj: any) => {
        if (obj.hasOwnProperty('uri')) return obj;
        return obj.foreign;
    }

    onSync = (record: any) => {
        try {
            const address = JSON.parse(record.address || '{}');

            if (address.frontend) {
                const addrObj = this.getForeignAddressObj(address.frontend);
                let languageZipFile = [addrObj.hosts[0], addrObj.uri].join('/');

                if (languageZipFile.indexOf('http') === -1) {
                    languageZipFile = `http://${languageZipFile}`;
                }

                const { dispatch } = this.props;

                dispatch({
                    type: 'publish/sync',
                    payload: languageZipFile as any
                }).then((res) => {
                    if (res && res.code === 200) {
                        message.success('同步成功');
                    } else {
                        message.error(res.message || '同步失败！');
                    }
                }).catch((err) => {
                    message.error(err.message);
                });
            } else {
                message.warn('不存在` 前端 `语言包');
            }
        } catch (e) {
            message.error(e.message);
        }
    }

    getLanguageText = (languages = []) => {
        return languages.reduce((accumulator: any, current) => {
            const existed = languageOptions.find((item) => item.value === current);

            if (existed) {
                accumulator.push(existed.label);
            }

            return accumulator;
        }, []);
    }

    onPreviewLanguage = (language: any) => {
        try {
            const item = this!.current as any;
            const address = JSON.parse(item.address || '{}');

            if (address[language]) {
                const addrObj = this.getForeignAddressObj(address[language]);

                let languageZipFile = [addrObj.hosts[0], addrObj.uri].join('/');

                if (languageZipFile.indexOf('http') === -1) {
                    languageZipFile = `http://${languageZipFile}`;
                }

                window.open(`/api/publish/preview?zip=${languageZipFile}&id=${item.id}&type=${language}`, '_blank');
            } else {
                message.warn(`不存在\` ${(this as any).getLanguageText([language]).join(',')} \`语言包`);
            }
        } catch (e) {
            message.error(e.message);
        }
    }

    onDownload = (record: any) => {
        this.setState({
            downloadModalVisible: true,
        });

        this.current = record;
    }

    onViewLog = (record: any) => {
        this.current = record;
    }

    getList = () => {
        const { dispatch, match, publishList } = this.props;

        Promise.all([
            dispatch({
                type: 'publish/getList',
                payload: {
                    sceneId: match.params.sceneId,
                    page: {
                        current: publishList.current,
                        size: publishList.size,
                    },
                },
            }),
            dispatch({
                type: 'publish/getVersions',
                payload: {
                    sceneId: match.params.sceneId,
                },
            }),
        ]).catch((err) => {
            message.error(err.message);
        }).finally(() => {
            this.setState({
                isInit: true,
            });
        });
    }

    componentDidMount() {
        this.getList();
        this.fetchFebaseApps();
    }

    showModal = () => {
        this.setState({
            modalVisible: true,
        });
    }

    hideModal = () => {
        this.setState({
            modalVisible: false,
        });
        this.getList();
    }

    handleOk = () => {
        this.setState({
            confirmLoading: true,
        });

        const timer = setTimeout(() => {
            clearTimeout(timer);
            message.info(labelMap.STATUS_SUCESS);
            this.setState({
                modalVisible: false,
                confirmLoading: false,
            });
        }, 5000);
    }

    handleCancel = () => {
        this.setState({
            modalVisible: false,
        });
    }

    handleOkForDownload = async () => {
        const { languages } = this.state;

        if (!languages || !languages.length) {
            message.warn(labelMap.MESSAGE_UNSELECTED_LANGUAGE);
            return;
        }

        if (this.current) {
            try {
                const address = JSON.parse((this!.current as any).address || '{}');
                const languageAddresses: any = [];
                const noexisted: any = [];

                languages.forEach((current) => {
                    if (address[current]) {
                        // 随机下载任意 cdn zip,默认下载国外的资源，反正国内国外资源一样
                        const addrObj = this.getForeignAddressObj(address[current]);
                        let cdnUrl = `${addrObj.hosts[Math.floor(Math.random() * addrObj.hosts.length)]}/${addrObj.uri}`;

                        if (cdnUrl.indexOf('http') === -1) {
                            cdnUrl = `https://${cdnUrl}`;
                        }

                        languageAddresses.push(cdnUrl);
                    } else {
                        noexisted.push(current);
                    }
                });

                if (noexisted.length) {
                    message.error(`不存在语言包:\` ${(this as any).getLanguageText(noexisted).join(',')} \`, 无法下载！`);
                    return;
                }

                if (languageAddresses.length) {
                    languageAddresses.forEach((element: any) => {
                        let targetUrl = element;

                        if (Array.isArray(targetUrl)) {
                            targetUrl = targetUrl[0];
                        }

                        downloadFile(targetUrl); // 循环调用方法
                    });

                    const timer = setTimeout(() => {
                        clearTimeout(timer);
                        this.setState({
                            downloadModalVisible: false,
                            languages: [],
                        });
                    });
                } else {
                    message.error('不存在语言包');
                }
            } catch (e) {
                message.error(e.message);
            }
        }
    }

    handleCancelForDownload = () => {
        this.setState({
            downloadModalVisible: false,
        });
    }

    handleFebaseOk = () => {
        const { selectBranchName, selectProject } = this.state;
        const { dispatch, login } = this.props;
        this.setState({
            loading: true,
            febaseModalVisible: false,
        });
        dispatch({
            type: 'publish/publishApp',
            payload: {
                appId: (selectProject as (IFebaseItem | undefined))?.id,
                branch: selectBranchName,
                operator: login?.userInfo?.mailAddress,
                envType: 'online'
            },
        }).then((res) => {
            if (res.code === 200) {
                this.setState({
                    febaseAppUrl: res?.data?.result?.extendInfo?.febaseAppUrl?.replace('overview', 'sprint'),
                    showPublishSuccessModal: true,
                });
            } else {
                message.error(res?.result?.message);
            }
        }).catch((err) => {
            message.error(err?.message);
        }).finally(() => {
            this.setState({
                loading: false,
            });
        });
    }

    handleFebaseCancel = () => {
        this.setState({
            febaseModalVisible: false,
        });
    }

    closeResultModal = () => {
        this.setState({
            showPublishSuccessModal: false
        });
    }

    fetchFebaseApps = () => {
        const { dispatch } = this.props;
        this.setState({
            loading: true,
        });

        dispatch({
            type: 'publish/getFebaseApps',
        }).then((res) => {
            if (res?.data?.result?.rows) {
                const selectOptions = res.data?.result?.rows?.map((item: IFebaseItem) => ({
                    value: item.id,
                    text: item.name
                }));
                this.setState({
                    febaseList: res.data?.result?.rows || [],
                    febaseSelectOptions: selectOptions
                });
            }
        }).finally(() => {
            this.setState({
                loading: false,
            });
        });
    }

    onFebaseSelectId = (val: number) => {
        const { dispatch } = this.props;
        const { febaseList } = this.state;
        this.setState({
            loading: true,
        });
        // 选择应用之后需要选择分支
        const selectFebaseProject = febaseList.find((item: IFebaseItem) => item.id === val);
        dispatch({
            type: 'publish/getProjectBranch',
            payload: {
                gitName: (selectFebaseProject as (IFebaseItem | undefined))?.git,
            },
        }).then((res) => {
            if (res.code === 200) {
                this.setState({
                    branchList: res?.data?.result || [],
                    selectProject: selectFebaseProject
                });
            }
        }).finally(() => {
            this.setState({
                loading: false,
            });
        });
    }

    onBranchSelectId = (val: string) => {
        this.setState({
            selectBranchName: val,
        });
    }

    handleOkForPreview = () => {
        this.setState({
            previewModalVisible: true,
        });
    }

    handleCancelForPreview = () => {
        this.setState({
            previewModalVisible: false,
        });
    }

    onChangeVersion = (value: any) => {
        const { dispatch, match, publishList } = this.props;

        this.setState({
            loading: true,
        });

        dispatch({
            type: 'publish/getList',
            payload: {
                sceneId: match.params.sceneId,
                version: value,
                page: {
                    current: 1,
                    size: publishList.size,
                },
            },
        }).finally(() => {
            this.setState({
                loading: false,
            });
        });

    }

    onSelectLanguage = (selectedLanguages: any) => {
        this.setState({
            languages: selectedLanguages,
        });
    }

    onPublish = () => {
        this.setState({
            confirmLoading: true,
        });

        setTimeout(() => {
            message.info(labelMap.STATUS_SUCESS);
            this.setState({
                modalVisible: false,
                confirmLoading: false,
            });
        }, 5000);
    }

    onChangePage = (value: any) => {
        const { dispatch, match, publishList } = this.props;

        dispatch({
            type: 'publish/getList',
            payload: {
                sceneId: match.params.sceneId,
                page: {
                    current: value,
                    size: publishList.size,
                },
            }
        }).catch((err) => {
            message.error(err.message);
        });
    }

    render() {
        const { publishList, publishVersions, match } = this.props;
        const sceneId = parseInt(match.params.sceneId, 10);
        const {
            modalVisible,
            downloadModalVisible,
            previewModalVisible,
            febaseModalVisible,
            febaseSelectOptions,
            branchList,
            selectBranchName,
            selectProject,
            showPublishSuccessModal,
            febaseAppUrl,
            loading,
        } = this.state;
        const PublishWrapperForm = (this.PublishWrapperForm as any);
        const versions = [{
            id: '-1',
            version: '全部',
        }].concat(publishVersions.map((item) => ({
            id: `${item}`,
            version: `v${item}`,
        }))).sort((a, b) => Number(b.id) - Number(a.id));

        return (
            <div className={styles.publish}>
                <Button type="primary" onClick={this.showModal}>新建发布</Button>
                <div className={styles.marginOptions}></div>
                <label htmlFor="language-version">发布版本: &nbsp;</label>
                <Select
                    showSearch
                    style={{ width: 200 }}
                    placeholder="发布版本"
                    allowClear
                    optionFilterProp="children"
                    onChange={this.onChangeVersion}
                    filterOption={(input, option) => {
                        if (option) {
                            return (option!.props!.children! + '').toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        } else {
                            return false;
                        }
                    }}>
                    {
                        versions.map((version: any) => (
                            <Option
                                key={version.id}
                                value={version.id}>
                                {version.version}
                            </Option>
                        ))
                    }
                </Select>
                {
                    febaseSelectOptions?.length ? (
                        <div>
                            <Divider orientation="left">Febase操作</Divider>
                            <label>我的Febase应用: &nbsp;</label>
                            <div className={styles.marginOptions}></div>
                            <Select
                                showSearch
                                disabled={loading}
                                style={{ width: 200, marginRight: 10 }}
                                placeholder="我的Febase应用"
                                allowClear
                                optionFilterProp="children"
                                onChange={this.onFebaseSelectId}
                                filterOption={(input, option) => {
                                    if (option) {
                                        return (option!.props!.children! + '').toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    } else {
                                        return false;
                                    }
                                }}>
                                {
                                    febaseSelectOptions.map((option: any) => (
                                        <Option
                                            key={option.value}
                                            value={option.value}>
                                            {option.text}
                                        </Option>
                                    ))
                                }
                            </Select>
                            <label>Febase应用分支: &nbsp;</label>
                            <Select
                                showSearch
                                style={{ width: 200, marginRight: 10 }}
                                placeholder="Febase应用分支"
                                allowClear
                                optionFilterProp="children"
                                onChange={this.onBranchSelectId}
                                disabled={!branchList?.length || loading}
                                filterOption={(input, option) => {
                                    if (option) {
                                        return (option!.props!.children! + '').toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    } else {
                                        return false;
                                    }
                                }}>
                                {
                                    branchList.map((option: any) => (
                                        <Option
                                            key={option.name}
                                            value={option.name}>
                                            {option.name}
                                        </Option>
                                    ))
                                }
                            </Select>
                            <Button type="primary" disabled={!selectBranchName || loading} onClick={() => {
                                this.setState({
                                    febaseModalVisible: true
                                });
                            }}>部署Febase分支</Button>
                            <div className={styles.marginBlock}></div>
                        </div>
                    ) : null
                }

                <Table
                    rowKey="id"
                    loading={loading}
                    dataSource={publishList.data}
                    columns={this.columns}
                    pagination={{
                        current: publishList.current,
                        total: publishList.total,
                        onChange: this.onChangePage,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showSizeChanger: true,
                    }} />
                <Modal
                    title="新建发布"
                    visible={modalVisible}
                    onOk={this.handleOk}
                    destroyOnClose
                    onCancel={this.handleCancel}
                    footer={null}
                    maskClosable={false}>
                    <PublishWrapperForm sceneId={sceneId} hideModal={this.hideModal} />
                </Modal>
                <Modal
                    title="发布结果"
                    visible={showPublishSuccessModal}
                    onOk={this.closeResultModal}
                    destroyOnClose
                    onCancel={this.closeResultModal}
                    maskClosable={false}>
                        <div>
                            Febase接口调用成功，<a href={febaseAppUrl} target="_blank">点此查看分支部署结果</a>
                        </div>
                </Modal>
                <Modal
                    title="发布febase应用"
                    visible={febaseModalVisible}
                    onOk={this.handleFebaseOk}
                    destroyOnClose
                    onCancel={this.handleFebaseCancel}
                    maskClosable={false}>
                        <div>
                            确定部署项目<span className={styles.bold}>{selectProject?.name}</span>的<span className={styles.bold}>{selectBranchName}</span>分支到线上环境吗？
                        </div>
                </Modal>
                <Modal
                    title="下载语言包"
                    visible={downloadModalVisible}
                    onOk={this.handleOkForDownload}
                    destroyOnClose
                    maskClosable={false}
                    onCancel={this.handleCancelForDownload}>
                    <Checkbox.Group options={languageOptions} onChange={this.onSelectLanguage} />
                </Modal>
                <Modal
                    title="预览"
                    visible={previewModalVisible}
                    onOk={this.handleOkForPreview}
                    destroyOnClose
                    maskClosable={false}
                    onCancel={this.handleCancelForPreview}
                    footer={null}>
                    {
                        languageOptions.map((languageOption) => (
                            <Button
                                key={languageOption.value}
                                className={styles.languageBtn}
                                onClick={() => this.onPreviewLanguage(languageOption.value)}>
                                {languageOption.label}
                            </Button>
                        ))
                    }
                </Modal>
            </div>
        );
    }
}
