import * as appService from '@/service/apps';
import { IAppsModel } from '@/typings/IServiceModel';
import { message } from 'antd';

// 曲库标签数据
const apps: IAppsModel = {
    namespace: 'apps',

    state: {
        data: null,
        sceneId: 0,
        total: 0,
        size: 2,
        current: 1,
        query: ''
    },

    effects: {
        * get(action, { call, put }) {
            try {
                const result = yield call(appService.get, action.payload);
                const data = (result.data || {});

                yield put({
                    type: 'setState',
                    payload: {
                        data: result.code === 200 ? ((result.data || {}).records || []) : [],
                        total: data.page.total,
                        query: action.payload.query
                    },
                });
            } catch (err) {
                message.error(err.message);
                yield put({
                    type: 'setState',
                    payload: {
                        data: null,
                    },
                });
            }
        },
        * delete(action, { call }) {
            const payload = action.payload;
            yield call(appService.remove, payload);
        },
        * setCurrent(action, { put }) {
            yield put({
                type: 'setState',
                payload: {
                    current: ((action.payload && action.payload.current) || 1),
                },
            });
        }
    },

    reducers: {
        setState(state, { payload }) {
            return { ...state, ...payload };
        },
    },
};

export default apps;
