import React from 'react';
import { connect } from 'dva';
import { IRouteProps } from '@/typings/IRoute';
import { routeMapStateToProps } from '@/util/helper';
import { PackageStatus, PackageStatusMap } from '@/util/const';
import { isEmpty } from 'lodash';
import { message, Spin, Table, Empty, Form, Button, } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import {
    IPackage, IPackageFile,
} from '@/typings/IServiceModel';
import { DvaLoadingEffect } from '@/typings/IDva';
import memoizeFn from '@music/tl-memoize-fn';
import styles from './style.module.less';

interface IPackagePageProps extends FormComponentProps, IRouteProps {

}

class PackagePage extends React.PureComponent<IPackagePageProps> {
    private packageFileColumns: Array<ColumnProps<IPackageFile>> = [
        {
            title: '语言',
            dataIndex: 'language',
            key: 'language',
            render: (text) => {
                return this.props.languageMap[text] || text;
            },
        },
        {
            title: 'hash',
            dataIndex: 'hash',
            key: 'hash',
        },
        {
            title: '文件类型',
            dataIndex: 'fileType',
            key: 'fileType',
        },
        {
            title: '',
            dataIndex: 'nosKey',
            key: 'nosKey',
            render: (text) => (
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`http://music-i18n.nos-jd.163yun.com/${text}`}>查看</a>
            ),
        },
    ];
    private packageColumns: Array<ColumnProps<IPackage>> = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '版本',
            dataIndex: 'version',
            key: 'version',
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: (status: PackageStatus): string => PackageStatusMap[status] || '---',
        },
        {
            title: '发布时间',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (time: string): string => moment(time).format('YYYY-MM-DD HH:mm:ss'),
        },
        {
            title: '发布人',
            dataIndex: 'creator',
            key: 'creator',
        },
    ];

    componentDidMount(): void {
        const { dispatch } = this.props;

        dispatch({
            type: 'packageList/get',
            payload: {
                offset: 0,
            },
        }).catch((err) => {
            message.error(err.message || '数据获取失败，请重试');
        });
    }

    private createPackage = () => {
        const { dispatch, } = this.props;

        if (this.isCreating()) {
            return;
        }

        dispatch({
            type: 'packageList/create',
        }).then(() => {
            message.success('语言包创建成功');
        }).then(() => {
            return dispatch({
                type: 'packageList/get',
            });
        }).catch((err) => {
            message.error(err.message || '数据获取失败，请重试');
        });
    };

    @memoizeFn(
        ['props', 'loading', 'effects', 'packageList/get'],
        ['props', 'loading', 'effects', 'packageList/create']
    )
    isLoading(...args: DvaLoadingEffect[]) {
        return args.includes(true);
    }

    @memoizeFn(
        ['props', 'loading', 'effects', 'packageList/create']
    )
    isCreating(...args: DvaLoadingEffect[]) {
        return args.includes(true);
    }

    @memoizeFn('props.packageList')
    getAllPackageIdList(list?: IPackage[]) {
        return (list || []).map((item: IPackage) => (item.id));
    }

    private renderPackageFileList = (packageItem: IPackage) => {
        if (isEmpty(packageItem.packageFiles)) {
            return <Empty/>;
        }
        return (
            <Table
                rowKey="id"
                pagination={false}
                dataSource={packageItem.packageFiles}
                columns={this.packageFileColumns}
            />
        );
    };

    render() {
        const { packageListPagination, dispatch, packageList } = this.props;
        const { offset, total, limit, } = packageListPagination;
        return (
            <div className={styles.ctn}>
                <Spin spinning={this.isLoading()}>
                    <div className={styles.publishBtnCtn}>
                        <Button
                            type="primary"
                            onClick={this.createPackage}>发布语言包</Button>
                    </div>
                    <Table
                        rowKey="id"
                        expandedRowKeys={this.getAllPackageIdList()}
                        expandIcon={() => <div/>}
                        expandRowByClick
                        defaultExpandAllRows={true}
                        pagination={{
                            hideOnSinglePage: true,
                            total,
                            pageSize: limit,
                            current: offset <= 0 ? 1 : Math.ceil(total / offset / limit) + 1,
                            onChange: (page) => {
                                dispatch({
                                    type: 'packageList/get',
                                    payload: {
                                        offset: (page - 1) * limit,
                                    },
                                }).catch((err) => {
                                    message.error(err.message || '数据获取失败，请重试');
                                });
                            },
                        }}
                        expandedRowRender={this.renderPackageFileList}
                        dataSource={packageList}
                        columns={this.packageColumns}
                    />
                </Spin>
            </div>
        );
    }
}

export default connect(routeMapStateToProps)(Form.create<IPackagePageProps>()(PackagePage));
