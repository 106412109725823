import React from 'react';
import { ModalProps } from 'antd/lib/modal';
import { Modal } from 'antd';
import SceneForm, { ISceneFormRef } from '@/component/SceneForm';
import { ILanguage, ISceneCreate, ILogin } from '@/typings/IServiceModel';

interface IAddSceneModalProps extends ModalProps {
    isNew: boolean;
    languageList?: ILanguage[];
    onSubmit: (value: ISceneCreate) => void;
    dataSource?: ISceneCreate;
    login?: ILogin;
}

export default class AddSceneModal extends React.PureComponent<IAddSceneModalProps> {
    private ref: ISceneFormRef | null = null;

    onOk = (): void => {
        if (!this.ref) {
            return;
        }

        this.ref.preCheck((errors, value: ISceneCreate) => {
            const { onSubmit, dataSource } = this.props;
            if (errors) {
                return;
            }

            if (!value?.id) {
                value.id = dataSource?.id;
            }

            if (onSubmit) {
                onSubmit(value);
            }
        });
    }

    render() {
        const {
            isNew = false,
            onOk,
            onSubmit,
            languageList,
            login,
            dataSource,
            title = '应用创建',
            ...rest
        } = this.props;
        return (
            <Modal
                keyboard={false}
                destroyOnClose
                maskClosable={false}
                onOk={this.onOk}
                width={760}
                title={title}
                {...rest}
            >
                <SceneForm
                    login={login}
                    dataSource={dataSource}
                    languageList={languageList || []}
                    wrappedComponentRef={(ref: ISceneFormRef) => {
                        this.ref = ref;
                    }}
                    isNew={isNew}
                />
            </Modal>
        );
    }
}
