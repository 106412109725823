/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { FormComponentProps, ValidateCallback } from 'antd/lib/form';
import { IDocumentSetCreate, ILanguageMap, ILanguage, IGroup, IDocument, IDocumentSet } from '@/typings/IServiceModel';
import { DocumentSetType, DocumentSetTypeMap, clientMap } from '@/util/const';
import { DocumentFormWithoutFormProps as DocumentForm } from '@/component/DocumentForm';
import { DocumentImageFormWithoutFormProps as DocumentImageForm } from '@/component/DocumentImageForm';
import * as documentSetService from '@/service/documentSet';
import DocumentMatchModal from '@/component/DocumentMatchModal';
import UploadView from '@music/mobile-upload';
import s3Uploader from '@music/tl-s3-uploader';
import {
    Input, Form, Select, message, Button
} from 'antd';
import { get, uniqWith, isEqual } from 'lodash';
import Config from '@/config/index';
import styles from './style.module.less';

const host = window.location.host;
const {
    bizKey, bucket, nosTokenApi
} = Config;

interface IDocumentSetFormProps extends FormComponentProps {
    onChange?: (value: IDocumentSetCreate) => void;
    isNew: boolean;
    groupId?: number;
    groupList: IGroup[];
    dataSource?: IDocumentSetCreate;
    sceneLanguage: string[];
    languageList: ILanguage[];
    languageMap: ILanguageMap;
    keyUniqueCheck?: (value: string) => Promise<boolean>;
    keyGenerator?: () => Promise<string>;
    i18n: any;
}

interface IDocumentSetFormState {
    isUniqueKey: boolean;
    isCheckingUniqueKey: boolean;
    isShowDescMatch: boolean;
    type: string;
    isShowDocumentMatchModal: boolean;
    matchDataSource: Array<{
        desc: string,
        documents: IDocument[][]
    }>;
}

interface IObjectKeys {
    [key: string]: string | number | undefined;
}
export interface IDocumentSetFormRef extends JSX.Element {
    preCheck: (cb: ValidateCallback<IDocumentSetCreate>) => {};
}

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};

class DocumentSetForm extends React.PureComponent<IDocumentSetFormProps, IDocumentSetFormState> {
    state = {
        isUniqueKey: false,
        isCheckingUniqueKey: false,
        isShowDescMatch: false, // 是否存在相同描述的项
        matchDataSource: [], // 相同描述的项的搜索结果
        type: DocumentSetType.text,
        isShowDocumentMatchModal: false
    };

    uploadRef = React.createRef<any>();

    preCheck = (cb: ValidateCallback<IDocumentSetCreate>) => {
        this.props.form.validateFieldsAndScroll(cb);
    }

    trans = () => {
        const {
            i18n
        } = this.props;
        return i18n.getFixedT(null, ['textManage']);
    };

    renderDocumentList = () => {
        const {
            isNew,
            sceneLanguage,
            languageMap,
            languageList,
            form,
        } = this.props;
        const trans = this.trans();

        if (!isNew || sceneLanguage.length === 0) {
            return null;
        }

        let DocumentFormItem = DocumentForm;
        let title = trans('textList');
        if (this.state.type === DocumentSetType.image) {
            DocumentFormItem = DocumentImageForm;
            title = trans('picList');
        }

        const lngMap = { ...languageMap };

        sceneLanguage.forEach((key) => {
            lngMap[key] = trans(key.split('-').join('_'));
        });

        return (
            <div className={styles.documentListCtn}>
                <div className={styles.documentListTitle}>{title}</div>
                {sceneLanguage.map((languageCode: string, index: number) => (
                    <DocumentFormItem
                        dataSourcePrefix={`documents.${index}.`}
                        form={form}
                        languageList={languageList}
                        key={trans(languageCode.split('-').join('_'))}
                        isNew={isNew}
                        dataSource={{
                            text: '',
                            languageCode,
                        }} />
                ))}
            </div>
        );
    }

    generateKey = () => {
        const { keyGenerator, form } = this.props;
        const trans = this.trans();
        if (!keyGenerator) {
            return;
        }
        keyGenerator().then((result) => {
            form.setFieldsValue({
                key: result,
            });
        }).catch((err) => {
            message.error(err.message || trans('keyGenerateFail'));
        });
    }

    handleSelectChange = (value: any) => {
        this.setState({ type: value });
    };

    // 从剪贴板拿取图片并上传
    paste = (event: any) => {
        const items = event.clipboardData && event.clipboardData.items;
        const file = items[0].getAsFile();
        if (file?.type?.startsWith('image') && this.uploadRef.current) {
            this.uploadRef.current?.uploadBtn?.retry(file, 0);
        }
    }

    onDescBlur = (event: any) => {
        const {
            isNew,
            sceneLanguage,
        } = this.props;
        // 编辑弹层不去查询接口
        if (!isNew || sceneLanguage.length === 0) {
            return null;
        }
        const value = event.target?.value;
        if (!value.trim()) {
            return;
        }
        documentSetService.getMatchDesc({
            client: 0,
            groupId: 0,
            desc: JSON.stringify([value]),
            sceneLanguage: JSON.stringify(sceneLanguage)
        }).then((res) => {
            if (res?.code === 200 && res?.data?.total > 0) {
                const list = res?.data?.list || [];
                // 用于去除重复的项
                const tempList = uniqWith(list, (a: IDocumentSet, b: IDocumentSet) => {
                    return isEqual(a.documents, b.documents);
                });
                // 检测搜索的接口是否存在文案
                const tempDocument: IDocument[][] = [];
                for (const item of tempList) {
                    tempDocument.push(item.documents);
                }
                if (tempDocument.length) {
                    this.setState({ isShowDescMatch: true, matchDataSource: [{ desc: value, documents: tempDocument }] });
                }
            } else {
                this.setState({ isShowDescMatch: false });
            }
        });
    }

    onDescFocus = () => {
        this.setState({ isShowDescMatch: false });
    }

    checkDesc = () => {
        this.setState({
            isShowDocumentMatchModal: true,
        });
    }

    onImport = (data: IDocument[]) => {
        const {
            form,
            sceneLanguage,
        } = this.props;
        const obj: IObjectKeys = {};
        for (const item of data) {
            const index = sceneLanguage.indexOf(item.languageCode);
            obj[`documents.${index}.text`] = item.text;
        }
        form.setFieldsValue(obj);
        this.setState({
            isShowDocumentMatchModal: false,
        });
    }

    componentDidMount() {
        // 避免重复创建，直接覆盖的写法
        document.onpaste = this.paste;
    }

    componentWillUnmount() {
        document.onpaste = null;
    }

    render() {
        const {
            form,
            dataSource,
            keyUniqueCheck,
            isNew,
            groupId,
            groupList,
            i18n,
            sceneLanguage,
            languageMap,
            // keyGenerator,
        } = this.props;
        const { isShowDescMatch, isShowDocumentMatchModal, matchDataSource } = this.state;
        const { getFieldDecorator } = form;
        const trans = this.trans();

        // const isShowKeyGeneratorBtn = isNew && keyGenerator;
        return (
            <>
            <Form
                {...formItemLayout}
                layout="horizontal">
                <Form.Item
                    label={trans('moduleSelect')}
                    key="groupId">
                    {getFieldDecorator('groupId', {
                        validateFirst: true,
                        initialValue: groupList.some((el) => el.id === groupId) ? groupId : undefined,
                        rules: [
                            {
                                required: true,
                                message: ` ${trans('notNull')}`,
                            },
                        ],
                    })(
                        <Select
                            showSearch
                            filterOption={(input, option) => {
                                if (!option) return false;
                                const children = option.props.children;
                                return (Array.isArray(children)
                                    ? children.join('')
                                    : (children as string))
                                    .toLocaleLowerCase()
                                    .indexOf(input.toLocaleLowerCase()) >= 0;
                            }}
                        >
                            {groupList.map((item: any) => (
                                <Select.Option key={item.id} value={item.id}>
                                    {item.name}{item.key ? `(${item.key})` : ''}
                                </Select.Option>
                            ))}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item
                    label="key"
                    key="key">
                    {getFieldDecorator('key', {
                        validateFirst: true,
                        validateTrigger: ['onSubmit', 'onBlur'],
                        initialValue: get(dataSource, 'key', ''),
                        rules: [
                            {
                                required: true,
                                message: ` ${trans('notNull')}`,
                            },
                            {
                                max: 255,
                                message: `${trans('lengthLimit')}`,
                            },
                            {
                                pattern: /^[0-9a-zA-Z_]+$/,
                                message: ` ${trans('textLimit')}`,
                            },
                            {
                                validator: (_, value: string, cb) => {
                                    if (keyUniqueCheck && value) {
                                        keyUniqueCheck(value)
                                            .then((result: boolean) => {
                                                const currentKey = form.getFieldValue('key');

                                                // @ts-ignore
                                                if (currentKey !== value) {
                                                    cb();
                                                    return;
                                                }
                                                if (!result) {
                                                    cb(trans('existKey'));
                                                    return;
                                                }
                                                cb();
                                            })
                                            .catch((err) => {
                                                cb(err.message || trans('checkFail'));
                                            });
                                    } else {
                                        cb();
                                    }
                                },
                            },
                        ],
                    })(
                        <Input />
                    )}
                    {/* {isShowKeyGeneratorBtn && <Col offset={1} span={5}><Button onClick={this.generateKey} type="primary">自动生成</Button></Col>} */}
                </Form.Item>
                <Form.Item
                    label={trans('description')}
                    key="desc">
                    {getFieldDecorator('desc', {
                        validateFirst: true,
                        initialValue: get(dataSource, 'desc', ''),
                        rules: [
                            {
                                required: true,
                                message: ` ${trans('notNull')}`,
                            },
                            {
                                max: 255,
                                message: `${trans('lengthLimit')}`,
                            },
                        ],
                    })(
                        <Input onBlur={(e) => this.onDescBlur(e)} onFocus={() => this.onDescFocus()}/>
                    )}
                    {isShowDescMatch ? <Button type="link" onClick={() => this.checkDesc()}>检测到存在相同描述已翻译的项，点击查看</Button> : null}
                </Form.Item>
                <Form.Item
                    label={trans('client')}
                    key="client">
                    {getFieldDecorator('client', {
                        validateFirst: true,
                        initialValue: get(dataSource, 'client') ? get(dataSource, 'client', '').split(',') : undefined,
                        rules: [
                            {
                                required: true,
                                message: ` ${trans('notNull')}`,
                            },
                        ],
                    })(
                        <Select mode="multiple">
                            {clientMap.map((item: { key: string, value: number }) => (
                                <Select.Option key={item.value}>
                                    {item.key}
                                </Select.Option>
                            ))}
                        </Select>
                    )}
                </Form.Item>
                <Form.Item
                    label={trans('type')}
                    key="type">
                    {getFieldDecorator('type', {
                        validateFirst: true,
                        initialValue: get(dataSource, 'name', DocumentSetType.text),
                        rules: [
                            {
                                required: true,
                                message: ` ${trans('notNull')}`,
                            },
                        ],
                    })(
                        <Select disabled={!isNew} onChange={this.handleSelectChange}>
                            <Select.Option value={DocumentSetType.text}>
                                {DocumentSetTypeMap[DocumentSetType.text]}
                            </Select.Option>
                            <Select.Option value={DocumentSetType.image}>
                                {DocumentSetTypeMap[DocumentSetType.image]}
                            </Select.Option>
                        </Select>
                    )}
                </Form.Item>
                <Form.Item
                    label={trans('requestFrom')}
                    key="from">
                    {getFieldDecorator('from', {
                        validateFirst: false,
                        initialValue: get(dataSource, 'from', '')
                    })(
                        <Input />
                    )}
                </Form.Item>
                <Form.Item
                    label={trans('imgDesc')}
                    key="upload">
                    <div>针对有字数限制的文案，请务必截图</div>
                    <div>截图内容包括：该模块视觉样式+字数限制</div>
                    <div>在此区域内ctrl + V 即可直接上传</div>
                    {getFieldDecorator('upload', {
                        validateFirst: false,
                        initialValue: get(dataSource, 'upload', [])
                    })(
                        <UploadView
                            uploadMethod={s3Uploader} // s3上传必传字段
                            host={host} // 获取token、cdn地址的接口host，默认为window.location.host
                            path={nosTokenApi} // 获取token的接口path
                            bizKey={bizKey}
                            bucket={bucket}
                            ref={this.uploadRef}
                            multiple={false}
                            picLimit={1}
                            accept="image/bmp,image/jpg,image/jpeg,image/png,image/gif" />
                    )}
                </Form.Item>
                {this.renderDocumentList()}
            </Form>
            <DocumentMatchModal
                i18n={i18n}
                sceneLanguage={sceneLanguage}
                dataSource={matchDataSource}
                languageMap={languageMap}
                visible={isShowDocumentMatchModal}
                onSubmit={(data, radioSelect) => {
                    const select = radioSelect[0] || 0;
                    this.onImport(data[0].documents[select]);
                }}
                onImport={(data: IDocument[]) => this.onImport(data)}
                onCancel={() => {
                    this.setState({
                        isShowDocumentMatchModal: false,
                    });
                }}
            />
            </>
        );
    }
}

export default Form.create<IDocumentSetFormProps>()(DocumentSetForm);
export const DocumentSetFormWithoutFormProps = DocumentSetForm;
