/* eslint-disable react/jsx-filename-extension */
import React, { Fragment } from 'react';
import { FormComponentProps, ValidateCallback } from 'antd/lib/form';
import { IDocumentCreate, ILanguage } from '@/typings/IServiceModel';
import {
    Input, Form, Spin
} from 'antd';
import Config from '@/config/index';
import UploadView from '@music/mobile-upload';
import '@music/mobile-upload/dist/index.css'; // 引入 css 样式
import s3Uploader from '@music/tl-s3-uploader';

import { get } from 'lodash';

const {
    bizKey, bucket, nosTokenApi
} = Config;
const host = window.location.host;

interface IDocumentFormProps extends FormComponentProps {
    onChange?: (value: IDocumentCreate) => void;
    isNew: boolean;
    dataSource?: IDocumentCreate;
    languageList: ILanguage[];
    dataSourcePrefix?: string;
    updateService?: (value: IDocumentCreate, dataSource: IDocumentCreate) => Promise<boolean>;
}

interface IDocumentFormState {
    isUpdating: boolean;
}

class DocumentImageForm extends React.PureComponent<IDocumentFormProps, IDocumentFormState> {
    state = {
        isUpdating: false,
    };

    preCheck = (cb: ValidateCallback<IDocumentCreate>) => {
        this.props.form.validateFields(cb);
    }

    onBlur = () => {
        // const {
        //     isNew,
        //     dataSource,
        //     updateService,
        //     dataSourcePrefix,
        // } = this.props;

        // const { isUpdating } = this.state;

        // if (isNew || !updateService || isUpdating || !dataSource) {
        //     return;
        // }

        // this.preCheck((err, value) => {
        //     if (err) {
        //         return;
        //     }
        //     this.setState({
        //         isUpdating: true,
        //     });

        //     updateService({
        //         text: get(value, `${dataSourcePrefix}text`),
        //         id: get(value, `${dataSourcePrefix}id`),
        //         languageCode: get(value, `${dataSourcePrefix}languageCode`),
        //     }, dataSource).then((result) => {
        //         if (!result) {
        //             throw new Error();
        //         }
        //         this.setState({
        //             isUpdating: false,
        //         });
        //     }).catch((err) => {
        //         message.error(err.message || '文案更新失败');
        //         this.setState({
        //             isUpdating: false,
        //         });
        //     });
        // });
    }

    render() {
        const {
            form,
            dataSource,
            languageList,
            dataSourcePrefix = '',
        } = this.props;

        const { isUpdating } = this.state;

        if (!dataSource) {
            return null;
        }
        const { getFieldDecorator } = form;

        const label = languageList?.find((lang) => lang.code === dataSource.languageCode)?.desc
            || dataSource.languageCode;

        return (
            <Fragment>
                <Form.Item
                    style={{ display: 'none' }}
                    key="id">
                    {getFieldDecorator(`${dataSourcePrefix}id`, {
                        initialValue: dataSource.id || 0,
                    })(
                        <Input type="hidden" />
                    )}
                </Form.Item>
                <Form.Item
                    style={{ display: 'none' }}
                    key="languageCode">
                    {getFieldDecorator(`${dataSourcePrefix}languageCode`, {
                        initialValue: dataSource.languageCode,
                        rules: [
                            {
                                required: true,
                                message: '不得为空',
                            },
                        ],
                    })(
                        <Input type="hidden" />
                    )}
                </Form.Item>
                <Spin spinning={isUpdating}>
                    <Form.Item
                        label={`${label}: `}
                        key="image">
                        {getFieldDecorator(`${dataSourcePrefix}image`, {
                            validateFirst: true,
                            initialValue: get(dataSource, 'image', ''),
                        })(
                            <UploadView
                                uploadMethod={s3Uploader} // s3上传必传字段
                                host={host} // 获取token、cdn地址的接口host，默认为window.location.host
                                path={nosTokenApi} // 获取token的接口path
                                bizKey={bizKey}
                                bucket={bucket}
                                multiple={false}
                                picLimit={1}
                                accept="image/bmp,image/jpg,image/jpeg,image/png,image/gif" />
                        )}
                    </Form.Item>
                </Spin>

            </Fragment>
        );
    }
}

export default Form.create<IDocumentFormProps>()(DocumentImageForm);
export const DocumentImageFormWithoutFormProps = DocumentImageForm;
