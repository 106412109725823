import * as sceneService from '@/service/scene';
import { ISceneModel, IServiceResult, IScene } from '@/typings/IServiceModel';
import { IAction } from '@/typings/IDva';

interface GetPayload {
    id: number;
}

// 曲库标签数据
const scene: ISceneModel = {
    namespace: 'scene',

    state: {
        data: null,
        id: 0,
    },

    effects: {
        * get(action: IAction, { call, put }) {
            const payload = action.payload as GetPayload;
            try {
                const result: IServiceResult = yield call(sceneService.getById, payload);
                yield put({
                    type: 'setState',
                    payload: {
                        data: result.data as IScene,
                        id: payload.id,
                    },
                });
            } catch (err) {
                yield put({
                    type: 'setState',
                    payload: {
                        data: null,
                        id: payload.id,
                    },
                });
                throw err;
            }
        },
        * update(action: IAction, { call }) {
            const payload = action.payload as IScene;
            try {
                yield call(sceneService.update, payload);
            } catch (err) {
                throw err;
            }
        },
        * create(action: IAction, { call }) {
            const payload = action.payload;
            return yield call(sceneService.create, payload);
        },
    },

    reducers: {
        setState(state, { payload }) {
            return { ...state, ...payload };
        },
    },
};

export default scene;
