export const API_BASE: string = '/api';

export enum DocumentSetType {
    image = 'image',
    text = 'text',
    // 'constant',
}

export const DocumentSetTypeMap = {
    [DocumentSetType.image]: '图片',
    [DocumentSetType.text]: '文本',
    // [DocumentSetType.constant]: '通用文案',
};

export enum PackageStatus {
    pending,
    done,
    error,
}

export const PackageStatusMap = {
    [PackageStatus.pending]: '发布中',
    [PackageStatus.done]: '已发布',
    [PackageStatus.error]: '发布失败',
};

export const ExcelDocumentMap = [
    { key: '描述(必填)(根据group_key、document_key匹配文案,无则新增,有则更新;翻译内容可为空;只将要修改和新增的数据放在excel导入)' },
    { key: '图片描述(非必填)(图片中会包含视觉样式+字数要求)' },
    { key: 'group_key(非必填)' },
    { key: 'document_key(非必填)' },
    { key: '文本类型(必填,示例:text)' },
    { key: '所属端(必填,1:ios,2:android,3:frontend,4:backend,5:flutter,示例:1,2)' },
    { key: '创建人' },
];

export const clientMap = [
    { key: 'ios', value: 1 },
    { key: 'android', value: 2 },
    { key: 'frontend', value: 3 },
    { key: 'backend', value: 4 },
    { key: 'flutter', value: 5 },
];
