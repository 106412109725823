/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import UploadView from '@music/mobile-upload';
import '@music/mobile-upload/dist/index.css'; // 引入 css 样式
import s3Uploader from '@music/tl-s3-uploader';
import Config from '@/config/index';
import styles from './style.module.less';

const {
    bizKey, bucket, nosTokenApi
} = Config;
const host = window.location.host;

interface IImageInputState {
    uploadValue: any;
}

interface IImageInputProp {
    style: any;
    value: string;
    onPressEnter: (value: string) => void;
}
class ImageInput extends React.PureComponent<IImageInputProp, IImageInputState> {
    state = {
        uploadValue: this.props.value ? [{ url: this.props.value }] : []
    };

    componentDidUpdate(prevProps: IImageInputProp) {
        if (this.props.value !== prevProps.value) {
            this.setState({ uploadValue: this.props.value ? [{ url: this.props.value }] : [] });
        }
    }

    onDelete = () => {
        this.props.onPressEnter('');
    }

    onChange = (list: any) => {
        if (list.length && list[0].hzUrl) {
            const url = list[0].hzUrl;
            this.props.onPressEnter(url);
        }
    }

    render() {
        return (
            <div className={styles.imageInput} style={this.props.style}>
                <UploadView
                    style={this.props.style}
                    uploadMethod={s3Uploader} // s3上传必传字段
                    host={host} // 获取token、cdn地址的接口host，默认为window.location.host
                    path={nosTokenApi} // 获取token的接口path
                    bizKey={bizKey}
                    bucket={bucket}
                    value={this.state.uploadValue}
                    onChange={this.onChange}
                    multiple={false}
                    onDelete={this.onDelete}
                    picLimit={1}
                    accept="image/bmp,image/jpg,image/jpeg,image/png,image/gif" />
            </div>
        );
    }
}

export default ImageInput;
