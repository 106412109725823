import React from 'react';
import { connect } from 'dva';
import { IRouteProps, IRouteConfig } from '@/typings/IRoute';
import { routeMapStateToProps } from '@/util/helper';
// import { message } from 'antd';
import { Route, Switch, Redirect, } from 'dva/router';
import Language from '../Language';
import Package from '../Package';
import Publish from '../Publish';
import Apps from '../Apps';
import Log from '../Log';
import Group from '../Group';
import Document from '../Document';
import Authority from '../Authority';
import AuthorityApply from '../Authority/apply';
import Check from '../Authority/check-center';

interface IWorkShopState {
    isInit: boolean;
}

@connect(routeMapStateToProps)
export default class WorkShop extends React.PureComponent<IRouteProps, IWorkShopState> {
    state = {
        isInit: false,
    };

    componentDidMount(): void {
        const { match, dispatch } = this.props;
        const { params } = match;
        Promise.all([
            dispatch({
                type: 'scene/get',
                payload: {
                    id: params.sceneId,
                },
            }),
            dispatch({
                type: 'groupList/get',
                payload: {
                    id: params.sceneId,
                },
            }),
        ]).catch(() => {
            // message.error(err.message);
        }).finally(() => {
            this.setState({
                isInit: true,
            });
        });
    }

    render() {
        const { match, scene, login, history } = this.props;
        const { isInit } = this.state;
        const { params } = match;

        if (!scene || (login?.userInfo?.permission !== 300 && !login?.userInfo?.scenes?.length)) {
            return (
                <Switch>
                    <Route
                        path={`/:sceneId/authority/apply${history.location.search}`}
                        component={AuthorityApply} />
                    {
                        isInit ? (
                            <Redirect to={`/${params.sceneId}/authority/apply${history?.location?.search}`} />
                        ) : null
                    }
                </Switch>
            );
        }

        const filteredRoutes = (): IRouteConfig[] => {
            let routes: IRouteConfig[] = [
                {
                    path: '/:sceneId/apps',
                    component: Apps
                },
                {
                    path: '/:sceneId/language',
                    component: Language
                },
                {
                    path: '/:sceneId/group',
                    component: Group
                },
                {
                    path: '/:sceneId/document',
                    component: Document
                },
                {
                    path: '/:sceneId/package',
                    component: Package
                },
                {
                    path: '/:sceneId/publish',
                    component: Publish
                },
                {
                    path: '/:sceneId/authority/apply',
                    component: AuthorityApply
                },
                {
                    path: '/:sceneId/authority/userlist',
                    component: Authority
                },
                {
                    path: '/:sceneId/authority/check',
                    component: Check
                },
                {
                    path: '/:sceneId/log',
                    component: Log
                },
            ];

            if (login?.userInfo?.permission === 300) {
                return routes;
            }

            const currentScene = login?.userInfo?.scenes?.find((item) => item.id === scene.id);
            // 已审核翻译人员只能看到文案
            if (currentScene?.scene_user?.pms === 50) {
                routes = [];
                if (currentScene?.scene_user?.status === 2) {
                    routes.push({
                        path: '/:sceneId/document',
                        component: Document
                    });
                }
            }

            // 至少要能够看到权限
            routes.push({
                path: '/:sceneId/authority/apply',
                component: AuthorityApply
            });

            return routes;
        };

        return (
            <Switch>
                {
                    filteredRoutes().map((item) => (
                        <Route
                            key={item.path}
                            path={`${item.path}`}
                            component={item.component} />
                    ))
                }
                <Redirect to={`/${params.sceneId}/authority/apply${history.location.search}`} />
            </Switch>
        );
    }
}
