/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */

import React from 'react';
import { connect } from 'dva';
import {
    message, Select, Button, Table, Modal, Input
} from 'antd';
import { IRouteProps } from '@/typings/IRoute';
import { routeMapStateToProps } from '@/util/helper';
import * as userService from '@/service/user';
import * as applyService from '@/service/apply';
import ExpandedRow from '../components/expandedRow';

import { sendPopoNotice } from '@/service/documentSet';
import { IScene, IUser, IApply } from '@/typings/IServiceModel';
import styles from './style.module.less';

const { confirm } = Modal;

interface IEditModalProps {
    userApp: number;
    listLoading: boolean;
    userAppList: any[];
    userPermissionList: any[];
    isThird: boolean;
    curItem: IApply;
    applyVisible: boolean;
    reason: string;
    applyPms: number;
}

interface IStatus {
    [key: number]: string;
}

const STATUS_MAP: IStatus = {
    0: '已拒绝',
    1: '申请中',
    2: '已拥有'
};

@connect(routeMapStateToProps)
export default class Authority extends React.PureComponent<IRouteProps, IEditModalProps> {

    constructor(props: IRouteProps) {
        super(props);
        this.renderClientSelect = this.renderClientSelect.bind(this);
    }

    state = {
        userApp: this.props.sceneList[0]?.id || 0,
        listLoading: false,
        userAppList: [],
        userPermissionList: [],
        isThird: false,
        applyVisible: false,
        reason: '',
        applyPms: 50,
        curItem: { userId: 0, pms: 0, sceneId: 0, status: 0, id: 0 }
    };

    userIdentity: IStatus = {
        100: '成员',
        200: '管理员',
        300: '超级管理员',
        50: '翻译人员',
    };

    // 获取用户权限列表
    getUserPermissionList = async (isInit?: boolean) => {
        this.setState({ listLoading: true });
        const { match } = this.props;
        const userApp = +match?.params?.sceneId;
        const { data } = await applyService.getUserApplyList({
            userId: this.props.login.userInfo?.userId || 0
        });
        const scenes = data?.result || [];

        await Promise.all(scenes.map(async (el: any) => {
            // 申请中，需要获取对应应用的管理员列表
            if (el.status === 1) {
                const result = await userService.getUserList({
                    pms: 200,
                    sceneId: el.sceneId,
                    userInfo: '',
                    limit: 1000,
                    offset: 0,
                });
                // 获取当前所选应用管理员列表
                const ownerList = result?.data?.result || [];
                el.owners = ownerList.map((user: IUser) => user.mailAddress).join('\n');
            }
        }));

        if (isInit) {
            this.setState({
                userPermissionList: scenes,
                userApp: userApp || this.props.sceneList[0]?.id || 0,
                listLoading: false
            });
        } else {
            this.setState({
                userPermissionList: scenes,
                listLoading: false
            });
        }
    };

    // 申请权限，默认申请翻译权限：50
    handleAddUserPermission = async (pms = 50) => {
        const res = await applyService.applyUserScene({
            sceneId: this.state.userApp,
            userId: this.props.login.userInfo?.userId || 0,
            status: 1, // 申请中
            pms
        });

        if (res?.data?.result) {
            this.sendPopo(this.state.userApp, pms);
            this.getUserPermissionList();
        } else {
            message.error('该应用权限已申请或已拥有');
        }
    };

    async sendPopo(sceneId: number, pms: number) {
        const { login } = this.props;

        try {
            const result = await userService.getUserList({
                pms: 200,
                sceneId,
                userInfo: '',
                limit: 1000,
                offset: 0,
            });
            const userList = result?.data?.result || [];

            // 获取当前所选应用管理员列表
            const receivers = userList.map((user: IUser) => user.mailAddress).join(',');
            const authAddress = `${window.location.origin}/${sceneId}/authority/check`;
            const noticeContent = `用户：${login?.userInfo?.nickName}\r\n申请权限：${this.userIdentity[pms]}\r\n请尽快审核，审核地址：${authAddress}`;
            if (!receivers) {
                throw Error('当前应用无管理员存在，请联系平台开发');
            }

            await sendPopoNotice({
                receiver: receivers,
                content: noticeContent
            });
            message.success('申请成功，已popo通知应用管理员');
        } catch (e) {
            message.error(e.message || '通知失败，请主动联系当前应用管理员');
        }
    }

    // 撤回申请
    onRetractReply = async (scene: IApply) => {
        const { sceneName, id } = scene;
        confirm({
            title: '提示',
            content: `确认撤回 “${sceneName}” 应用的权限申请吗？`,
            onOk: async () => {
                try {
                    await applyService.deleteApply({
                        applyId: id,
                    }).then((res) => {
                        if (res?.data?.result) {
                            message.success('成功撤回申请');
                            this.getUserPermissionList();
                        }
                    });
                } catch (e) {
                    message.error(e.message);
                }
            }
        });

    };

    renderClientSelect = () => {
        const { userApp } = this.state;
        return (
            <Select
                value={userApp}
                onChange={(value: number) => {
                    this.setState({ userApp: value });
                }}
                style={{
                    width: 200,
                    marginRight: 20,
                }}>
                {this.props.sceneList.map((item: IScene) => (
                    <Select.Option key={item.id} value={item.id}>
                        {`${item.name}(${item.aliasName})`}
                    </Select.Option>
                ))}
            </Select>
        );
    };

    componentDidMount() {
        const { login, sceneList } = this.props;
        this.getUserPermissionList(true);

        if (login?.userInfo?.permission === 300) {
            if (!sceneList.length) return;
            this.setState({
                userAppList: [...sceneList],
                isThird: false
            });
        } else {
            const list: any = login?.userInfo?.scenes?.filter((el: IScene) => el?.scene_user?.pms === 200);
            this.setState({
                userAppList: [...list],
                isThird: login?.userInfo?.isThird || false
            });
        }

    }

    columns = [
        {
            title: 'ID',
            key: 'sceneId',
            dataIndex: 'sceneId',
        },
        {
            title: '所属应用',
            render: (it: any) => (`${it.sceneName}(${it.sceneAliasName})`)
        },
        {
            title: '权限',
            render: (it: any) => (this.userIdentity[it.pms])
        },
        {
            title: '当前处理人',
            render: (it: any) => it.owners
        },
        {
            title: '当前状态',
            render: (it: any) => {
                const { status } = it;

                return (
                    <Button disabled={status !== 1} type="link">
                        {STATUS_MAP[status]}
                    </Button>
                );
            }
        },
        {
            title: '操作',
            render: (it: any) => {
                const { status } = it;

                if (status === 2) {
                    return;
                }

                if (status === 1) {
                    return (
                        <Button
                            type="link"
                            disabled={status === 2}
                            onClick={() => {
                                this.setState({ curItem: it });
                                this.onRetractReply(it);
                            }}>
                            撤回申请
                        </Button>
                    );
                }
                return (
                    [
                        <Button
                            type="link"
                            key="again"
                            onClick={() => {
                                this.setState({ curItem: it });
                                this.setState({ applyVisible: true, applyPms: it.pms });
                            }}>
                            重新发起
                        </Button>,
                        <Button
                            type="link"
                            key="cancel"
                            onClick={() => {
                                this.setState({ curItem: it });
                                this.onRetractReply(it);
                            }}>
                            作废
                        </Button>,
                    ]
                );
            }
        },
    ];

    handleCancel = () => {
        this.setState({
            applyVisible: false
        });
    };

    handleApply = async () => {
        const { reason, curItem } = this.state;
        const { sceneId, pms, id } = curItem;
        if (!reason) {
            message.error('申请理由不能为空');
            return;
        }
        await applyService.againApply({
            status: 1,
            reason,
            pms,
            id
        });
        this.sendPopo(sceneId, pms);
        this.getUserPermissionList();

        this.handleCancel();
    };

    render() {
        const { listLoading, userPermissionList, isThird, applyVisible, reason } = this.state;

        return (
            <div className={styles.modalContainer}>
                <div style={{ paddingBottom: 20 }}>
                    {/* @desc: 下拉选择你要申请的应用，然后点击右侧按钮申请对应的权限。申请完之后，如果该应用有管理员，那么管理员会收到权限申请的popo推送。 */}
                    {/* @direction:bottom */}
                    {/* @step: selectApp=1 */}
                    <span>选择应用：</span>
                    {this.renderClientSelect()}

                    <Button
                        disabled={isThird}
                        type="primary"
                        style={{ marginRight: 10 }}
                        onClick={() => this.handleAddUserPermission(100)}>申请开发权限</Button>
                    <Button type="primary" onClick={() => this.handleAddUserPermission(50)}>申请翻译权限</Button>
                </div>
                <Table
                    rowKey="id"
                    pagination={false}
                    loading={listLoading}
                    expandedRowRender={ExpandedRow}
                    columns={this.columns}
                    dataSource={userPermissionList} />

                <Modal
                    title="重新发起"
                    visible={applyVisible}
                    onOk={() => {
                        this.handleApply();
                    }}
                    onCancel={() => {
                        this.handleCancel();
                        this.setState({ applyVisible: false });
                    }}>
                    <Input
                        placeholder="请输入申请理由"
                        value={reason}
                        onChange={(e) => {
                            this.setState({ reason: e.target.value || '' });
                        }} />
                </Modal>
            </div>
        );
    }
}
