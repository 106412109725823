import React from 'react';
import { connect } from 'dva';
import { IRouteProps } from '@/typings/IRoute';
import { routeMapStateToProps } from '@/util/helper';
import { DocumentSetType, DocumentSetTypeMap, clientMap } from '@/util/const';
import { formatDate } from '@/util';
import { isEmpty, get } from 'lodash';
import { Storage } from '@music/helper';
import { Select, Button, message, Spin, Table, Form, Popconfirm, Input, Modal, Icon, DatePicker, Tooltip, Divider, Checkbox, Radio } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { ColumnProps } from 'antd/lib/table';
import DocumentSetFormModal from '@/component/DocumentSetFormModal';
import DocumentSetGroupModal from '@/component/DocumentSetGroupModal';
import ExportExcel from '@/component/ExportExcel';
import ImportExcel from '@/component/ImportExcel';
import BatchMatchBtn from '@/component/BatchMatchBtn';
import ColumnSet from '@/component/ColumnSet';
import { withTranslation } from '@music/mobile-i18n';
import { parse } from '@music/mobile-url';
import KeyInput from './keyinput';
import TextInput from './textinput';
import ImageInput from './imageinput';
import {
    IGroup,
    IDocumentSet,
    IDocumentTableItemUpdate,
    IGroupKeyValue,
    IDocumentSetCreate,
    IDocument,
} from '@/typings/IServiceModel';
import { DvaLoadingEffect } from '@/typings/IDva';
import memoizeFn from '@music/tl-memoize-fn';
import moment from 'moment';
import { docsValid, clientEnum } from '@/util/docsValid';
import styles from './style.module.less';

const { confirm } = Modal;
const { Local } = Storage;
const LocalKey = 'columnSet221014';

const getLengthColor = (percent: number) => {
    if (percent === -100) {
        return '#f3f3f3';
    } else if (Math.abs(percent) >= 50) {
        return 'red';
    } else if (Math.abs(percent) >= 30) {
        return 'orange';
    } else if (Math.abs(percent) > 10) {
        return '#ffff40';
    } else {
        return 'green';
    }
};

interface IColumn {
    label: any;
    value: any;
}

interface IDocumentState {
    isShowAddDocumentModel: boolean;
    isShowAddBatchDocumentModel: boolean;
    isShowTranslateSelectModel: boolean;
    isShowBatchTranslateSelectModel: boolean;
    currentEditDocumentSet: IDocumentSet | null;
    modalGroupDocumentSet: IDocumentSet | null;
    selectedRowKeys: React.Key[];
    modalGroupMultDocumentSet: IDocumentSet | null;
    client: number;
    search: string;
    searchValue: string;
    searchRange: any[];
    waitToFillArr: IGroupKeyValue[];
    isCreatingDocument: boolean;
    showBatchDelete: boolean;
    selectedRows: IDocumentSet[];
    isTranslator: boolean;
    sendPopoGroupIds: number[];
    filterLanguageCode: string;
    isFiltering: boolean;
    saveChangedDocsForAWhile: boolean;
    needTranslateRecord: IDocumentSet | null;
    selectTranslateCode: string[];
    columnSetCheckedValues: any[];
    columnSetOptions: IColumn[];
    batchTranslateLanguage: string;
}

interface IDocumentPageProps extends FormComponentProps, IRouteProps {

}

class DocumentPage extends React.PureComponent<IDocumentPageProps, IDocumentState> {
    private trans: (...args: any[]) => string;

    constructor(props: IRouteProps & IDocumentPageProps) {
        super(props);

        this.trans = props.i18n.getFixedT(null, ['textManage']);
    }

    exportRef = React.createRef<ExportExcel>();
    state = {
        isShowAddDocumentModel: false,
        isShowAddBatchDocumentModel: false,
        isShowTranslateSelectModel: false,
        isShowBatchTranslateSelectModel: false,
        currentEditDocumentSet: null,
        modalGroupDocumentSet: null,
        selectedRowKeys: [],
        modalGroupMultDocumentSet: null,
        client: 0,
        search: '',
        searchValue: '',
        searchRange: [],
        waitToFillArr: [],
        isCreatingDocument: false,
        showBatchDelete: false,
        selectedRows: [],
        isTranslator: false,
        sendPopoGroupIds: [],
        filterLanguageCode: 'all',
        isFiltering: false,
        saveChangedDocsForAWhile: false,
        needTranslateRecord: null,
        selectTranslateCode: [],
        columnSetCheckedValues: [],
        columnSetOptions: [],
        batchTranslateLanguage: '',
    };

    private documentSetColumns = (): Array<ColumnProps<IDocumentSet>> => {
        return [
            {
                title: 'id',
                dataIndex: 'id',
                key: 'id',
                fixed: 'left',
            },
            {
                title: '是否发布',
                dataIndex: 'isPulish',
                key: 'isPulish',
                fixed: 'left',
                render: (_text, record) => {
                    return (
                        <div className={record.isPublish ? styles.publish : styles.unPublish}>
                            {record.isPublish ? '已发布' : '未发布'}
                        </div>
                    );
                }
            },
            {
                title: 'key',
                dataIndex: 'key',
                key: 'key',
                fixed: 'left',
                render: (_text, record) => {
                    return (
                        <div className={styles.keyfrom}>
                            <KeyInput
                                disabled={this.state.isTranslator}
                                highLightValue={this.state.search}
                                record={record}
                                style={{ width: '100px' }}
                                value={record.key}
                                onBlur={this.updateDocumentSetTableItem}
                            />
                            {
                                record.from
                                    ? <a href={record.from} target="_blank"><Icon type="link" /></a>
                                    : null
                            }
                        </div>
                    );
                }
            },
            {
                title: this.trans('description'),
                dataIndex: 'desc',
                key: 'desc',
                fixed: 'left',
                render: (_text, record) => {
                    return (
                        <TextInput
                            style={{ width: '200px' }}
                            highLightValue={this.state.search}
                            disabled={this.state.isTranslator}
                            client={record.client}
                            value={record.desc}
                            onPressEnter={(value) => {
                                this.updateDocumentSetTableItem(record, { desc: value });
                            }}
                        />
                    );
                }
            },
            {
                title: this.trans('imgDescription'),
                dataIndex: 'imgDesc',
                key: 'imgDesc',
                fixed: 'left',
                render: (_text, record) => {
                    if (record.imgDesc) {
                        return <img title="点击打开" onClick={() => {
                            window.open(`//${record.imgDesc}`);
                        }} style={{
                            cursor: 'pointer',
                            maxWidth: 200
                        }} src={`//${record.imgDesc}`} />;
                    }
                    return null;
                }
            },
            {
                title: this.trans('type'),
                dataIndex: 'type',
                key: 'type',
                render: (text: DocumentSetType) => (DocumentSetTypeMap[text]),
            },
            {
                title: this.trans('module'),
                dataIndex: 'groupId',
                key: 'groupId',
                render: (_text, record) => {
                    return (
                        <Select
                            style={{ width: '200px' }}
                            placeholder={this.trans('moduleBelong')}
                            disabled={this.state.isTranslator}
                            value={record.groupId}
                            onChange={(e: any) => {
                                this.updateDocumentSetTableItem(record, { groupId: e });
                            }}
                        >
                            {this.props.groupList.map((item: IGroup) => (
                                <Select.Option key={item.id} value={item.id}>
                                    {`${item.name}(${item.key})`}
                                </Select.Option>
                            ))}
                        </Select>
                    );
                }
            },
            {
                title: this.trans('client'),
                dataIndex: 'client',
                key: 'client',
                render: (_text, record) => {
                    return (
                        <div className={styles.tagBackgroundColor}>
                            <Select
                                mode="multiple"
                                style={{ width: '200px' }}
                                placeholder={this.trans('client')}
                                disabled={this.state.isTranslator}
                                value={record.client ? record.client.split(',') : []}
                                onChange={(e: any) => {
                                    const client = e.join(',');
                                    this.updateDocumentSetTableItem(record, { client });
                                }}>
                                {clientMap.map((item) => <Select.Option key={item.value}>{item.key}</Select.Option>)}
                            </Select>
                        </div>
                    );
                }
            },
            {
                title: () => {
                    return (
                        <div>
                            文案长度对比（单元格中的数字是渲染长度）
                            <Tooltip placement="topLeft" title="长度以中文长度为基准，单元格中的数字是以字体12px的渲染长度。短于中文长度的负方向显示，长于中文的正方向显示。长度大于中文50%以上的展示红色进度条，30%橘色，10%黄色，灰色为空">
                                <Icon type="question-circle" />
                            </Tooltip>
                        </div>
                    );
                },
                width: 216,
                dataIndex: 'docLens',
                key: 'docLens',
                render: (_id: IDocumentSet['id'], record: IDocumentSet) => {
                    const { docLens } = record;
                    const zhLength: number = docLens?.find((doc) => doc.languageCode === 'zh-CN')?.length || 0;
                    if (!zhLength) {
                        return '无中文，无法对比';
                    }

                    return (
                        <div style={{ width: '100%', paddingLeft: '100px' }}>
                            {
                                docLens?.map((document) => {
                                    const { languageCode, length } = document;
                                    const percent = Math.ceil(((length - zhLength) / zhLength) * 100);
                                    const style = {
                                        background: getLengthColor(percent),
                                        transform: `translateX(${Math.min(percent, 0)}px)`,
                                        width: `${Math.abs(percent)}px`,
                                        color: '#54585d',
                                    };

                                    let text = '';
                                    // 长度是以中文为基准，所以为绿色
                                    if (languageCode === 'zh-CN' && length) {
                                        style.background = 'green';
                                        style.width = '100px';
                                        style.transform = `translateX(-100px)`;
                                        style.color = '#fff';
                                    }
                                    if (!length) {
                                        style.background = '#f3f3f3';
                                        style.width = '100px';
                                        style.transform = `translateX(-100px)`;
                                        text = `${languageCode} 空`;
                                    } else {
                                        const calcPercent = percent > 0 ? `+${percent}%` : `${percent}%`;
                                        text = `${languageCode} ${ languageCode === 'zh-CN' ? '' : calcPercent }`;
                                    }
                                    return (
                                        <div key={languageCode} style={{
                                            whiteSpace: 'nowrap',
                                            fontSize: '12px',
                                            height: '14px',
                                            lineHeight: '14px',
                                            borderRadius: '4px',
                                            boxSizing: 'border-box',
                                            paddingLeft: '5px',
                                            textAlign: 'center',
                                            marginBottom: '2px',
                                            ...style
                                        }}>{text}</div>
                                    );
                                })
                            }
                        </div>
                    );
                },
            },
            {
                title: this.trans('controller'),
                dataIndex: 'lastModifyUser',
                key: 'lastModifyUser',
                render: (_id: IDocumentSet['id'], record: IDocumentSet) => (record.lastModifyUser ? record.lastModifyUser : record.creator),
            },
            {
                title: this.trans('updateTime'),
                dataIndex: 'updatedAt',
                key: 'updatedAt',
                render: (_id: IDocumentSet['id'], record: IDocumentSet) => (formatDate(record.lastUpdatedAt || record.createdAt, 'yyyy-MM-dd hh:mm:ss')),
            },
            {
                title: this.trans('actions'),
                dataIndex: 'id',
                key: 'operate',
                fixed: 'right',
                render: (_id: IDocumentSet['id'], record: IDocumentSet) => {
                    return (<div className={styles.operateBtnCtn}>
                        <Button
                            type="link"
                            onClick={() => this.showLanguageSelect(record)}>机翻</Button>
                        <Button
                            type="link"
                            disabled={this.state.isTranslator}
                            onClick={() => {
                                this.onEditDocumentSet(record);
                            }}>{this.trans('edit')}</Button>
                        {/* <Button
                            type="primary"
                            onClick={() => {
                                this.onEditDocumentSetGroup(record);
                            }}>分组</Button> */}
                        <Popconfirm
                            onConfirm={() => this.onDeleteDocumentSet(record)}
                            title={this.trans('confirmToDelete')}
                        >
                            <Button
                            type="link"
                            disabled={this.state.isTranslator}>{this.trans('delete')}</Button>
                        </Popconfirm>
                    </div>);
                },
            },
        ];
    }

    componentDidMount(): void {
        const { key }: { key?: string } = parse(window.location.search);

        if (key) {
            this.setState({
                search: key
            });
        }

        const columnSetOptions = this.documentSetColumns().map((item) => {
            let { title } = item;
            if (item.key === 'docLens') {
                title = '文案长度对比（单元格中的数字是渲染长度）';
            }
            return {
                label: String(title),
                value: String(item.key)
            };
        });
        let columnSetCheckedValues;
        try {
            const columnSet221014 = Local.getItem(LocalKey);
            if (columnSet221014) {
                columnSetCheckedValues = JSON.parse(columnSet221014);
            } else {
                columnSetCheckedValues = columnSetOptions.map((item) => item.value);
            }
        } catch (e) {
            columnSetCheckedValues = columnSetOptions.map((item) => item.value);
        }

        this.setState({
            columnSetOptions,
            columnSetCheckedValues
        });

        const { history, login } = this.props;
        this.unregisterHistoryListener = history.listen((location) => {
            const { groupList, dispatch, match } = this.props;
            const urlGroupId = location?.pathname?.match(/\d{1,}$/);
            const isDownExcel = /exportExcel=1/.test(location?.search);
            let groupId = urlGroupId ? parseInt(urlGroupId[0]) : 0;
            const sceneId = match?.params?.sceneId;

            const hasGroupId = groupList.some((el) => el.id === groupId);

            if (hasGroupId && isDownExcel) {
                this.exportRef?.current?.exportExcel();
                return;
            }
            if (groupId !== 0 && !hasGroupId) {
                groupId = 0;
            }
            const fetchList = [];

            if (groupList.length !== 0) {
                fetchList.push(dispatch({
                    type: 'group/getById',
                    payload: {
                        id: groupId,
                    },
                }));
            }

            fetchList.push(dispatch({
                type: 'documentSetList/get',
                payload: {
                    sceneId,
                    id: groupId,
                    offset: 0,
                    client: this.state.client,
                    search: this.state.search || key,
                    searchValue: this.state.searchValue,
                    searchRange: this.state.searchRange
                },
            }));

            Promise.all(fetchList).then(() => {
                const currentScene = login?.userInfo?.scenes?.find((item) => item.id === Number(sceneId));
                if (currentScene) {
                    this.setState({
                        isTranslator: login?.userInfo?.permission !== 300 && currentScene.scene_user.pms === 50,
                        isFiltering: false
                    });
                }
            }).catch((err) => {
                message.error(err.message || this.trans('dataFail'));
            });
        });
    }

    componentWillUnmount() {
        this.unregisterHistoryListener();
    }

    private unregisterHistoryListener = () => { };

    private onEditDocumentSet = (documentSet: IDocumentSet): void => {
        // 服务端存储的是imgDesc的string类型，这里需要转换成upload的数组字段给上传组件
        if (!documentSet.upload && documentSet.imgDesc) {
            documentSet.upload = [{
                hzUrl: documentSet.imgDesc,
            }];
        }
        const { currentEditDocumentSet } = this.state;
        if (currentEditDocumentSet) {
            return;
        }
        this.setState({
            currentEditDocumentSet: documentSet,
            isShowAddDocumentModel: true
        });
    }

    // private onEditDocumentSetGroup = (documentSet: IDocumentSet): void => {
    //     const { modalGroupDocumentSet } = this.state;
    //     if (modalGroupDocumentSet) {
    //         return;
    //     }

    //     this.setState({
    //         modalGroupDocumentSet: documentSet,
    //     });
    // }

    private onBulkDeleteOk = (): void => {
        if (this.isDeletingDocumentSet()) {
            return;
        }

        const { dispatch } = this.props;

        dispatch({
            type: 'documentSet/bulkDelete',
            payload: {
                ids: this.state.selectedRowKeys,
            },
        }).then(() => {
            this.setState({
                selectedRowKeys: [],
                selectedRows: [],
                showBatchDelete: false,
                isFiltering: false
            });
            return dispatch({
                type: 'documentSetList/get',
                payload: {
                    client: this.state.client,
                    search: this.state.search,
                    searchValue: this.state.searchValue,
                    searchRange: this.state.searchRange
                },
            });
        }).then(() => {
            message.success(this.trans('deleteSuccess'));
        }).catch((err) => {
            message.error(err.message || this.trans('failRetry'));
        });
    }

    private onDeleteDocumentSet = (record: IDocumentSet): void => {
        if (this.isDeletingDocumentSet()) {
            return;
        }

        const { dispatch } = this.props;

        dispatch({
            type: 'documentSet/delete',
            payload: {
                id: record.id,
            },
        }).then(() => {
            this.setState({ selectedRowKeys: [], isFiltering: false });
            return dispatch({
                type: 'documentSetList/get',
                payload: {
                    client: this.state.client,
                    search: this.state.search,
                    searchValue: this.state.searchValue,
                    searchRange: this.state.searchRange
                },
            });
        }).then(() => {
            message.success(this.trans('deleteSuccess'));
        }).catch((err) => {
            message.error(err.message || this.trans('failRetry'));
        });
    }

    private updateDocumentSet = (value: IDocumentSetCreate): void => {
        if (this.isUpdatingDocumentSet()) {
            return;
        }
        const { dispatch, match } = this.props;
        const sceneId = match?.params?.sceneId;
        const { currentEditDocumentSet, filterLanguageCode, saveChangedDocsForAWhile } = this.state;
        if (!currentEditDocumentSet) { // 编辑态已退出
            return;
        }

        const { groupId, id }: { groupId?: number; id?: number; } = currentEditDocumentSet || {};
        if (!groupId || !id) {
            const errMsg = this.trans('documentMsgError');
            message.error(errMsg);
            return;
        }

        const payload = {
            id,
            ...value,
            client: value.client.join(',')
        };

        if (value?.upload?.length) {
            payload.imgDesc = value.upload[value.upload.length - 1].hzUrl || '';
        } else {
            payload.imgDesc = '';
        }

        dispatch({
            type: 'documentSet/update',
            payload
        }).then(() => {
            message.success(this.trans('documentUpdateSuccess'));
            this.setState({
                isShowAddDocumentModel: false,
                currentEditDocumentSet: null,
            });
            if (this.state.isFiltering) {
                const { location } = this.props;
                const urlGroupId = location?.pathname?.match(/\d{1,}$/);
                const mapGroupId = (urlGroupId && parseInt(urlGroupId[0])) || 0;
                return dispatch({
                    type: 'documentSetList/filter',
                    payload: {
                        id: sceneId,
                        client: this.state.client,
                        filterLanguageCode,
                        groupId: mapGroupId,
                        isSaveTenMinutes: saveChangedDocsForAWhile
                    },
                });
            }
            return dispatch({
                type: 'documentSetList/get',
                payload: {
                    id: groupId,
                },
            });
        }).catch((err) => {
            message.error(err.message || this.trans('failRetry'));
        });
    }

    private updateDocumentSetTableItem = (record: IDocumentSet, value: IDocumentTableItemUpdate) => {
        if (!/^[0-9a-zA-Z_]+$/.test(value.key as string)) {
            message.error(this.trans('textLimit'));
            return Promise.reject();
        }
        const { dispatch } = this.props;
        const { id, sceneId } = record;

        if (value.client) {
            // 禁用字段校验
            const clientArr = value.client.split(',');
            const docs = record.documents;
            for (const cItem of clientArr) {
                const clientItem = parseInt(cItem);
                for (const dItem of docs) {
                    const docItemText = dItem.text;
                    const validMsg = docsValid(clientItem, docItemText);
                    if (validMsg) {
                        this.setState({ isFiltering: false });
                        return dispatch({
                            type: 'documentSetList/get',
                            payload: {
                                client: this.state.client,
                                search: this.state.search,
                                searchValue: this.state.searchValue,
                                searchRange: this.state.searchRange
                            },
                        }).then(() => {
                            message.error(`${this.trans('hasUselessWord')}${clientEnum[clientItem].client} ${validMsg}`);
                        }).catch((err) => {
                            message.error(err.message || this.trans('failRetry'));
                        });
                    }
                }
            }
        }

        return dispatch({
            type: 'documentSet/update',
            payload: {
                id,
                ...value
            },
        }).then(() => {
            if (!this.state.isFiltering) {
                return dispatch({
                    type: 'documentSetList/get',
                    payload: {
                        client: this.state.client,
                        search: this.state.search,
                        searchValue: this.state.searchValue,
                        searchRange: this.state.searchRange
                    },
                });
            }
            const { location } = this.props;
            const urlGroupId = location?.pathname?.match(/\d{1,}$/);
            const mapGroupId = (urlGroupId && parseInt(urlGroupId[0])) || 0;
            return dispatch({
                type: 'documentSetList/filter',
                payload: {
                    id: sceneId,
                    client: this.state.client,
                    filterLanguageCode: this.state.filterLanguageCode,
                    groupId: mapGroupId,
                    isSaveTenMinutes: this.state.saveChangedDocsForAWhile
                },
            });
        }).then(() => {
            message.success(this.trans('documentAddSuccess'));
        }).catch((err) => {
            message.error(err.message || this.trans('failRetry'));
            if (value.key) {
                return Promise.reject(err);
            }
        });
    }

    private udpateDocumentSetTableItemDocument = (record: IDocumentSet, value: { id: number, text: string, languageCode: string }): void => {
        const { dispatch } = this.props;
        // 禁用字段校验
        const clientArr = record.client.split(',');
        const docText = value.text;
        for (const cItem of clientArr) {
            const clientItem = parseInt(cItem);
            const validMsg = docsValid(clientItem, docText);
            if (validMsg) {
                message.error(`${this.trans('hasUselessWord')}${clientEnum[clientItem].client} ${validMsg}`);
                return;
            }
        }

        let type = 'document/update';
        let payload: {
            id: number,
            text: string,
            languageCode?: string
        } = {
            id: value.id,
            text: value.text || '',
        };
        if (!value.id) {
            type = 'document/create';
            payload = {
                id: record.id,
                text: value.text || '',
                languageCode: value.languageCode
            };
        }
        dispatch({
            type,
            payload,
        }).then(() => {
            if (type === 'document/update' && this.state.isFiltering) {
                const { location } = this.props;
                const urlGroupId = location?.pathname?.match(/\d{1,}$/);
                const mapGroupId = (urlGroupId && parseInt(urlGroupId[0])) || 0;
                return dispatch({
                    type: 'documentSetList/filter',
                    payload: {
                        id: record.sceneId,
                        client: this.state.client,
                        filterLanguageCode: this.state.filterLanguageCode,
                        groupId: mapGroupId,
                        isSaveTenMinutes: this.state.saveChangedDocsForAWhile
                    },
                });
            } else {
                this.setState({ isFiltering: false });
                return dispatch({
                    type: 'documentSetList/get',
                    payload: {
                        client: this.state.client,
                        search: this.state.search,
                        searchValue: this.state.searchValue,
                        searchRange: this.state.searchRange
                    },
                });
            }
        }).then(() => {
            message.success(this.trans('documentUpdateSuccess'));
        }).catch((err) => {
            message.error(err.message || this.trans('failRetry'));
        });
    }

    private onModalGroupMultOk = (values: any): void => {
        const { dispatch } = this.props;
        const { selectedRowKeys, modalGroupMultDocumentSet } = this.state;
        const { groupId }: { groupId?: number; } = modalGroupMultDocumentSet || {};

        if (!groupId) {
            message.error(this.trans('documentMsgError'));
            return;
        }
        dispatch({
            type: 'documentSetList/bulkUpdate',
            payload: {
                ids: selectedRowKeys.join(','),
                groupId: values.groupId,
            },
        }).then(() => {
            message.success(this.trans('divideSuccess'));
        }).catch((err) => {
            confirm({
                title: this.trans('divideFail'),
                content: err.message || this.trans('failRetry'),
            });
        }).finally(() => {
            this.setState({ selectedRowKeys: [], modalGroupMultDocumentSet: null, isFiltering: false });
            return dispatch({
                type: 'documentSetList/get',
                payload: {
                    client: this.state.client,
                    search: this.state.search,
                    searchValue: this.state.searchValue,
                    searchRange: this.state.searchRange
                },
            });
        });
    }

    private onModalGroupOk = (values: any): void => {
        if (this.isUpdatingDocumentSet()) {
            return;
        }
        const { dispatch } = this.props;
        const { modalGroupDocumentSet } = this.state;
        if (!modalGroupDocumentSet) { // 编辑态已退出
            return;
        }

        const { groupId, id, }: { groupId?: number; id?: number; } = modalGroupDocumentSet || {};

        if (!groupId || !id) {
            message.error(this.trans('documentMsgError'));
            return;
        }

        dispatch({
            type: 'documentSet/update',
            payload: {
                id,
                groupId: values.groupId,
            },
        }).then(() => {
            this.setState({ selectedRowKeys: [] });
            if (!this.state.isFiltering) {
                return dispatch({
                    type: 'documentSetList/get',
                    payload: {
                        client: this.state.client,
                        search: this.state.search,
                        searchValue: this.state.searchValue,
                        searchRange: this.state.searchRange
                    },
                });
            }
            return true;
        }).then(() => {
            message.success(this.trans('documentUpdateSuccess'));
            this.setState({
                modalGroupDocumentSet: null,
            });
        }).catch((err) => {
            message.error(err.message || this.trans('failRetry'));
        });
    }

    private renderGroupSelect = (): JSX.Element | null => {
        const { groupList, scene, group, history, location } = this.props;
        const urlGroupId = location?.pathname?.match(/\d{1,}$/);
        const groupId = group?.id || (urlGroupId && parseInt(urlGroupId[0])) || 0;

        if (isEmpty(groupList)) {
            return null;
        }
        const hasGroupInList = groupList.some((el) => el.id === groupId);

        return (
            <Select
                showSearch
                style={{
                    width: 250,
                    marginRight: 20,
                }}
                value={hasGroupInList ? groupId : 0}
                filterOption={(input, option) => {
                    if (!option) return false;
                    const children = option!.props!.children;
                    return (Array.isArray(children)
                        ? children.join('')
                        : (children as string)).toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0;
                }}
                onChange={(value: number) => {
                    this.setState({
                        selectedRowKeys: [],
                        client: 0,
                        search: '',
                        sendPopoGroupIds: [],
                    });
                    if (!scene?.id) return;
                    history.push(`/${scene.id}/document/${value || 0}`);
                }}>
                {[
                    { id: 0, name: this.trans('all'), key: '', sceneId: scene ? scene.id : '' },
                    ...groupList,
                ].map((item: any) => (
                    <Select.Option key={item.id} value={item.id}>
                        {item.name}{item.key ? `(${item.key})` : ''}
                    </Select.Option>
                ))}
            </Select>
        );
    }

    private renderLanguageCodeSelect = (): JSX.Element | null => {
        const { scene, languageMap } = this.props;
        const { filterLanguageCode } = this.state;

        const codeList = [{ key: '全部(all)', value: 'all' }].concat(scene?.languages?.map((code) => {
            return {
                key: `${languageMap[code]}(${code})`,
                value: code
            };
        }) || []);
        return (
            <Select
                style={{
                    width: 150,
                    marginRight: 20,
                }}
                value={filterLanguageCode}
                onChange={(value: string) => {
                    this.setState({ filterLanguageCode: value });
                }}>
                {codeList.map((item: { key: string, value: string }) => (
                    <Select.Option key={item.key} value={item.value}>
                        {item.key}
                    </Select.Option>
                ))}
            </Select>
        );
    }

    private renderClientSelect = (): JSX.Element | null => {
        const { client } = this.state;

        const clientList = [{ key: this.trans('all'), value: 0 }, ...clientMap];

        return (
            <Select
                style={{
                    width: 150,
                    marginRight: 20,
                }}
                value={client}
                onChange={(value: number) => {
                    this.setState({ client: value });
                }}>
                {clientList.map((item: { key: string, value: number }) => (
                    <Select.Option key={item.key} value={item.value}>
                        {item.key}
                    </Select.Option>
                ))}
            </Select>
        );
    }

    private renderSendSelect = (): JSX.Element | null => {
        const { sendPopoGroupIds } = this.state;
        const { groupList, group, location } = this.props;
        const urlGroupId = location?.pathname?.match(/\d{1,}$/);
        const groupId = group?.id || (urlGroupId && parseInt(urlGroupId[0])) || 0;
        if (isEmpty(groupList)) {
            return null;
        }
        const hasGroupInList = groupList.some((el) => el.id === groupId);
        const selectValues = Array.from(new Set(groupId ? [groupId, ...sendPopoGroupIds] : [...sendPopoGroupIds]));
        return (
            <>
                <span>{this.trans('pushModule')}: </span>
                <Select
                    showSearch
                    style={{
                        width: 250,
                        marginRight: 20,
                    }}
                    mode="multiple"
                    value={selectValues}
                    disabled={this.state.isTranslator}
                    filterOption={(input: string, option) => {
                        if (!option) return false;
                        const children = option!.props!.children;
                        return (Array.isArray(children)
                            ? children.join('')
                            : (children as string)).toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0;
                    }}
                    onChange={(value: number[]) => {
                        let groupIds = [];
                        if (groupId && hasGroupInList) {
                            groupIds.push(groupId);
                        }
                        groupIds = [...groupIds, ...value];
                        this.setState({
                            sendPopoGroupIds: Array.from(new Set(groupIds))
                        });
                    }}>
                    {groupList.map((item: any) => (
                        <Select.Option key={item.id} value={item.id}>
                            {item.name}{item.key ? `(${item.key})` : ''}
                        </Select.Option>
                    ))}
                </Select>
                {/* @desc: 左边选择要推送的模块后，点击推送消息，会将对应模块下未翻译的文案推送给翻译 */}
                {/* @step: pushNotice=1 */}
                {/* @direction: right */}
                <Button
                    type="primary"
                    ghost
                    disabled={this.state.isTranslator || !selectValues?.length}
                    onClick={() => {
                        this.postPoPoMsg(selectValues);
                    }}>{this.trans('sendMsg')}</Button>
            </>
        );
    }

    // private filterUntranslatedDocs = () => {
    //     const { dispatch, match, location, group } = this.props;
    //     const { client, filterLanguageCode, saveChangedDocsForAWhile } = this.state;
    //     const urlGroupId = location?.pathname?.match(/\d{1,}$/);
    //     const groupId = group?.id || (urlGroupId && parseInt(urlGroupId[0])) || 0;
    //     const sceneId = match?.params?.sceneId;
    //     dispatch({
    //         type: 'documentSetList/filter',
    //         payload: {
    //             offset: 0,
    //             id: sceneId,
    //             client,
    //             filterLanguageCode,
    //             groupId,
    //             isSaveTenMinutes: saveChangedDocsForAWhile
    //         },
    //     }).then(() => {
    //         this.setState({
    //             isFiltering: true
    //         });
    //     }).catch((err) => {
    //         message.error(err.message || this.trans('getGroupFail'));
    //     });
    // };

    private onSearch = async () => {
        const { dispatch } = this.props;
        const { client, search, searchValue, searchRange } = this.state;
        this.setState({ selectedRowKeys: [], isFiltering: false });
        dispatch({
            type: 'documentSetList/get',
            payload: {
                client,
                search,
                searchValue,
                searchRange,
                offset: 0,
            },
        }).catch((err) => {
            message.error(err.message || this.trans('getGroupFail'));
        });
    }

    private onFilterAllUnMatchTexts = () => {
        const { dispatch } = this.props;
        this.setState({ selectedRowKeys: [], isFiltering: false });
        dispatch({
            type: 'documentSetList/get',
            payload: {
                filterUnMatch: true,
                offset: 0,
            },
        }).catch((err) => {
            message.error(err.message || this.trans('getGroupFail'));
        });
    }

    private onTranslate = () => {
        const { dispatch } = this.props;
        const { needTranslateRecord, selectTranslateCode } = this.state;
        if (!selectTranslateCode?.length) {
            return message.error('未选择需要翻译的语种！');
        }
        const transText = (needTranslateRecord as any)?.documents?.find((item: IDocument) => {
            return item.languageCode === 'zh-CN';
        })?.text || '';
        if (!transText) {
            return message.error('请先填充中文文案');
        }
        const needTransCodeList = selectTranslateCode.map((item: string) => item?.split('-')[0]);
        Promise.all(needTransCodeList.map(async (item: string) => {
            const result = await dispatch({
                type: 'documentSet/translate',
                payload: {
                    translateWord: transText,
                    toLang: item
                },
            });
            return result;
        })).then((res) => {
            const translateArr = selectTranslateCode.map((item: string) => ({
                languageCode: item,
                text: res.find((option: {[key: string]: string }) => {
                    return item?.split('-')[0] === option.toLang;
                })?.result || '',
            }));
            const newSet = {
                ...needTranslateRecord as any,
                documents: [...translateArr]
            };

            dispatch({
                type: 'documentSet/updateDocument',
                payload: {
                    id: newSet.sceneId,
                    dataInside: { documentsetlist: JSON.stringify([newSet]) }
                },
            }).then(() => {
                this.setState({ needTranslateRecord: null, selectTranslateCode: [], isShowTranslateSelectModel: false });
                return dispatch({
                    type: 'documentSetList/get',
                    payload: {
                        client: this.state.client,
                        search: this.state.search,
                        searchValue: this.state.searchValue,
                        searchRange: this.state.searchRange
                    },
                });
            }).catch((error) => message.error(error.message));
        });
    }

    private showLanguageSelect = (record: IDocumentSet) => {
        if (record.type === 'image') {
            return message.error('图片类型不支持机翻');
        }
        const { languageMap } = this.props;
        const newRecord = {
            ...record,
            documents: record.documents.map((item: IDocument) => ({
                ...item,
                CNtext: languageMap[item.languageCode]
            }))
        };
        this.setState({
            selectTranslateCode: [],
            needTranslateRecord: newRecord,
            isShowTranslateSelectModel: true
        });
    };

    private showBatchLanguageSelect = () => {
        this.setState({
            isShowBatchTranslateSelectModel: true
        });
    };

    private onSelectTranslateCode = (val: any) => {
        this.setState({
            selectTranslateCode: val,
        });
    };

    private onBatchTranslate = () => {
        const { languageMap, dispatch } = this.props;

        const { selectedRows, batchTranslateLanguage } = this.state;

        const newRecords = selectedRows.filter((row: IDocumentSet) => {
            const needTransDoc = row.documents.find((item: IDocument) => item.languageCode === batchTranslateLanguage);
            const hasChineseText = row.documents.find((item: IDocument) => item.languageCode === 'zh-CN' && item.text);
            return row.type !== 'image' && hasChineseText && (needTransDoc?.text === '' || !needTransDoc);
        }).map((item: IDocumentSet) => ({
            ...item,
            documents: item.documents.map((item: IDocument) => ({
                ...item,
                CNtext: languageMap[item.languageCode]
            }))
        }));
        this.setState({ isShowBatchTranslateSelectModel: false });

        if (!newRecords.length) {
            return message.error('未找到需要翻译的文案');
        }
        if (!batchTranslateLanguage) {
            return message.error('未选择需要翻译的语种！');
        }

        const needTransLng = batchTranslateLanguage?.split('-')[0];
        const transHandler = (needTransArr: IDocumentSet[]) => {
            const currentTransRecords = needTransArr.slice(0, 5);
            const restArr = needTransArr.slice(5);

            Promise.all(currentTransRecords.map(async (item: any) => {
                const transText = (item as any)?.documents?.find((item: IDocument) => {
                    return item.languageCode === 'zh-CN';
                })?.text || '';
                const result = await dispatch({
                    type: 'documentSet/translate',
                    payload: {
                        translateWord: transText,
                        toLang: needTransLng,
                        id: item.id
                    },
                });
                return result;
            })).then((res: any) => {
                const newSets = currentTransRecords.map((record) => ({
                    ...record,
                    documents: [{
                        languageCode: batchTranslateLanguage,
                        text: res.find((option: {[key: string]: string | number }) => {
                            return record.id === option.id;
                        })?.result || '',
                    }]
                }));
                dispatch({
                    type: 'documentSet/updateDocument',
                    payload: {
                        id: newSets[0].sceneId,
                        dataInside: { documentsetlist: JSON.stringify(newSets) }
                    },
                }).then(() => {
                    if (!restArr.length) {
                        this.setState({ selectedRows: [], selectedRowKeys: [], batchTranslateLanguage: '', isShowBatchTranslateSelectModel: false });
                        return dispatch({
                            type: 'documentSetList/get',
                            payload: {
                                client: this.state.client,
                                search: this.state.search,
                                searchValue: this.state.searchValue,
                                searchRange: this.state.searchRange
                            },
                        });
                    } else {
                        transHandler(restArr);
                    }
                }).catch((error) => message.error(error.message));
            });
        };

        transHandler(newRecords);
    }

    private onReset = () => {
        const { dispatch } = this.props;
        this.setState({
            selectedRowKeys: [],
            client: 0,
            search: '',
            searchValue: '',
            searchRange: [],
            isFiltering: false,
        });
        dispatch({
            type: 'documentSetList/get',
            payload: {
                offset: 0,
            },
        }).catch((err) => {
            message.error(err.message || this.trans('getGroupFail'));
        });
    }

    private postPoPoMsg = (groupIds: number[]): void => {
        if (this.isSendNeedTranslatePopoMsg()) {
            return;
        }

        const { dispatch, scene } = this.props;
        const [languageCodeList = [], languageMap] = scene?.scene?.languages?.reduce((acc: any, language: any) => {
            const [codes, map] = acc;
            codes.push(language.code);
            map[language.code] = language.desc;
            return acc;
        }, [[], { all: '全量' }]) || [];
        const origin = window!.location!.origin;
        const sendSuccess = this.trans('sendSuccess');
        let receiver: Record<string, string>;
        try {
            receiver = JSON.parse(get(scene, 'receiver', '{}') || '{}');
        } catch (err) {
            receiver = {};
        }
        confirm({
            title: this.trans('remind'),
            content: (
                <>
                    <div>{this.trans('sendTo')}</div>
                    {Object.keys(receiver).length > 0 ? (
                        Object.keys(receiver).map((code) => (
                            <div key={code}>{languageMap[code]}: {receiver[code]}</div>
                        ))
                    ) : (
                        <span>{this.trans('detailPerson')}</span>
                    )}
                </>
            ),
            onOk() {
                dispatch({
                    type: 'documentSet/sendNeedTranslatePopoMsg',
                    payload: {
                        sceneId: scene?.id || '',
                        groupIds: groupIds || [],
                        languageCodeList,
                        origin
                    },
                }).then(() => {
                    message.success(sendSuccess);
                }).catch((err) => {
                    message.error(err.message);
                });
            }
        });
    }

    private createDocumentSet = (values: any) => {
        const { dispatch } = this.props;

        if (this.isCreatingDocumentSet()) {
            return;
        }

        const payload = {
            ...values,
            client: values.client.join(','),
        };

        const upload = values?.upload;
        if (upload?.length) {
            payload.imgDesc = upload[upload.length - 1].hzUrl || '';
        } else {
            payload.imgDesc = '';
        }

        if (values.type === 'image') {
            payload.documents = payload.documents.map((item: any) => {
                return {
                    id: item.id,
                    languageCode: item.languageCode,
                    text: item.image.length === 2 ? item.image[1].hzUrl : ''
                };
            });
        }

        dispatch({
            type: 'documentSet/create',
            payload,
        }).then(() => {
            message.success(this.trans('createSuccess'));
            this.setState({
                isShowAddDocumentModel: false,
            });
        }).then(() => {
            this.setState({ selectedRowKeys: [], isFiltering: false });
            return dispatch({
                type: 'documentSetList/get',
                payload: {
                    client: this.state.client,
                    search: this.state.search,
                    searchValue: this.state.searchValue,
                    searchRange: this.state.searchRange
                }
            });
        }).catch((err) => {
            message.error(err.message || this.trans('dataFail'));
        });
    }

    private addMultiText = async (keyValueArr: IGroupKeyValue[], client: string, groupId: number) => {
        const { dispatch } = this.props;

        const payload = keyValueArr.map((item) => ({
            client,
            groupId,
            desc: item.desc,
            key: item.key,
            from: item.from,
            type: 'text',
        }));
        try {
            await dispatch({
                type: 'documentSet/create',
                payload,
            });
            message.success(this.trans('createSuccess'));
            this.setState({
                isShowAddBatchDocumentModel: false,
                isCreatingDocument: false,
            });
            this.setState({ selectedRowKeys: [], isFiltering: false });
            return dispatch({
                type: 'documentSetList/get',
                payload: {
                    client: this.state.client,
                    search: this.state.search,
                    searchValue: this.state.searchValue,
                    searchRange: this.state.searchRange
                }
            });
        } catch (error) {
            message.error(error.message || this.trans('dataFail'));
            this.setState({
                isCreatingDocument: false,
            });
        }
    }

    private createMultiDocumentSet = (values: any) => {
        const { isCreatingDocument } = this.state;
        if (isCreatingDocument) {
            return;
        }
        this.setState({
            isCreatingDocument: true,
        });
        const keyValueArr = values.keys.map((item: IGroupKeyValue) => {
            return {
                key: values[`key${item.key}`],
                desc: values[`desc${item.desc}`],
                from: values[`from${item.from}`] || '',
                val: item.key
            };
        });
        const client = values.client.join(',');
        this.addMultiText(keyValueArr, client, values.groupId);
    }

    private renderDocumentSetTable = () => {
        const { isFiltering, columnSetCheckedValues } = this.state;
        const { documentSetList, documentSetListPagination, dispatch, scene } = this.props;
        const {
            total,
            limit,
            offset,
        } = documentSetListPagination;
        const onShowSizeChange = (current: any, pageSize: any) => {
            this.setState({ selectedRowKeys: [], isFiltering: false });
            dispatch({
                type: 'documentSetList/get',
                payload: {
                    offset: (current - 1) * pageSize,
                    limit: pageSize,
                    client: this.state.client,
                    search: this.state.search,
                    searchValue: this.state.searchValue,
                    searchRange: this.state.searchRange
                },
            }).catch((err) => {
                message.error(err.message || this.trans('dataFail'));
            });
        };

        const columns = [...this.documentSetColumns()].map((item) => {
            return {
                className: (columnSetCheckedValues as string[]).includes(String(item.key)) ? '' : styles.hidden,
                ...item,
            };
        });

        if (scene) {
            const languageList = scene.scene.languages;
            languageList.forEach((language: any) => {
                columns.splice(7, 0, {
                    title: this.trans(language.code.split('-').join('_')) || language.desc,
                    dataIndex: language.code,
                    key: language.code,
                    className: '',
                    render: (_text, record) => {
                        const document = record.documents.filter((doc) => {
                            return doc.languageCode === language.code;
                        })[0] || {};
                        if (record.type === 'image') {
                            return (
                                <ImageInput
                                    style={{ width: '200px' }}
                                    value={document.text}
                                    onPressEnter={(value) => {
                                        this.udpateDocumentSetTableItemDocument(record, { id: document.id, text: value, languageCode: language.code });
                                    }}
                                />
                            );
                        } else {
                            return (
                                <TextInput
                                    style={{ width: '200px', direction: language.code === 'ar-EG' ? 'rtl' : 'ltr' }}
                                    highLightValue={this.state.searchValue}
                                    disabled={false}
                                    client={record.client}
                                    value={document.text}
                                    onPressEnter={(value) => {
                                        this.udpateDocumentSetTableItemDocument(record, { id: document.id, text: value, languageCode: language.code });
                                    }}
                                />
                            );
                        }
                    }
                });
            });
        }

        return (
            <Table
                rowKey="id"
                size="small"
                pagination={{
                    hideOnSinglePage: true,
                    total,
                    showTotal: (total) => `共 ${total}`,
                    pageSize: limit,
                    defaultCurrent: 1,
                    current: (offset + limit) / limit,
                    onChange: (page) => {
                        this.setState({ selectedRowKeys: [] });
                        if (isFiltering) {
                            const { dispatch, match, location, group } = this.props;
                            const { filterLanguageCode, saveChangedDocsForAWhile, client } = this.state;
                            const urlGroupId = location?.pathname?.match(/\d{1,}$/);
                            const groupId = group?.id || (urlGroupId && parseInt(urlGroupId[0])) || 0;
                            const sceneId = match?.params?.sceneId;
                            return dispatch({
                                type: 'documentSetList/filter',
                                payload: {
                                    offset: (page - 1) * limit,
                                    client,
                                    id: sceneId,
                                    filterLanguageCode,
                                    groupId,
                                    isSaveTenMinutes: saveChangedDocsForAWhile
                                },
                            }).catch((err) => {
                                message.error(err.message || this.trans('getGroupFail'));
                            });
                        }
                        return dispatch({
                            type: 'documentSetList/get',
                            payload: {
                                offset: (page - 1) * limit,
                                client: this.state.client,
                                search: this.state.search,
                                searchValue: this.state.searchValue,
                                searchRange: this.state.searchRange
                            },
                        }).catch((err) => {
                            message.error(err.message || this.trans('dataFail'));
                        });
                    },
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100', '500'],
                    onShowSizeChange
                }}
                dataSource={documentSetList}
                columns={columns}
                scroll={{ x: true }}
                rowSelection={{
                    selectedRowKeys: this.state.selectedRowKeys,
                    onChange: (selectedRowKeys: React.Key[], selectedRows: IDocumentSet[]) => {
                        this.setState({
                            selectedRowKeys,
                            selectedRows
                        });
                    },
                }}
            />
        );
    }

    private keyUniqueCheck = (value: string): Promise<boolean> => {
        const { dispatch, group } = this.props;

        return dispatch({
            type: 'documentSet/checkKey',
            payload: {
                key: value,
                groupId: group ? group.id : undefined
            },
        });
    }

    private keyGenerator = (): Promise<string> => {
        const { dispatch } = this.props;
        return dispatch({
            type: 'documentSet/generateKey',
        });
    }

    private getGroupId = (): number => {
        const { groupList, group, location } = this.props;
        const urlGroupId = location?.pathname?.match(/\d{1,}$/);
        const groupId = group?.id || (urlGroupId && parseInt(urlGroupId[0])) || (this.state?.currentEditDocumentSet as any)?.groupId || 0;

        if (isEmpty(groupList)) {
            return 0;
        }
        return groupId;
    }

    @memoizeFn(
        ['props', 'loading', 'effects', 'group/getById'],
        ['props', 'loading', 'effects', 'documentSetList/get'],
        ['props', 'loading', 'effects', 'documentSetList/filter'],
        ['props', 'loading', 'effects', 'documentSet/create'],
        ['props', 'loading', 'effects', 'documentSet/update'],
        ['props', 'loading', 'effects', 'documentSet/delete'],
        ['props', 'loading', 'effects', 'documentSet/bulkDelete'],
        ['props', 'loading', 'effects', 'documentSet/sendNeedTranslatePopoMsg'],
        ['props', 'loading', 'effects', 'documentSet/translate'],
        ['props', 'loading', 'effects', 'documentSet/updateDocument']
    )
    isLoading(...args: DvaLoadingEffect[]) {
        return args.includes(true);
    }

    @memoizeFn(
        ['props', 'loading', 'effects', 'documentSet/sendNeedTranslatePopoMsg']
    )
    isSendNeedTranslatePopoMsg(loading?: boolean) {
        return loading === true;
    }

    @memoizeFn(
        ['props', 'loading', 'effects', 'documentSet/create']
    )
    isCreatingDocumentSet(loading?: boolean) {
        return loading === true;
    }

    @memoizeFn(
        ['props', 'loading', 'effects', 'documentSet/delete'],
        ['props', 'loading', 'effects', 'documentSet/bulkDelete']
    )
    isDeletingDocumentSet(loading?: boolean) {
        return loading === true;
    }
    @memoizeFn(
        ['props', 'loading', 'effects', 'documentSet/update']
    )
    isUpdatingDocumentSet(loading?: boolean) {
        return loading === true;
    }

    @memoizeFn('props.documentSetList')
    getAllDocumentSetIdList(list?: IDocumentSet[]) {
        return (list || []).map((documentSet: IDocumentSet) => (documentSet.id));
    }

    render() {
        const { group, groupList, scene, language, languageMap, documentSetList, i18n } = this.props;
        const {
            isShowAddDocumentModel, modalGroupDocumentSet, selectedRowKeys, currentEditDocumentSet,
            modalGroupMultDocumentSet, isShowAddBatchDocumentModel, waitToFillArr, isCreatingDocument,
            showBatchDelete, selectedRows, isTranslator, filterLanguageCode, isShowTranslateSelectModel,
            needTranslateRecord, columnSetOptions, columnSetCheckedValues, isShowBatchTranslateSelectModel
        } = this.state;
        const { RangePicker } = DatePicker;

        const isEdit = currentEditDocumentSet;

        // const setValue = debounce((e) => {
        //     e.persist()
        //     this.setState({
        //         searchValue: e && e?.currentTarget?.value
        //     });
        // }, 300)
        if (groupList.length === 0) {
            return null;
        }
        return (
            <div className={styles.ctn}>
                <Spin spinning={this.isLoading()}>
                    <div className={styles.header}>
                        <div>
                            <span>{this.trans('moduleSelect')}: </span>
                            {this.renderGroupSelect()}
                            <Button
                                type="primary"
                                disabled={!groupList?.length || this.state.isTranslator}
                                onClick={() => {
                                    this.setState({
                                        isShowAddDocumentModel: true,
                                    });
                                }}>{this.trans('addSingleText')}</Button>
                            <Button
                                type="primary"
                                disabled={!groupList?.length || this.state.isTranslator}
                                onClick={() => {
                                    this.setState({
                                        isShowAddBatchDocumentModel: true,
                                    });
                                }}>{this.trans('addMultiText')}</Button>
                        </div>
                        <div className={styles.topRightContainer}>
                            <Button
                                type="primary"
                                disabled={!selectedRowKeys.length}
                                onClick={() => {
                                    this.showBatchLanguageSelect();
                                }}>批量翻译</Button>
                            {
                                !isTranslator ? (
                                    <ExportExcel
                                        i18n={i18n}
                                        ref={this.exportRef}
                                        sceneId={scene ? scene.id : undefined}
                                        groupId={(group && group.id) ? group.id : undefined}
                                        selectedRows={this.state.selectedRows}
                                        groupList={groupList}
                                        languages={scene ? scene.scene.languages : []}
                                    />
                                ) : null
                            }
                            {
                                !isTranslator ? (
                                    <ImportExcel
                                        i18n={i18n}
                                        sceneId={scene ? scene.id : undefined}
                                        onSuccess={this.onReset}
                                        languages={scene ? scene.scene.languages : []}
                                    />
                                ) : null
                            }
                            <Button
                                type="primary"
                                disabled={!selectedRowKeys.length || this.state.isTranslator}
                                onClick={() => {
                                    this.setState({
                                        modalGroupMultDocumentSet: documentSetList[0],
                                    });
                                }}>{this.trans('batchSeperate')}</Button>
                            <Button
                                type="primary"
                                disabled={!selectedRowKeys.length || this.state.isTranslator}
                                onClick={() => {
                                    // const selectedRows = documentSetList.filter(el => (selectedRowKeys as React.Key[]).includes(el.id))
                                    this.setState({
                                        showBatchDelete: true,
                                        // selectedRows,
                                    });
                                }}>{this.trans('batchDelete')}</Button>
                        </div>
                    </div>
                    {
                        scene?.receiver ? (
                            <div className={styles.header}>
                                <div>
                                    {this.renderSendSelect()}
                                </div>
                            </div>
                        ) : null
                    }
                    <div className={styles.header}>
                        <div>
                            <span>{this.trans('client')}: </span>
                            {this.renderClientSelect()}
                            <span>{this.trans('keyword')}/key: </span>
                            <Input
                                style={{ marginRight: '10px', width: '150px' }}
                                value={this.state.search}
                                placeholder={this.trans('enterkeyword')}
                                onPressEnter={() => { this.onSearch(); }}
                                onChange={(e) => { this.setState({ search: e.currentTarget.value }); }} />
                            <span>{this.trans('textValue')}</span>
                            <Input
                                style={{ marginRight: '10px', width: '150px' }}
                                value={this.state.searchValue}
                                placeholder={this.trans('enterText')}
                                onPressEnter={() => { this.onSearch(); }}
                                onChange={(e) => {
                                    this.setState({
                                        searchValue: e?.currentTarget?.value
                                    });
                                }} />
                            <span>{this.trans('timeRange')}</span>
                            <RangePicker
                                style={{ marginRight: '10px' }}
                                disabledDate={(current) => !!(current && current > moment().endOf('day'))}
                                ranges={{
                                    Today: [moment('00:00:00', 'HH:mm:ss'), moment()],
                                }}
                                showTime={{
                                    defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')],
                                }}
                                format="YYYY-MM-DD HH:mm:ss"
                                onChange={(_, dateStrings) => {
                                    this.setState({
                                        searchRange: dateStrings
                                    });
                                }}
                                />
                            <Button
                                type="primary"
                                onClick={() => { this.onSearch(); }}>{this.trans('search')}</Button>
                        </div>
                    </div>
                    <div style={{ marginBottom: 20 }}>
                        <Divider orientation="left">高级操作</Divider>
                        {
                            !isTranslator && (
                                <>
                                    {this.renderLanguageCodeSelect()}
                                    {/* @desc: 左边选择对应的语种，点击按钮导出该语种未翻译的文案 */}
                                    {/* @direction: right */}
                                    <span style={{ marginRight: 40 }}>
                                        <ExportExcel
                                            i18n={i18n}
                                            btnText="导出所选语种未翻译文案"
                                            filterLanguageCode={filterLanguageCode}
                                            isExportUnTransDocs={true}
                                            sceneId={scene ? scene.id : undefined}
                                            groupId={(group && group.id) ? group.id : undefined}
                                            selectedRows={this.state.selectedRows}
                                            groupList={groupList}
                                            languages={scene ? scene.scene.languages : []}
                                        />
                                    </span>
                                </>
                            )
                        }

                        <span style={{ marginRight: 40 }}>
                            <Button
                                type="primary"
                                onClick={this.onFilterAllUnMatchTexts}>筛选所有变量未匹配文案</Button>
                        </span>

                        {/* @desc: 针对含有变量占位符的文案，若其他语种写法有误，会导致变量替换失败，点击按钮即可导出所有不匹配的文案。错误示例：【中文】你好：%1【英文】hello: 1% */}
                        {/* @direction: right */}
                        <span style={{ marginRight: 40 }}>
                            <ExportExcel
                                i18n={i18n}
                                btnText="导出变量未匹配文案"
                                isExportUnMatchVariable={true}
                                sceneId={scene ? scene.id : undefined}
                                groupId={(group && group.id) ? group.id : undefined}
                                groupList={groupList}
                                languages={scene ? scene.scene.languages : []}
                            />
                        </span>
                        {/* @desc: 请先勾选需要查找的列表项，针对相同描述已翻译过的文案，提供直接导入文案的方法 */}
                        {/* @direction: right */}
                        <span style={{ marginRight: 40 }}>
                            <BatchMatchBtn
                                i18n={i18n}
                                disabled={!selectedRowKeys.length}
                                selectedRowKeys={selectedRowKeys}
                                documentSetList={documentSetList}
                                languageMap={languageMap}
                                sceneLanguage={scene!.languages}
                                onClose={() => {
                                    this.onSearch();
                                }}
                            />
                        </span>
                        <span>
                            <ColumnSet
                                options={columnSetOptions}
                                defaultValue={columnSetCheckedValues}
                                onChange={(values) => {
                                    Local.setItem(LocalKey, JSON.stringify(values));
                                    this.setState({
                                        columnSetCheckedValues: values,
                                    });
                                }}
                            />
                        </span>
                    </div>
                    {/* <div className={styles.header}>
                        <div>
                            <span>{this.trans('docLang')}</span>
                            {this.renderLanguageCodeSelect()}
                            <Checkbox onChange={(val) => {
                                this.setState({
                                    saveChangedDocsForAWhile: val.target.checked
                                });
                            }}>
                                是否保留10分钟内已填充的文案
                            </Checkbox>
                            <Button
                                type="primary"
                                onClick={() => { this.filterUntranslatedDocs(); }}>{this.trans('unTransDocs')}</Button>
                        </div>
                    </div> */}
                    {this.renderDocumentSetTable()}
                    <DocumentSetFormModal
                        i18n={i18n}
                        isNew={!isEdit}
                        groupId={this.getGroupId()}
                        groupList={groupList}
                        keyGenerator={this.keyGenerator}
                        languageMap={languageMap}
                        sceneLanguage={scene!.languages}
                        languageList={language}
                        visible={isShowAddDocumentModel}
                        dataSource={currentEditDocumentSet || undefined}
                        keyUniqueCheck={!isEdit && this.keyUniqueCheck}
                        onSubmit={isEdit ? this.updateDocumentSet : this.createDocumentSet}
                        onCancel={() => {
                            this.setState({
                                isShowAddDocumentModel: false,
                                currentEditDocumentSet: null
                            });
                        }}
                    />
                    <DocumentSetFormModal
                        isBatch
                        i18n={i18n}
                        groupId={this.getGroupId()}
                        groupList={groupList}
                        isCreatingDocument={isCreatingDocument}
                        waitToFillArr={waitToFillArr}
                        keyUniqueCheck={this.keyUniqueCheck}
                        keyGenerator={this.keyGenerator}
                        languageMap={languageMap}
                        sceneLanguage={scene!.languages}
                        languageList={language}
                        visible={isShowAddBatchDocumentModel}
                        onSubmit={this.createMultiDocumentSet}
                        onCancel={() => {
                            this.setState({
                                isShowAddBatchDocumentModel: false,
                            });
                        }}
                    />
                    <DocumentSetGroupModal
                        i18n={i18n}
                        groupList={groupList}
                        dataSource={modalGroupDocumentSet || undefined}
                        onSubmit={this.onModalGroupOk}
                        onCancel={() => {
                            this.setState({
                                modalGroupDocumentSet: null,
                            });
                        }}
                    />
                    <DocumentSetGroupModal
                        i18n={i18n}
                        groupList={groupList}
                        dataSource={modalGroupMultDocumentSet || undefined}
                        onSubmit={this.onModalGroupMultOk}
                        onCancel={() => {
                            this.setState({
                                modalGroupMultDocumentSet: null,
                            });
                        }}
                    />
                    <Modal
                        title={this.trans('batchDelete')}
                        visible={showBatchDelete}
                        onCancel={() => { this.setState({ showBatchDelete: false, }); }}
                        onOk={this.onBulkDeleteOk}
                    >
                        <div className={styles.batchDelete}>
                            <h3>{this.trans('deleteTextConfirm')}:</h3>
                            <div className={styles.batchDeleteTitle}>
                                <p>Key</p>
                                <p>{this.trans('description')}</p>
                            </div>
                            <div className={styles.batchDeleteWrap}>
                                {
                                    selectedRows.map((item: IDocumentSet) =>
                                    (
                                        <div key={item.id} className={styles.batchDeleteContent}>
                                            <p>{item.key}</p>
                                            <p>{item.desc}</p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </Modal>
                    <Modal
                        title="机翻语种选择"
                        destroyOnClose
                        visible={isShowTranslateSelectModel}
                        onCancel={() => {
                            this.setState({
                                isShowTranslateSelectModel: false, needTranslateRecord: null, selectTranslateCode: []
                            });
                        }}
                        onOk={this.onTranslate}
                    >
                        <div className={styles.batchDelete}>
                            <h3>待翻译中文文本</h3>
                            <p>
                                {(needTranslateRecord as any)?.documents?.find((item: IDocument) => item.languageCode === 'zh-CN')?.text || ''}
                            </p>
                            <h3>语种</h3>
                            <Checkbox.Group className={styles.checkboxGroup} onChange={this.onSelectTranslateCode}>
                                {(scene?.scene?.languages as any)?.map((language: any) => {
                                    const lng = (needTranslateRecord as any)?.documents?.find((item: IDocument) => item.languageCode === language.code);
                                    if (lng && (lng.text || lng.languageCode === 'zh-CN')) {
                                        return null;
                                    }
                                    return (
                                        <Checkbox
                                            key={language.code}
                                            value={language.code}>
                                            {languageMap[language.code]}
                                        </Checkbox>
                                    );
                                })}
                            </Checkbox.Group>
                        </div>
                    </Modal>
                    <Modal
                        title="机翻语种选择"
                        destroyOnClose
                        visible={isShowBatchTranslateSelectModel}
                        onCancel={() => {
                            this.setState({
                                isShowBatchTranslateSelectModel: false, batchTranslateLanguage: '',
                            });
                        }}
                        onOk={this.onBatchTranslate}
                    >
                        <div className={styles.batchDelete}>
                            <h3>提示</h3>
                            <p>
                                图片类型及无中文文本的文案将会被过滤
                            </p>
                            <h3>语种</h3>
                            <Radio.Group className={styles.checkboxGroup} onChange={(val) => {
                                this.setState({
                                    batchTranslateLanguage: val.target.value
                                });
                            }}>
                                {(scene?.scene?.languages as any)?.map((language: any) => {
                                    if (language.code === 'zh-CN') {
                                        return null;
                                    }
                                    return (
                                        <Radio
                                            key={language.code}
                                            value={language.code}>
                                            {languageMap[language.code]}
                                        </Radio>
                                    );
                                })}
                            </Radio.Group>
                        </div>
                    </Modal>
                </Spin>
            </div>
        );
    }
}

export default connect(routeMapStateToProps)(withTranslation()(Form.create<IDocumentPageProps>()(DocumentPage)));
