import React, { useEffect, useState } from 'react';

import { ConfigProvider } from 'antd';
import {
    Router, Switch, Route, Redirect
} from 'dva/router';
import zhCN from 'antd/es/locale/zh_CN';
import enUS from 'antd/lib/locale/en_US';
import idID from 'antd/lib/locale/id_ID';
import arEG from 'antd/lib/locale/ar_EG';
import msMY from 'antd/lib/locale/ms_MY';
import viVN from 'antd/lib/locale/vi_VN';
import { createBrowserHistory } from 'history';
import { parse } from '@music/mobile-url';
import Home from '@/page/Home';
import Login from '@/page/Login';
import BaseLayout from '@/component/Layout';
import I18nReact from '@music/mobile-i18n';
import './App.less';

const browserHistory = createBrowserHistory();
const lngMap: any = {
    'zh-CN': zhCN,
    'en-US': enUS,
    'id-ID': idID,
    'ar-EG': arEG,
    'ms-MY': msMY,
    'vi-VN': viVN
};

interface Search {
    lng?: string;
}
const { lng }: Search = parse(window.location.search);

const config = {
    cdn: lng === 'zh-CN' ? 'https://f1.iplay.126.net/i18n-languages-nos-cdn/' : 'https://z1.baecdn.com/i18n-languages-aws-cdn/',
    version: 'v28',
};

// 构造多语言预加载模块：preModule
const RouteJson: any = {
    login: {
        path: '/login',
        component: Login
    },
    index: {
        path: '/:sceneId',
        component: Home
    },
    home: {
        path: '/',
        component: Home
    },
};

// 多语言后台目前路由的方式不适用按模块加载页面，干脆全量模块都加载掉
const preModule = [
    'login', 'textManage', 'menu',
];

const App: any = (props: any) => {
    const Comp = () => {
        const p: any = parse(props.history.location.search);
        const [inited, setInited] = useState(false);
        const [lng, setLng] = useState(p.lng || 'zh-CN');

        useEffect(() => {
            props.history.listen((location: any) => {
                const params: any = parse(location.search);
                if (params.lng) {
                    setLng(params.lng);
                }
            });
        }, []);

        useEffect(() => {
            const initI18n = async () => {
                const resourceConfig = {
                    preModule,
                    cdn: config.cdn,
                    version: config.version
                };

                // TODO: lng 需要调整
                await I18nReact.init({ lng }, resourceConfig, () => { });

                setInited(true);
            };

            initI18n();
        }, [lng]);

        if (!inited) {
            return <div />;
        }

        return (
            <ConfigProvider locale={lngMap[lng]}>
                <Router history={props.history || browserHistory}>
                    <Route render={(prop) => (
                        <BaseLayout {...prop}>
                            <Switch>
                                {
                                    Object.keys(RouteJson).map((e) => (
                                        <Route
                                            key={RouteJson[e].path}
                                            path={RouteJson[e].path}
                                            component={RouteJson[e].component} />
                                    ))
                                }
                                <Redirect to="/" />
                            </Switch>
                        </BaseLayout>
                    )} />
                </Router>
            </ConfigProvider>
        );
    };

    return Comp();
};

export default App;
