import React from 'react';
import { ModalProps } from 'antd/lib/modal';
import { Modal } from 'antd';
import GroupForm, { IGroupFormRef } from '@/component/GroupForm';
import { IGroupCreate } from '@/typings/IServiceModel';

interface IGroupFormModalProps extends ModalProps {
    onSubmit: (value: IGroupCreate) => void;
    dataSource?: IGroupCreate;
}

export default class GroupFormModal extends React.PureComponent<IGroupFormModalProps> {
    private ref: IGroupFormRef | null = null;

    onOk = (): void => {
        if (!this.ref) {
            return;
        }

        this.ref.preCheck((errors, value: IGroupCreate) => {
            const { onSubmit } = this.props;
            if (errors) {
                return;
            }

            const modifyValue = { ...value };
            if (modifyValue.key) {
                modifyValue.key = modifyValue.key.replace(/\s/g, '');
            }

            if (onSubmit) {
                onSubmit(modifyValue);
            }
        });
    }

    render() {
        const {
            onOk,
            onSubmit,
            dataSource,
            ...rest
        } = this.props;
        return (
            <Modal
                keyboard={false}
                destroyOnClose
                maskClosable={false}
                onOk={this.onOk}
                width={760}
                title="模块创建"
                {...rest}
            >
                <GroupForm
                    dataSource={dataSource}
                    wrappedComponentRef={(ref: IGroupFormRef) => {
                        this.ref = ref;
                    }}
                />
            </Modal>
        );
    }
}
