import * as service from '@/service/documentSet';
import {
    IServiceResult,
    IDocumentSet,
    IDocumentSetCreate,
    IGroupModelState,
    IDocumentSetModel,
    IDocumentCreate,
    IScene,
} from '@/typings/IServiceModel';
import { omit } from 'lodash';

interface GetPayload {
    id: number;
}

// 曲库标签数据
const scene: IDocumentSetModel = {
    namespace: 'documentSet',

    state: {
        data: null,
        id: 0,
    },

    effects: {
        * get(action, { call, put, }) {
            const payload = action.payload as GetPayload;
            try {
                const result: IServiceResult = yield call(service.getById, payload);
                yield put({
                    type: 'setState',
                    payload: {
                        data: result.data as IDocumentSet,
                        id: payload.id,
                    },
                });
            } catch (err) {
                yield put({
                    type: 'setState',
                    payload: {
                        data: null,
                        id: payload.id,
                    },
                });
                throw err;
            }
        },
        * update(action, { call }) {
            const payload = action.payload as IDocumentSet;
            yield call(service.update, payload);
        },
        * delete(action, { call }) {
            const payload = action.payload as IDocumentSet;
            yield call(service.remove, payload);
        },
        * bulkDelete(action, { call }) {
            const payload = action.payload as IDocumentSet;
            yield call(service.bulkRemove, payload);
        },
        * sendNeedTranslatePopoMsg(action, { call }) {
            const payload = action.payload as IDocumentSet;
            yield call(service.sendNeedTranslatePopoMsg, payload);
        },
        * sendPopoNotice(action, { call }) {
            const payload = action.payload as IDocumentSet;
            yield call(service.sendPopoNotice, payload);
        },
        * translate(action, { call }) {
            const payload = action.payload as IDocumentSet;
            const res: IServiceResult = yield call(service.translate, payload);

            return res && res.data;
        },
        * updateDocument(action, { call }) {
            const payload = action.payload as any;
            const res: IServiceResult = yield call(service.updateDocument, payload);

            return res && res.data;
        },
        * create(action, { call, select, put, all }) {
            const groupId = yield select((state: { group: IGroupModelState }) => state.group.id);
            const payload = action.payload as IDocumentSetCreate;
            const res: IServiceResult = yield call(service.create, {
                ...omit(payload, ['id', 'documents']),
                id: groupId,
            });

            if (!res.data || !res.data.id) {
                throw new Error('创建文案失败');
            }
            const documentSetId: number = res.data.id;
            const createDocumentMission = (payload.documents || []).map((document: IDocumentCreate) => {
                return put.resolve({
                    type: 'document/create',
                    payload: {
                        ...document,
                        id: documentSetId,
                    },
                });
            });

            yield all(createDocumentMission);
        },
        * checkKey({ payload }, { call, select, }) {
            const sceneId = yield select((state: { scene: IScene }) => state.scene.id);
            const params = Object.assign({ id: sceneId }, payload);
            const result: IServiceResult = yield call(service.checkKeyUnique, params);

            return result && result.data && result.data.result;
        },
        * generateKey({ payload }, { call, select, }) {
            const sceneId = yield select((state: { scene: IScene }) => state.scene.id);
            const params = Object.assign({ id: sceneId }, payload);
            const result: IServiceResult = yield call(service.generateKey, params);

            if (!result || !result!.data || !result.data.key) {
                throw new Error();
            }

            return result.data.key;
        },
    },

    reducers: {
        setState(state, { payload }) {
            return { ...state, ...payload };
        },
    },
};

export default scene;
