import App from '@/App';
import * as serviceWorker from './serviceWorker';
import dva, { DvaInstance, Model, Router } from 'dva';
import { createBrowserHistory } from 'history';
import createLoading from 'dva-loading';
import sceneModel from '@/model/scene';
import appsModel from '@/model/apps';
import userModel from '@/model/user';
import sceneListModel from '@/model/sceneList';
import groupListModel from '@/model/groupList';
import groupModel from '@/model/group';
import DocumentSetListModel from '@/model/documentSetList';
import LogListModel from '@/model/logList';
import DocumentSetModel from '@/model/documentSet';
import languageModel from '@/model/language';
import documentModel from '@/model/document';
import packageListModel from '@/model/packageList';
import loginModel from '@/model/login';
import publishModel from '@/model/publish';
import documentVersionListModel from '@/model/documentVersionList';
import './index.css';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

const app: DvaInstance = dva({
    history: createBrowserHistory(),
    onError: () => {
    },
});

app.use(createLoading());
app.model(sceneModel as Model);
app.model(sceneListModel as Model);
app.model(userModel as Model);
app.model(languageModel as Model);
app.model(groupListModel as Model);
app.model(groupModel as Model);
app.model(DocumentSetListModel as Model);
app.model(LogListModel as Model);
app.model(DocumentSetModel as Model);
app.model(documentModel as Model);
app.model(packageListModel as Model);
app.model(loginModel as Model);
app.model(appsModel as Model);
app.model(publishModel as Model);
app.model(documentVersionListModel as Model);
app.router(App as Router);
app.start('#root');

serviceWorker.unregister();
