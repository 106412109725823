// @ts-nocheck
/* eslint-disable react/jsx-filename-extension */
import React, { useState, FC } from 'react';
import {
    Modal, Form, Input
} from 'antd';
import { FormComponentProps } from 'antd/es/form';
import PropTypes from 'prop-types';
import { get } from 'lodash';

class LngForm extends React.Component<FormComponentProps, any> {
    handleSubmit = (cb) => {
        const { validateFields, resetFields } = this.props.form;
        validateFields((err, values) => {
            if (!err) {
                if (cb) cb(values);
                resetFields();
            }
        });
    };

    render() {
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
        };

        const { form, data, isNew } = this.props;
        const { getFieldDecorator } = form;
        return (
            <Form {...formItemLayout} className="login-form">
                <Form.Item label="描述">
                    {getFieldDecorator('desc', {
                        initialValue: get(data, 'desc', ''),
                        rules: [{ required: true, message: '请输入描述' }],
                    })(
                        <Input
                            placeholder="请输入描述" />
                    )}
                </Form.Item>
                <Form.Item label="通用语言简码">
                    {getFieldDecorator('code', {
                        initialValue: get(data, 'code', ''),
                        rules: [{ required: true, message: '请输入通用语言简码' }],
                    })(
                        <Input
                            disabled={!isNew}
                            placeholder="请输入通用语言简码" />
                    )}
                </Form.Item>
                <Form.Item label="ios简码">
                    {getFieldDecorator('iosCode', {
                        initialValue: get(data, 'iosCode', ''),
                        rules: [{ required: true, message: '请输入ios简码' }],
                    })(
                        <Input
                            placeholder="请输入ios简码" />
                    )}
                </Form.Item>
                <Form.Item label="安卓简码">
                    {getFieldDecorator('androidCode', {
                        initialValue: get(data, 'androidCode', ''),
                        rules: [{ required: true, message: '请输入安卓简码' }],
                    })(
                        <Input
                            placeholder="请输入安卓简码" />
                    )}
                </Form.Item>
                <Form.Item label="服务端简码">
                    {getFieldDecorator('backendCode', {
                        initialValue: get(data, 'backendCode', ''),
                        rules: [{ required: true, message: '请输入服务端简码' }],
                    })(
                        <Input
                            placeholder="请输入服务端简码" />
                    )}
                </Form.Item>
                <Form.Item label="前端简码">
                    {getFieldDecorator('frontendCode', {
                        initialValue: get(data, 'frontendCode', ''),
                        rules: [{ required: true, message: '请输入前端简码' }],
                    })(
                        <Input
                            placeholder="请输入前端简码" />
                    )}
                </Form.Item>
                <Form.Item label="Flutter简码">
                    {getFieldDecorator('flutterCode', {
                        initialValue: get(data, 'flutterCode', ''),
                        rules: [{ required: true, message: '请输入Flutter简码' }],
                    })(
                        <Input
                            placeholder="请输入Flutter简码" />
                    )}
                </Form.Item>
            </Form>
        );
    }
}

const WrappedLngForm = Form.create<FormComponentProps>()(LngForm);

const AddDialog: FC<any> = ({
    isModalVisible, onSubmit, onCancel, currentLng, isNew
}) => {
    const [formRef, setForm] = useState(null);

    const handleOk = () => {
        formRef.handleSubmit((values) => {
            onSubmit(values);
        });
    };

    return (
        <Modal title="新增语言包" visible={isModalVisible} onOk={handleOk} onCancel={onCancel}>
            <WrappedLngForm
                data={currentLng}
                isNew={isNew}
                wrappedComponentRef={(ref) => {
                    setForm(ref);
                }} />
        </Modal>
    );
};

AddDialog.propTypes = {
    isModalVisible: PropTypes.bool.isRequired,
    isNew: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    currentLng: PropTypes.instanceOf(Object)
};

AddDialog.defaultProps = {
    currentLng: {}
};

export default AddDialog;
