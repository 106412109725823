/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {
    Button, Upload, message, Icon, Modal, Input, Divider
} from 'antd';
import * as documentSetService from '@/service/documentSet';
import * as sceneService from '@/service/scene';
import XLSX from 'xlsx';
import { docsValid, clientEnum } from '@/util/docsValid';
import { exportFile } from '@/component/ExportExcel';

interface IExportExcelProps {
    sceneId: number | undefined;
    i18n: any;
    onSuccess: () => void;
    languages?: any[];
}

interface IExportExcelStates {
    createBatchLoading: boolean;
    googleSheetsUrl?: string;
}

const { confirm } = Modal;
export default class ImportExcel extends React.PureComponent<IExportExcelProps, IExportExcelStates> {
    constructor(props: any) {
        super(props);
        this.state = {
            createBatchLoading: false,
            googleSheetsUrl: '',
        };
    }

    stopLoading = () => {
        message.destroy();
        this.setState({
            createBatchLoading: false,
            googleSheetsUrl: '',
        });
    }

    trans = () => {
        const {
            i18n
        } = this.props;
        return i18n.getFixedT(null, ['textManage']);
    };

    handleImportList = async ({
        list,
        header
    }: {
        list: any[];
        header: any
    }) => {
        const trans = this.trans();
        const { sceneId = 0, onSuccess, languages: lngs = [] } = this.props;
        const languageIndex = 7; // 翻译从第7位开始

        for (const [listKey, listItem] of list.entries()) {
            const clientList = (listItem[5] || '').toString().split(/，|,/);
            const docList = listItem.slice(languageIndex);
            for (const cItem of clientList) {
                const clientItem = parseInt(cItem);
                for (const docItem of docList) {
                    const validMsg = docsValid(clientItem, docItem);
                    if (validMsg) {
                        message.error(`【excel line: ${listKey + 2}】${trans('hasUselessWord')}${clientEnum[clientItem].client} ${validMsg}`);
                        return false;
                    }
                }
            }
        }

        sceneService.getById({ id: sceneId }).then((scene) => {
            const languages = (scene && scene.data) ? scene.data.languages : [];
            const headerLanguages = Array.prototype.slice.call(header).slice(languageIndex).map((item) => item.split('(')[1].slice(0, -1));
            // 出错的行
            const errIdxArr: number[] = [];
            const documentSetList = list.filter((item: any) => item.length).map((item: any, idx: number) => {
                let documents = headerLanguages.map((language: string, index: any) => {
                    if (languages.find((lan: string) => lan === language) && item[index + languageIndex]) {
                        return {
                            languageCode: language,
                            text: item[index + languageIndex] || '' // 翻译内容从第5位开始
                        };
                    }
                    return null;
                });
                documents = documents.filter((document) => document);
                try {
                    return {
                        desc: item[0] || '',
                        imgDesc: item[1] || '',
                        groupKey: item[2]?.toString()?.replace(/\s/g, '') || '',
                        key: item[3],
                        type: item[4],
                        client: (item[5] || '').toString().replace(/，/, ','),
                        documents
                    };
                } catch (e) {
                    errIdxArr.push(idx);
                }
            });
            if (errIdxArr.length) {
                throw new Error(`第${errIdxArr.map((index) => index + 1).join()}行解析出错`);
            }
            this.setState({ createBatchLoading: true });
            return documentSetService.createBatch(sceneId, { documentsetlist: JSON.stringify(documentSetList) });
        }).then((res) => {
            if (res?.data?.result?.unMatchDocs?.length > 0) {
                const resp: any[] = res?.data?.result?.unMatchDocs;
                const unMatchDocs: any[] = resp.map((row) => ({
                    ...row,
                    imgDesc: row.imgDesc ? `http://${row.imgDesc}` : '',
                    group: {
                        key: row.groupKey
                    },
                }));

                // 弹窗提示，下载变量未匹配文案
                Modal.warning({
                    title: '文案校验不通过',
                    content: (
                        <div>
                            存在变量未匹配的文案，请下载
                            <span
                                onClick={() => exportFile({
                                    list: unMatchDocs, languages: lngs, sceneId, isGoogle: false
                                })}
                                style={{ color: '#1890ff', cursor: 'pointer' }}>
                                Excel
                            </span>
                            或
                            <span
                                onClick={() => exportFile({
                                    list: unMatchDocs, languages: lngs, sceneId, isGoogle: true
                                })}
                                style={{ color: '#1890ff', cursor: 'pointer' }}>
                                Google Sheets
                            </span>
                            ，修正后重新上传。
                        </div>
                    ),
                });
            }

            let mesg = trans('uploadSuccess');
            if (res && res.data && res.data.result) {
                const { createCount, updateCount } = res.data.result;
                mesg += `${trans('createCount')} ${createCount}，${trans('updateCount')} ${updateCount}`;
            }
            message.success(mesg);
            if (onSuccess) {
                onSuccess();
            }
            this.setState({ createBatchLoading: false });
        }, (err: any) => {
            message.error(`${trans('uploadFail')}：${err.message}`);
            this.setState({ createBatchLoading: false });
        });
    };

    importExcel = async (file: any): Promise<any> => {
        const { name } = file;
        const { sceneId = 0 } = this.props;
        const uploadSceneName = name?.split('_')?.[1];
        const currentScene = await sceneService.getById({ id: +sceneId });
        if (currentScene?.data?.name !== uploadSceneName) {
            message.error(`文件内容显示当前文件属于  “${uploadSceneName || ''}” ，请从左上角选择相应应用并重新导入`);
            return false;
        }
        const trans = this.trans();
        if (!sceneId) {
            message.error(trans('illegalImport'));
            return false;
        }
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = () => {
            const result = reader.result;
            if (typeof result === 'string' || !result) {
                message.error('FileReader error');
                return;
            }
            const data = new Uint8Array(result);
            const workbook = XLSX.read(data, { type: 'array' });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const list: string[][] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            const header = list.shift(); // 去除首行
            Modal.destroyAll();
            this.handleImportList({
                list,
                header
            });
        };
        return false;
    }

    importGoogleSheets = async (url: string) => {
        // 格式belike https://docs.google.com/spreadsheets/d/1kPsTVcdJKMwJTLZbj56xE-YNEq7fjULtPXCTCm-eD-Q/edit#gid=1779953709
        const pattern = /\/d\/([a-zA-Z0-9-_]+)/;
        const matches = url.match(pattern);
        if (matches && matches.length > 1) {
            const spreadsheetId = matches[1];
            const result = await documentSetService.importGoogleSheets({
                spreadsheetId
            });
            const { sheetName: name, values } = result.data;
            const { sceneId = 0 } = this.props;
            const uploadSceneName = name?.split('_')?.[1];
            const currentScene = await sceneService.getById({ id: +sceneId });
            if (currentScene?.data?.name !== uploadSceneName) {
                message.error(`文件内容显示当前文件属于  “${uploadSceneName || ''}” ，请从左上角选择相应应用并重新导入`);
                return;
            }
            const header = values.shift(); // 去除首行
            this.handleImportList({
                list: values,
                header
            });
        } else {
            message.error('未能正确获取google sheet 文档id，请确认文档地址是否有误');
        }
    }

    showConfirm = () => {
        const { createBatchLoading } = this.state;
        const trans = this.trans();
        const self = this;
        confirm({
            title: '请选择导入方式',
            content: (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <div style={{ marginBottom: 10 }}>
                        <div style={{ marginBottom: 5, fontWeight: 'bold' }}>方式一：导入Google Sheets</div>
                        <Input
                            style={{ width: '80%' }}
                            placeholder="请输入Google Sheets链接"
                            type="text"
                            onChange={(e) => {
                                self.setState({ googleSheetsUrl: e.currentTarget.value || '' });
                            }} />
                    </div>
                    <Divider>分割线</Divider>
                    <div style={{ marginBottom: 10 }}>
                        <div style={{ marginBottom: 5, fontWeight: 'bold' }}>方式二：导入Excel</div>
                        <Upload
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,.xlsx"
                            showUploadList={false}
                            disabled={createBatchLoading}
                            beforeUpload={this.importExcel}>
                            <Button type="primary">
                                {trans('importExcel')}
                            </Button>
                        </Upload>
                    </div>
                </div>
            ),
            okText: 'Yes',
            okType: 'primary',
            cancelText: 'Cancel',
            onOk() {
                if (self.state.googleSheetsUrl) {
                    self.importGoogleSheets(self.state.googleSheetsUrl);
                }
            },
            onCancel() {},
        });
    };

    render() {
        const { createBatchLoading } = this.state;
        const trans = this.trans();
        return (
            <Button
                type="primary"
                disabled={createBatchLoading}
                onClick={this.showConfirm}>
                {createBatchLoading && <Icon type="loading" />}
                {trans('importExcel')}
            </Button>
        );
    }
}
