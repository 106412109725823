import React from 'react';
import {
    Button, Popover, Checkbox, Row
} from 'antd';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import I18nReact from '@music/mobile-i18n';

interface IColumn {
    label: any;
    value: any;
}

interface IColumnSetProps {
    onChange?: (checkedValues: CheckboxValueType[]) => void;
    options?: IColumn[];
    defaultValue?: any[];
}

export default class ColumnSet extends React.PureComponent<IColumnSetProps> {
    state = {
    };

    trans = () => I18nReact.i18n.getFixedT(null, ['textManage']);

    onChangeCheck = (checkedValues: CheckboxValueType[]) => {
        const {
            onChange
        } = this.props;
        if (onChange) {
            onChange(checkedValues);
        }
    };

    renderContent = () => {
        const options = this.props.options || [];
        const defaultValue = this.props.defaultValue;
        return (
            <Checkbox.Group
                defaultValue={defaultValue}
                onChange={this.onChangeCheck}>
                {options.map((item) => (
                    <Row key={item.value}>
                        <Checkbox value={item.value}>{item.label}</Checkbox>
                    </Row>
                ))}
            </Checkbox.Group>
        );
    }

    render() {
        return (
            <Popover
                content={this.renderContent()}
                title="列设置"
                trigger="click">
                <Button type="primary">列设置</Button>
            </Popover>
        );
    }
}
