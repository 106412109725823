import { API_BASE } from '@/util/const';
import fetch from '@/util/fetch';
import {
    IServiceResult, IPageParams
} from '@/typings/IServiceModel';

export const getList = (data: IPageParams): PromiseLike<IServiceResult> => {
    const {
        id,
        ...rest
    } = data;
    return fetch(`${API_BASE}/scenes/${id}/logs`, {
        data: rest,
    });
};

export const a = 1;
