import { API_BASE } from '@/util/const';
import fetch from '@/util/fetch';
import { IServiceResult } from '@/typings/IServiceModel';

export const get = (data: any): PromiseLike<IServiceResult> => {
    return fetch(`${API_BASE}/apps/list`, {
        method: 'post',
        data
    });
};

export const remove = (id: number): PromiseLike<IServiceResult> => {
    return fetch(`${API_BASE}/app/delete/${id}`, {
        method: 'delete'
    });
};
