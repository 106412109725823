import React, { ReactElement } from 'react';
import { connect } from 'dva';
import { IRouteProps } from '@/typings/IRoute';
import { routeMapStateToProps } from '@/util/helper';
import { Menu, Icon } from 'antd';
import memoizeFn from '@music/tl-memoize-fn';
import { Link, matchPath } from 'dva/router';
import { withTranslation } from '@music/mobile-i18n';
import { Storage } from '@music/helper';
import { IScene } from '@/typings/IServiceModel';

import Style from './style.module.less';

const { Local } = Storage;
const LocalCollapsedKey = 'collapsedKey221017';
interface SlideMenu {
    key: string;
    to: string;
    icon: string;
    name: string | ReactElement;
    subMenu?: SlideMenu[];
    defaultOpenKeys?: any[];
    selectedKeys?: any[];
    permission?: number;
}

interface PageSiderStates {
    inlineCollapsed: boolean;
}

// 带有权限判断的 Menu.Item 组件
@connect(routeMapStateToProps)
class PageSider extends React.PureComponent<IRouteProps, PageSiderStates> {
    @memoizeFn(
        ['props', 'match', 'path'],
        ['props', 'match', 'url']
    )

    private trans: (...args: any[]) => string;

    constructor(props: IRouteProps) {
        super(props);

        this.trans = props.i18n.getFixedT(null, ['menu']);

        this.state = {
            inlineCollapsed: false
        };
    }

    private getSelectKey(): string[] {
        const { path: routeList, url } = this.props?.match;
        return (routeList as any || [])?.filter((route: string) => {
            return !!url && !!matchPath(url, {
                path: route,
                exact: true,
                strict: true,
            });
        });
    }

    componentDidMount(): void {
        this.setState({
            inlineCollapsed: Local.getItem(LocalCollapsedKey) !== '1'
        });
    }

    render() {
        const { scene, group, login, history } = this.props;
        const { scenes, permission } = login.userInfo;
        const { inlineCollapsed } = this.state;

        // 生成权限菜单，如果没有应用权限，默认sceneid=0
        const genAuthMenu = (sceneId = 0, subMenu: any = []) => ([
            {
                key: '/:sceneId/authority',
                to: '',
                icon: 'setting',
                name: (<><Icon type="key" />{this.trans('authManage')}</>),
                subMenu: [{
                    key: '/:sceneId/authority/apply',
                    to: `/${sceneId}/authority/apply`,
                    icon: 'setting',
                    name: this.trans('authApply'),
                }].concat(subMenu)
            },
        ]);

        const filteredMenus = (): SlideMenu[] => {
            let menus: SlideMenu[] = [];

            // 如果用户没有任何应用权限，则默认展示权限菜单
            if (!scene) {
                menus = menus.concat(genAuthMenu(0));
                return menus;
            }

            menus = [
                {
                    key: '/:sceneId/apps',
                    to: `/${scene.id}/apps`,
                    icon: 'profile',
                    name: this.trans('appManage'),
                    permission: 300,
                    subMenu: []
                },
                {
                    key: '/:sceneId/language',
                    to: `/${scene.id}/language`,
                    icon: 'project',
                    name: this.trans('languageManage'),
                    permission: 300,
                    subMenu: []
                },
                {
                    key: '/:sceneId/group',
                    to: `/${scene.id}/group`,
                    icon: 'container',
                    name: this.trans('moduleManage'),
                    subMenu: []
                },
                {
                    key: '/:sceneId/document',
                    to: `/${scene.id}/document/${group?.id || 0}`,
                    icon: 'file-word',
                    name: this.trans('textManage'),
                    subMenu: []
                },
                {
                    key: '/:sceneId/publish',
                    to: `/${scene.id}/publish`,
                    icon: 'setting',
                    name: this.trans('publishManage'),
                    subMenu: []
                },
                {
                    key: '/:sceneId/authority',
                    to: '',
                    icon: 'key',
                    name: this.trans('authManage'),
                    // name: (<><Icon type="key" />{this.trans('authManage')}</>),
                    subMenu: [
                        {
                            key: '/:sceneId/authority/check',
                            to: `/${scene.id}/authority/check`,
                            icon: 'setting',
                            name: this.trans('authCenter'),
                        },
                        {
                            key: '/:sceneId/authority/userlist',
                            to: `/${scene.id}/authority/userlist`,
                            icon: 'setting',
                            name: this.trans('userManage'),
                        },
                        {
                            key: '/:sceneId/authority/apply',
                            to: `/${scene.id}/authority/apply`,
                            icon: 'setting',
                            name: this.trans('authApply'),
                        },
                    ]
                },
                {
                    key: '/:sceneId/log',
                    to: `/${scene.id}/log`,
                    icon: 'setting',
                    name: this.trans('actionLog'),
                    subMenu: []
                },
            ];

            const currentScene = (scenes as IScene[]).find((item) => item.id === scene.id);

            if (login?.userInfo?.permission === 300) {
                return menus;
            }

            // 过滤出需要权限控制且菜单所需权限小于等于当前用户权限的菜单
            menus = menus.filter((o) => !o.permission || !permission || o.permission <= permission);

            // 翻译人员只能看到文案以及权限
            if (currentScene?.scene_user?.pms === 50 || !login?.userInfo?.scenes?.length) {
                menus = [];
                const textManage = this.trans('textManage');
                if (currentScene?.scene_user?.status === 2) {
                    menus = menus.concat([{
                        key: '/:sceneId/document',
                        to: `/${scene.id}/document/${group?.id || 0}`,
                        icon: 'file-word',
                        name: textManage,
                        subMenu: []
                    }]);
                }
                menus = menus.concat(genAuthMenu(scene.id));
            }
            return menus;
        };

        return (
            <div className={inlineCollapsed ? Style.siderMenuCollapsed : Style.siderMenu}>
                <div
                    className={Style.contract}
                    onClick={() => {
                        Local.setItem(LocalCollapsedKey, inlineCollapsed ? '1' : '0');
                        this.setState({
                            inlineCollapsed: !inlineCollapsed
                        });
                    }}>
                    {
                        inlineCollapsed
                            ? <Icon type="double-right" />
                            : <Icon type="double-left" />
                    }
                </div>
                <Menu
                    mode="inline"
                    inlineCollapsed={inlineCollapsed}
                    selectedKeys={this.getSelectKey()}
                    defaultOpenKeys={['/:sceneId/authority']}
                    style={{ height: '100%', borderRight: 0 }}>
                    {
                        filteredMenus().map((menu) => (

                            menu.subMenu && menu.subMenu.length > 0
                                ? (
                                    <Menu.SubMenu key={menu.key} title={inlineCollapsed ? <Icon type="key" /> : <><Icon type="key" />{menu.name}</> }>
                                        {
                                            menu?.subMenu?.map((sub) => (
                                                <Menu.Item key={sub.key || ''} title={sub.name}>
                                                    <Link to={`${sub.to}${history.location.search}`}>
                                                        <Icon type={sub.icon || ''} />
                                                        {sub.name}
                                                    </Link>
                                                </Menu.Item>
                                            ))
                                        }
                                    </Menu.SubMenu>
                                )
                                : (
                                    <Menu.Item key={menu.key} title={menu.name}>
                                        <Link to={`${menu.to}${history.location.search}`}>
                                            <Icon type={menu.icon} />
                                            {menu.name}
                                        </Link>
                                    </Menu.Item>
                                )
                        ))
                    }
                </Menu>
            </div>
        );
    }
}

export default withTranslation()(PageSider);
