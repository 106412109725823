import * as service from '@/service/document';
import {
    IServiceResult,
    IDocumentCreate,
    IDocumentModel,
    IDocument,
    IdParams,
} from '@/typings/IServiceModel';

interface GetPayload {
    id: number;
}

// 曲库标签数据
const scene: IDocumentModel = {
    namespace: 'document',

    state: {
        data: null,
        id: 0,
    },

    effects: {
        * get(action, { call, put }) {
            const payload = action.payload as GetPayload;
            try {
                const result: IServiceResult = yield call(service.getById, payload);
                yield put({
                    type: 'setState',
                    payload: {
                        data: result.data as IDocument,
                        id: payload.id,
                    },
                });
            } catch (err) {
                yield put({
                    type: 'setState',
                    payload: {
                        data: null,
                        id: payload.id,
                    },
                });
                throw err;
            }
        },
        * update(action, { call }) {
            const payload = action.payload as IDocument;
            return yield call(service.update, payload);
        },
        * delete(action, { call }) {
            const payload = action.payload as IdParams;
            yield call(service.remove, payload);
        },
        * create(action, { call }) {
            const payload = action.payload as IDocumentCreate;
            return yield call(service.create, payload);
        },
    },

    reducers: {
        setState(state, { payload }) {
            return { ...state, ...payload };
        },
    },
};

export default scene;
