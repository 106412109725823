import React from 'react';
import { FormComponentProps, ValidateCallback } from 'antd/lib/form';
import { IGroupCreate } from '@/typings/IServiceModel';
import { Input, Form } from 'antd';
import { get } from 'lodash';

interface IGroupFormProps extends FormComponentProps {
    onChange?: (value: IGroupCreate) => void;
    dataSource?: IGroupCreate;
}

export interface IGroupFormRef extends JSX.Element {
    preCheck: (cb: ValidateCallback<IGroupCreate>) => {};
}

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};

class GroupForm extends React.PureComponent<IGroupFormProps> {
    preCheck = (cb: ValidateCallback<IGroupCreate>) => {
        this.props.form.validateFieldsAndScroll(cb);
    }

    render() {
        const {
            form,
            dataSource,
        } = this.props;
        const { getFieldDecorator } = form;
        return (
            <Form
                {...formItemLayout}
                layout="horizontal">
                <Form.Item
                    label="模块名"
                    key="name">
                    {getFieldDecorator('name', {
                        validateFirst: true,
                        initialValue: get(dataSource, 'name', ''),
                        rules: [
                            {
                                required: true,
                                message: ' 不得为空',
                            },
                            {
                                max: 255,
                                message: '不得大于255个字符',
                            },
                        ],
                    })(
                        <Input />
                    )}
                </Form.Item>
                <Form.Item
                    label="key"
                    key="key">
                    {getFieldDecorator('key', {
                        validateFirst: true,
                        initialValue: get(dataSource, 'key', ''),
                        rules: [
                            {
                                required: true,
                                message: ' 不得为空',
                            },
                            {
                                max: 255,
                                message: '不得大于255个字符',
                            },
                            {
                                pattern: /^[0-9a-zA-Z_-]+$/,
                                message: ' 只能使用数字、英文、下划线(_)、中划线(-)',
                            },
                        ],
                    })(
                        <Input />
                    )}
                </Form.Item>
            </Form>
        );
    }
}

export default Form.create<IGroupFormProps>()(GroupForm);
