import * as loginService from '@/service/login';
import { ILoginModel } from '@/typings/IServiceModel';
import { message } from 'antd';

// 曲库标签数据
const login: ILoginModel = {
    namespace: 'login',

    state: {
        data: {
            status: 0,
            userInfo: {
                nickName: '姓名',
                mailAddress: 'test@corp.netease.com'
            },
            captcha: {
                captchaId: '',
                imgUrl: '',
            }
        }
    },

    effects: {
        * getLoginStatus(...args) {
            const { call, put } = args[1];

            try {
                const result = yield call(loginService.getLoginStatus);
                const userPersission = yield call(loginService.getUserPersission, {
                    ...result.data
                });
                yield put({
                    type: 'setLoginStatus',
                    payload: {
                        data: {
                            status: 1,
                            userInfo: { ...userPersission.data.user },
                        }
                    },
                });
                return result;
            } catch (err) {
                message.error(err.message);
                yield put({
                    type: 'getCaptcha',
                });
                throw err;
            }
        },

        * pmsLogin(action, { call }) {
            const { redirectUrl, fromHost } = action.payload;
            return yield call(loginService.pmsLogin, {
                redirectUrl,
                fromHost
            });
        },

        * musicLogin(action, { call }) {
            const {
                account, password, captchaId, captchavalue
            } = action.payload;
            return yield call(loginService.musicLogin, {
                account,
                password,
                captchaId,
                captchavalue
            });
        },

        // 云音乐 验证码获取
        * getCaptcha(_, { call, put }) {
            const { data } = yield call(loginService.getCaptcha);
            yield put({
                type: 'setCaptcha',
                payload: {
                    data: {
                        captcha: data
                    }
                },
            });
            return data;
        },

        // 云音乐验证码校验
        * verifyCaptcha({ payload: { captchaId, captchaValue } }, { call }) {
            const { data } = yield call(loginService.verifyCaptcha, {
                captchaId,
                captchaValue
            });
            return data;
        },

        * logout(...args) {
            const { call } = args[1];

            return yield call(loginService.logout);
        }
    },

    reducers: {
        setLoginStatus(state, { payload }) {
            return { ...state, ...payload };
        },
        setCaptcha(state, { payload }) {
            return { ...state, ...payload };
        },
    },
};

export default login;
