/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { ModalProps } from 'antd/lib/modal';
import { FormComponentProps } from 'antd/lib/form';
import { Modal, Form, Select } from 'antd';
import { IGroup, IDocumentSet } from '@/typings/IServiceModel';

interface IDocumentSetGroupModalProps extends ModalProps {
    onSubmit: (values: any) => void;
    groupList: IGroup[];
    dataSource?: IDocumentSet | boolean;
    i18n: any;
}

interface IDocumentSetGroupFormProps extends FormComponentProps {
    onSubmit: () => void;
    dataSource?: IDocumentSet | boolean;
    groupList: IGroup[];
    i18n: any;
}

const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};

const DocumentSetGroupForm = Form.create<IDocumentSetGroupFormProps>({ name: 'form_in_modal' })(
    class extends React.PureComponent<IDocumentSetGroupFormProps> {
        render() {
            const {
                onSubmit,
                dataSource,
                groupList,
                form,
                i18n,
                ...rest
            } = this.props;
            const { getFieldDecorator } = form;
            const trans = i18n.getFixedT(null, ['textManage']);
            return (
                <Modal
                    visible={!!dataSource}
                    keyboard={false}
                    destroyOnClose
                    maskClosable={false}
                    onOk={onSubmit}
                    width={380}
                    title={trans('replaceGroup')}
                    {...rest}>
                    <Form {...layout}>
                        <Form.Item label={trans('divideGroup')} key="groupId">
                            {getFieldDecorator('groupId', {
                                initialValue: typeof dataSource === 'object' ? dataSource.groupId : undefined,
                                rules: [{ required: true, message: 'Please select!' }],
                            })(
                                <Select>
                                    {groupList.map((item: IGroup) => (
                                        <Select.Option key={item.id} value={item.id}>
                                            {`${item.name}(${item.key})`}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            );
        }
    }
);

export default class DocumentSetGroupModal extends React.PureComponent<IDocumentSetGroupModalProps> {
    private ref: JSX.Element | null = null;

    state = {
        visible: false,
    };

    handleCancel = () => {
    };

    handleSubmit = () => {
        if (!this.ref) {
            return;
        }

        const { form } = this.ref.props;
        form.validateFields((err: any, values: any) => {
            if (err) {
                return;
            }

            form.resetFields();
            this.props.onSubmit(values);
        });
    };

    render() {
        const {
            i18n,
            ...rest
        } = this.props;
        return (
            <DocumentSetGroupForm
                i18n={i18n}
                {...rest}
                wrappedComponentRef={(ref: JSX.Element) => {
                    this.ref = ref;
                }}
                onSubmit={this.handleSubmit}
            />
        );
    }
}
