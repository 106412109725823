import React from 'react';
import {
    Table,
    message,
    Input,
    Popconfirm
} from 'antd';
import { IRouteProps } from '@/typings/IRoute';
import AddSceneModal from '@/component/AddSceneModal';
import { connect } from 'dva';
import { IScene, ISceneCreate } from '@/typings/IServiceModel';
import { routeMapStateToProps } from '@/util/helper';
import styles from './style.module.less';

interface IPublishPageState {
    isInit: boolean;
    loading: boolean;
    isShowAddSceneModal: boolean;
    isNew: boolean;
    title: string;
    currentScene: IScene | null;
}

type iPublishPage = IRouteProps & {
    [key: string]: any;
};

const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000; // 时区相差，单位为秒

@connect(routeMapStateToProps)
export default class Apps extends React.PureComponent<iPublishPage, IPublishPageState> {
    state = {
        isInit: true,
        loading: false,
        isShowAddSceneModal: false,
        isNew: false,
        title: '',
        currentScene: null
    };

    current = null; // 记录当前发布记录

    columns = [
        {
            title: 'id',
            key: 'id',
            dataIndex: 'id'
        },
        {
            title: '应用名',
            key: 'name',
            dataIndex: 'name',
        },
        {
            title: '应用英文名',
            key: 'aliasName',
            dataIndex: 'aliasName'
        },
        {
            title: '语言',
            key: 'originalLanguages',
            dataIndex: 'originalLanguages',
            render: (languages: any[]) => languages.map((language) => language.desc).join(',')
        },
        {
            title: '创建人',
            key: 'creator',
            dataIndex: 'creator',
        },
        {
            title: '更新时间',
            key: 'createdAt',
            dataIndex: 'createdAt',
            render: (updatedAt: any) => {
                const operateTime = new Date(new Date(updatedAt).getTime() - timezoneOffset);
                const updateAtSplits = operateTime.toISOString().split(/T|\./);

                return updateAtSplits.slice(0, 2).join(' ');
            }
        },
        {
            title: '操作',
            key: 'action',
            render: (_: any, record: any) => {
                return (
                    <span className={styles.actionDone}>
                        <a onClick={() => this.onEdit(record)}>编辑</a>
                        <Popconfirm
                            title="确定删除么？"
                            onConfirm={() => this.onDelete(record)}
                            okText="确认"
                            cancelText="取消"
                        >
                            <a href="#">删除</a>
                        </Popconfirm>
                    </span>
                );
            },
        },
    ];

    onEdit = (record: any) => {
        this.setState({
            isNew: false,
            title: `编辑${record.name}`,
            currentScene: record,
            isShowAddSceneModal: true
        });
    }

    onDelete = (record: any) => {
        const { dispatch } = this.props;

        dispatch({
            type: 'groupList/get',
            payload: {
                id: record.id
            },
        }).then(() => {
            const { groupList, appsList } = this.props;

            if (groupList.length && groupList.find((group) => group.name.indexOf('未分配') === -1)) {
                message.warn('已添加模块不允许删除，请先删除模块！');
            } else {
                dispatch({
                    type: 'apps/delete',
                    payload: record.id
                }).then(() => {
                    message.success('删除成功');
                    // this.getList();

                    const nextApp = (appsList.data || []).find((app: any) => app.id !== record.id);
                    window.location.href = nextApp ? `${window.location.origin}/${nextApp.id}/apps` : window.location.origin;
                }).catch((e) => {
                    message.error(e && e.message || '删除失败');
                });
            }
        }).catch((e) => {
            message.error(e && e.message);
        });
    }

    getList = (query?: string) => {
        const { dispatch } = this.props;

        this.setState({
            loading: true
        });
        dispatch({
            type: 'apps/get',
            payload: {
                query: query || '',
            },
        }).then(() => {
            this.setState({
                loading: false
            });
        }).catch(() => {
            this.setState({
                loading: false
            });
        });
    }

    onChangePage = (value: any) => {
        const { dispatch } = this.props;

        dispatch({
            type: 'apps/setCurrent',
            payload: {
                current: value
            }
        });
    }

    componentDidMount() {
        this.getList();
    }

    private createScene = (value: ISceneCreate) => {
        const { dispatch, scene } = this.props;
        const { isNew, currentScene } = this.state;
        if (!isNew) {
            value.id = (currentScene as any).id;
        }
        dispatch({
            type: isNew ? 'scene/create' : 'scene/update',
            payload: value,
        }).then(() => {
            return Promise.all([
                dispatch({
                    type: 'scene/get',
                    payload: {
                        id: scene!.id,
                    },
                }),
                dispatch({
                    type: 'sceneList/get',
                }),
            ]);
        }).then(() => {
            message.success(isNew ? '创建成功' : '编辑成功');
            this.setState({
                isShowAddSceneModal: false,
                isNew: false,
                title: '',
                currentScene: null
            });
            this.getList();
        }).catch((err) => {
            message.error(err.message || '请求失败，请重试');
        });
    }

    onSearch = (value: string) => {
        this.getList(value);
    }

    render() {
        const { appsList, language, login } = this.props;
        const {
            title, currentScene,
            isNew, isShowAddSceneModal
        } = this.state;
        return (
            <div className={styles.publish}>
                <Input.Search
                    style={{ width: 240 }}
                    placeholder="请输入应用名/应用英文名"
                    onSearch={this.onSearch}
                    enterButton="搜索" />
                <div className={styles.marginBlock}></div>
                <Table
                    rowKey="id"
                    loading={this.state.loading}
                    dataSource={appsList.data}
                    columns={this.columns}
                    pagination={{
                        current: appsList.current,
                        total: appsList.total,
                        onChange: this.onChangePage,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        showSizeChanger: true,
                    }} />
                <AddSceneModal
                    isNew={isNew}
                    login={login}
                    title={title}
                    dataSource={currentScene || undefined}
                    languageList={language}
                    visible={isShowAddSceneModal}
                    onSubmit={this.createScene}
                    onCancel={() => {
                        this.setState({
                            currentScene: null,
                            isShowAddSceneModal: false,
                        });
                    }}
                />
            </div>
        );
    }
}
