import { IFetchOption, IServiceResult } from '@/typings/IServiceModel';
// import fetch from '@music/encrypt-fetch';

function obj2query(obj: object | null): string {
    if (!obj) {
        return '';
    }
    let str: string = '';
    Object.entries(obj).forEach(([key, value]: [string, any]) => {
        if (typeof value === 'undefined') {
            return;
        } else if (typeof value === 'object') {
            str += encodeURIComponent(key) + '=' + encodeURIComponent(JSON.stringify(value)) + '&';
        } else {
            str += encodeURIComponent(key) + '=' + encodeURIComponent(value) + '&';
        }
    });
    return str.slice(0, -1);
}

export default (url: string, fetchOption: IFetchOption = {}): Promise<IServiceResult> => {
    const options: IFetchOption = {};

    if (fetchOption.method) {
        options.method = fetchOption.method;
    } else {
        options.method = 'get';
    }

    if (options.method === 'get') {
        const paramsStr = obj2query(fetchOption.data || null);
        if (paramsStr) {
            url = url.indexOf('?') > -1 ? `${url}&${paramsStr}` : `${url}?${paramsStr}`;
        }
    } else {
        options.body = JSON.stringify(fetchOption.data || {});
    }

    options.headers = Object.assign({
        'content-type': 'application/json',
    }, fetchOption.headers || {});

    if (fetchOption.credentials) {
        options.credentials = fetchOption.credentials;
    }

    return fetch(url, options as RequestInit)
        .then((response: Response) => (
            response.json()
        )).then((result: IServiceResult) => {
            if (!result || result.code !== 200) {
                throw result;
            } else {
                return result;
            }
        });
};
