/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { Select, Spin } from 'antd';
import { SelectProps } from 'antd/es/select';
import debounce from 'lodash/debounce';

export interface UserValue {
    label: string;
    value: string;
}

export interface DebounceSelectProps extends SelectProps {
    fetchOptions: (search: string) => Promise<UserValue[]>;
    debounceTimeout?: number;
}

const DebounceSelect: React.FC<DebounceSelectProps> = (
    { fetchOptions, debounceTimeout = 500, ...props }
) => {
    const [fetching, setFetching] = React.useState(false);
    const [options, setOptions] = React.useState<UserValue[]>([]);
    const fetchRef = React.useRef(0);

    const debounceFetcher = React.useMemo(() => {
        const loadOptions = (value: string) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);

            fetchOptions(value).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    return;
                }

                setOptions(newOptions);
                setFetching(false);
            });
        };

        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);

    return (
        <Select
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            {...props}>
            {
                options.map((item) => (
                    <Select.Option key={item.value} value={item.value}>{item.label}</Select.Option>
                ))
            }
        </Select>
    );
};

export default DebounceSelect;
