import * as service from '@/service/documentSet';
import {
    IDocumentSet,
    IDocument,
    IDocumentSetListModel,
    IDocumentModelState,
    IGroupModelState,
    ISceneModelState,
    IServiceListResult,
    docLen,
} from '@/typings/IServiceModel';
import { pick } from 'lodash';
import { IAction } from '@/typings/IDva';
import { getDocumnetLen } from '@/util';

// 获取文案最后更新时间
const findUpdateTime = (record: IDocumentSet) => {
    const { updatedAt, documents } = record;
    const res = documents.sort((a: IDocument, b: IDocument) => {
        if (a.updatedAt && b.updatedAt) {
            return new Date(a.updatedAt) > new Date(b.updatedAt) ? -1 : 1;
        }
        return 0;
    });
    const docUpdatedAt = res[0]?.updatedAt;

    return new Date(docUpdatedAt || 0) > new Date(updatedAt || 0) ? docUpdatedAt : updatedAt;
};

// 曲库标签数据
const sceneList: IDocumentSetListModel = {
    namespace: 'documentSetList',

    state: {
        data: [],
        limit: 10,
        offset: 0,
        total: 0,
    },

    effects: {
        * get(action: IAction, { call, put, select }) {
            try {
                const payload = action.payload;
                const { limit, offset } = yield select((state: { documentSetList: IDocumentModelState }) => state.documentSetList);
                const groupId = yield select((state: { group: IGroupModelState }) => state.group.id);
                const sceneId = yield select((state: { scene: ISceneModelState }) => state.scene.id);
                const params = Object.assign({
                    limit,
                    offset,
                    id: groupId,
                    sceneId
                }, payload);
                const result: IServiceListResult = yield call(service.getList, params);

                if (!result.data) {
                    throw new Error('数据获取错误');
                }

                const { list = [], publishTime = 0 } = result?.data;

                const lists: IDocumentSet[] = list.map((item: IDocumentSet) => {
                    const { documents = [] } = item;
                    const lastUpdatedAt = findUpdateTime(item);
                    const isPublish = new Date(publishTime) > new Date(lastUpdatedAt || 0);
                    const docLens: docLen[] = documents.map((document: IDocument) => {
                        const { languageCode, text } = document;
                        const length = getDocumnetLen(text);
                        return {
                            length,
                            languageCode
                        };
                    });
                    item.docLens = docLens;
                    item.isPublish = isPublish;
                    item.lastUpdatedAt = lastUpdatedAt;
                    return item;
                });

                yield put({
                    type: 'setState',
                    payload: {
                        ...pick(params, ['offset', 'limit']),
                        data: lists as IDocumentSet[],
                        total: result.data.total as number,
                    },
                });
                return true;
            } catch (err) {
                yield put({
                    type: 'setState',
                    payload: {
                        data: [],
                        // total: 0,
                    },
                });
                throw err;
            }
        },
        * bulkUpdate(action, { call }) {
            const payload = action.payload;
            yield call(service.bulkUpdate, payload);
        },

        * filter(action, { call, select, put }) {
            const payload = action.payload;
            const { limit, offset } = yield select((state: { documentSetList: IDocumentModelState }) => state.documentSetList);
            const params = Object.assign({
                limit,
                offset,
            }, payload);
            const result: IServiceListResult = yield call(service.filter, params);
            if (!result.data) {
                throw new Error('数据获取错误');
            }

            yield put({
                type: 'setState',
                payload: {
                    ...pick(params, ['offset', 'limit']),
                    data: result.data.list as IDocumentSet[],
                    total: result.data.total as number,
                },
            });
            return true;
        },
    },

    reducers: {
        setState(state, { payload }) {
            return { ...state, ...payload };
        },
    },
};

export default sceneList;
