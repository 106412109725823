/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { connect } from 'dva';
import { routeMapStateToProps } from '@/util/helper';
import { ILog } from '@/typings/IServiceModel';
import {
    Table, Button, Form, Input, message
} from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { ColumnProps } from 'antd/lib/table';
import styles from './style.module.less';
import { IDispatch } from '@/typings/IDva';
import { formatDate } from '@/util';

interface ILogProps {
    dispatch: IDispatch;
    logListPagination: {
        total: number,
        limit: number,
        offset: number,
    };
    logList: ILog[];
}

@connect(routeMapStateToProps)
class Log extends React.PureComponent<ILogProps> {
    constructor(props: ILogProps) {
        super(props);
        this.state = {
        };
    }

    columns: Array<ColumnProps<ILog>> = [
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'action',
            dataIndex: 'action',
            key: 'action',
        },
        {
            title: 'sourceId',
            dataIndex: 'sourceId',
            key: 'sourceId',
        },
        {
            title: 'before',
            dataIndex: 'before',
            key: 'before',
        },
        {
            title: 'after',
            dataIndex: 'after',
            key: 'after',
        },
        {
            title: 'user',
            dataIndex: 'user',
            key: 'user',
        },
        {
            title: 'createdAt',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (_id: number, record: ILog) => (formatDate(record.createdAt, 'yyyy-MM-dd hh:mm:ss')),
        },
    ];

    handleSubmit = (e: any) => {
        this.props.dispatch({
            type: 'logList/get',
            payload: {
                ...e
            },
        }).catch((err) => message.error(err.message || '数据获取失败，请重试'));
    }

    onShowSizeChange = (current: any, pageSize: any) => {
        this.props.dispatch({
            type: 'logList/get',
            payload: {
                offset: (current - 1) * pageSize,
                limit: pageSize,
            },
        }).catch((err) => message.error(err.message || '数据获取失败，请重试'));
    };

    componentDidMount(): void {
        const { dispatch } = this.props;
        dispatch({
            type: 'logList/get',
            payload: {
                offset: 0,
            },
        }).catch((err) => message.error(err.message || '数据获取失败，请重试'));
    }

    render() {
        const { logListPagination, dispatch, logList } = this.props;
        const {
            total,
            limit,
            // offset,
        } = logListPagination;
        return (
            <div className={styles.ctn}>
                <WrappedSearchForm
                    handleSubmit={(e) => this.handleSubmit(e)} />
                <Table
                    rowKey="id"
                    pagination={{
                        total,
                        pageSize: limit,
                        defaultCurrent: 1,
                        onChange: (page: number) => {
                            dispatch({
                                type: 'logList/get',
                                payload: {
                                    offset: (page - 1) * limit,
                                },
                            }).catch((err) => message.error(err.message || '数据获取失败，请重试'));
                        },
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '20', ' 50', ' 100'],
                        onShowSizeChange: this.onShowSizeChange
                    }}
                    dataSource={logList}
                    columns={this.columns} />
            </div>
        );
    }
}

export default Log;

interface ISearchFormProps extends FormComponentProps {
    handleSubmit: (e: any) => void;
}

class SearchForm extends React.Component<ISearchFormProps> {
    componentDidMount() {
    }

    check = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.handleSubmit(values);
            }
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form layout="inline">
                <Form.Item>
                    {getFieldDecorator('type', {
                        rules: [],
                    })(
                        <Input
                            placeholder="type" />
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('action', {
                        rules: [],
                    })(
                        <Input
                            placeholder="action" />
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('sourceId', {
                        rules: [],
                    })(
                        <Input
                            placeholder="sourceId" />
                    )}
                </Form.Item>
                <Form.Item>
                    <Button type="primary" onClick={this.check}>
                        搜索
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}

const WrappedSearchForm = Form.create<ISearchFormProps>()(SearchForm);
