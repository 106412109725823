import { API_BASE } from '@/util/const';
import fetch from '@/util/fetch';
import { IServiceResult, IdParams, IPageParams, IDocument, IDocumentCreate } from '@/typings/IServiceModel';

export const getList = (data: IPageParams): PromiseLike<IServiceResult> => {
    const {
        id,
        ...rest
    } = data;
    return fetch(`${API_BASE}/documentsets/${id}/documents`, {
        data: rest,
    });
};

export const getById = (data: IdParams): PromiseLike<IServiceResult> => {
    const { id } = data;
    return fetch(`${API_BASE}/documents/${id}`);
};

export const create = (data: IDocumentCreate): PromiseLike<IServiceResult> => {
    const {
        id,
        ...rest
    } = data;
    return fetch(`${API_BASE}/documentsets/${id}/documents`, {
        method: 'post',
        data: rest,
    });
};
export const update = (data: IDocument): PromiseLike<IServiceResult> => {
    const {
        id,
        ...rest
    } = data;
    return fetch(`${API_BASE}/documents/${id}`, {
        method: 'put',
        data: rest,
    });
};
export const remove = (data: IdParams): PromiseLike<IServiceResult> => {
    const {
        id,
    } = data;
    return fetch(`${API_BASE}/documents/${id}`, {
        method: 'delete',
    });
};
