/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */

import React, { FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'dva';
import { formatDate } from '@/util';
import {
    message, Select, Button, Modal, Input, Table, Popconfirm
} from 'antd';
import { routeMapStateToProps } from '@/util/helper';
import * as applyService from '@/service/apply';
import {
    IApply,
    IScene
} from '@/typings/IServiceModel';
import styles from './style.module.less';
import ExpandedRow from '../components/expandedRow';

interface IStatus {
    [key: number]: string;
}

const userIdentity: IStatus = {
    100: '成员',
    200: '管理员',
    50: '翻译人员',
};

const STATUS_MAP: IStatus = {
    0: '已拒绝',
    1: '待审核',
    2: '已通过',
};

interface IProps {
    login: {
        userInfo: {
            permission: number;
            scenes: IScene[]
        }
    };
}

const CheckCenter: FC<IProps> = ({ login }) => {
    const [userInfo, setUserInfo] = useState('');
    const [list, setList] = useState([]);
    const [status, setStatus] = useState<number>(-1);
    const [refuseVisible, setRefuseVisible] = useState(false);
    const [editAuthVisible, setEditAuthVisible] = useState(false);
    const [reason, setReason] = useState('');
    const [editPms, setEditPms] = useState(100);
    const [curItem, setCurItem] = useState<IApply>({ userId: 0, id: 0, status: 0 });

    const handleSearch = (value?: number) => {
        if (Number.isInteger(value)) {
            setStatus(Number(value));
        }
        applyService.getApplyCheckList({
            status: Number.isInteger(value) ? value : status,
            userInfo
        }).then((res) => {
            setList(res?.data?.result);
        });
    };

    const handApply = async (payload: IApply) => {
        try {
            const res = await applyService.updateApply(payload);
            if (res?.data?.result) {
                handleSearch();
                message.success('操作成功！');
            }
            return res;
        } catch (e) {
            message.error(e.message);
        }
    };

    const handleRefuse = async () => {
        if (!reason) {
            message.error('请输入拒绝理由');
            return;
        }
        const { id } = curItem;
        await handApply({
            status: 0,
            id,
            reason
        });
        setRefuseVisible(false);
    };

    // 权限编辑
    const handleEdit = async () => {
        const { id } = curItem;

        await handApply({
            status: 2,
            id,
            pms: editPms,
        });
        setEditAuthVisible(false);
    };

    const columns = [
        { title: '姓名', dataIndex: 'nickName', key: 'nickName' },
        { title: '邮箱', dataIndex: 'mailAddress', key: 'mailAddress' },
        { title: '所属应用', dataIndex: 'sceneName', key: 'sceneName' },
        {
            title: '申请权限', dataIndex: 'pms', key: 'pms', render: (it: any) => (userIdentity[it])
        },
        {
            title: '状态', dataIndex: 'status', key: 'status', render: (status: any) => (STATUS_MAP[status])
        },
        {
            title: '更新时间', dataIndex: 'updatedAt', key: 'updatedAt', render: (value: any) => (formatDate(value, 'yyyy-MM-dd hh:mm:ss'))
        },
        {
            title: '操作',
            dataIndex: '',
            key: 'x',
            render: (record: IApply) => (
                <div className={styles.actionDone}>
                    <Popconfirm
                        title="确认要审核通过吗？"
                        disabled={record.status !== 1}
                        onConfirm={() => {
                            const { userId, id } = record;
                            handApply({ status: 2, userId, id });
                        }}
                        okText="确认"
                        cancelText="取消">
                        <Button disabled={record.status !== 1} type="link">通过</Button>
                    </Popconfirm>
                    <Button
                        disabled={record.status !== 1}
                        onClick={() => {
                            setCurItem(record);
                            setRefuseVisible(true);
                        }}
                        type="link">
                        拒绝
                    </Button>
                    <Button
                        disabled={record.status !== 1}
                        onClick={() => {
                            setCurItem(record);
                            setEditAuthVisible(true);
                        }}
                        type="link">
                        权限编辑
                    </Button>
                </div>
            ),
        },
    ];

    const mngPms = useMemo(() => {
        if (login?.userInfo?.permission === 300) return 300;
        const currentScene = (login?.userInfo?.scenes).find((item) => item.id === curItem.sceneId);
        return currentScene?.scene_user?.pms;
    }, [curItem]);

    useEffect(() => {
        handleSearch();
    }, []);

    return (
        <div className={styles.pageCheckCenter}>
            <div className={styles.header}>
                <span style={{ marginRight: 10 }}>状态: </span>
                <div>
                    <Select defaultValue={status} style={{ width: 120 }} onChange={handleSearch}>
                        <Select.Option value={-1}>全部</Select.Option>
                        <Select.Option value={1}>待审核</Select.Option>
                        <Select.Option value={2}>已通过</Select.Option>
                        <Select.Option value={0}>已拒绝</Select.Option>
                    </Select>
                </div>
                <span style={{ marginLeft: 10, marginRight: 10 }}>用户: </span>
                <Input
                    value={userInfo}
                    onChange={(e) => {
                        setUserInfo(e.target.value);
                    }}
                    style={{ marginRight: 5, width: 220 }}
                    placeholder="请输入用户名或邮箱" />
                <Button
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                        handleSearch();
                    }}
                    type="primary">
                    搜索

                </Button>
            </div>
            <div className={styles.tableWrap}>
                <Table
                    columns={columns}
                    rowKey="id"
                    expandedRowRender={ExpandedRow}
                    dataSource={list} />
            </div>
            <Modal
                title="拒绝"
                visible={refuseVisible}
                onOk={() => {
                    handleRefuse();
                }}
                onCancel={() => {
                    setRefuseVisible(false);
                }}>
                <Input
                    placeholder="请输入拒绝理由"
                    value={reason}
                    onChange={(e) => {
                        setReason(e.target.value);
                    }} />
            </Modal>
            {/* 权限编辑弹窗 */}
            <Modal
                title="权限编辑"
                visible={editAuthVisible}
                onOk={() => {
                    handleEdit();
                }}
                onCancel={() => {
                    setEditAuthVisible(false);
                }}>
                <span>选择权限：</span>
                <Select
                    defaultValue={editPms}
                    style={{
                        width: 200
                    }}
                    onChange={(value: number) => {
                        setEditPms(value);
                    }}>
                    {
                        Object.keys(userIdentity).map((pmsCode) => (
                            <Select.Option key={pmsCode} disabled={+mngPms <= +pmsCode} value={+pmsCode}>
                                {userIdentity[+pmsCode]}
                            </Select.Option>
                        ))
                    }
                </Select>
            </Modal>
        </div>
    );
};
export default connect(routeMapStateToProps)(CheckCenter);
