import { API_BASE } from '@/util/const';
import fetch from '@/util/fetch';
import { IServiceResult, IdParams, IPageParams, IUserSceneParams, ISceneCreate, IScene } from '@/typings/IServiceModel';

export const getList = (data: IPageParams): PromiseLike<IServiceResult> => {
    return fetch(`${API_BASE}/scenes`, {
        data,
    });
};

export const getUserList = (data: IUserSceneParams): PromiseLike<IServiceResult> => {
    return fetch(`${API_BASE}/user/scenesList`, {
        data,
    });
};

export const getById = (data: IdParams): PromiseLike<IServiceResult> => {
    const { id } = data;
    return fetch(`${API_BASE}/scenes/${id}`);
};

export const create = (data: ISceneCreate): PromiseLike<IServiceResult> => {
    return fetch(`${API_BASE}/scenes`, {
        method: 'post',
        data: {
            ...data,
            language: (data.languages || []).join(','),
        },
    });
};
export const update = (data: IScene): PromiseLike<IServiceResult> => {
    const { id } = data;
    return fetch(`${API_BASE}/scenes/${id}`, {
        method: 'put',
        data: {
            ...data,
            language: (data.languages || []).join(','),
        },
    });
};
