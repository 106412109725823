import * as groupService from '@/service/group';
import {
    IGroupModel,
    IServiceResult,
    IGroup,
    ISceneModelState,
    IGroupCreate,
} from '@/typings/IServiceModel';

interface GetPayload {
    id: number;
}

// 曲库标签数据
const scene: IGroupModel = {
    namespace: 'group',

    state: {
        data: null,
        id: 0,
    },

    effects: {
        * getById(action, { call, put }) {
            const payload = action.payload as GetPayload;
            if (payload.id === 0) {
                yield put({
                    type: 'setState',
                    payload: {
                        data: null,
                        id: payload.id,
                    },
                });
                return;
            }
            try {
                const result: IServiceResult = yield call(groupService.getById, payload);
                yield put({
                    type: 'setState',
                    payload: {
                        data: result.data as IGroup,
                        id: payload.id,
                    },
                });
            } catch (err) {
                yield put({
                    type: 'setState',
                    payload: {
                        data: null,
                        id: payload.id,
                    },
                });
                throw err;
            }
        },
        * update(action, { call }) {
            const payload = action.payload as IGroup;
            yield call(groupService.update, payload);
        },
        * delete(action, { call }) {
            const payload = action.payload as IGroup;
            yield call(groupService.remove, payload);
        },
        * create(action, { call, select }) {
            const sceneId = yield select((state: { scene: ISceneModelState }) => state.scene.id);
            const payload = action.payload as IGroupCreate;
            yield call(groupService.create, {
                ...payload,
                id: sceneId,
            });
        },
    },

    reducers: {
        setState(state, { payload }) {
            return { ...state, ...payload };
        },
    },
};

export default scene;
