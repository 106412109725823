/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { FormComponentProps, ValidateCallback } from 'antd/lib/form';
import { IDocumentSetCreate, IGroupKeyValue, IGroup } from '@/typings/IServiceModel';
import { clientMap } from '@/util/const';
import {
    Input, Form, Select, Button, Icon,
} from 'antd';
import { get } from 'lodash';

interface IDocumentSetGroupFormProps extends FormComponentProps {
    onChange?: (value: IDocumentSetCreate) => void;
    dataSource?: IDocumentSetCreate;
    sceneLanguage: string[];
    waitToFillArr?: IGroupKeyValue[];
    keyUniqueCheck?: (value: string) => Promise<boolean>;
    keyGenerator?: () => Promise<string>;
    isCreatingDocument: boolean;
    i18n: any;
    groupId?: number;
    groupList: IGroup[];
}

export interface IDocumentSetGroupFormRef extends JSX.Element {
    preCheck: (cb: ValidateCallback<IDocumentSetCreate>) => {};
}

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};

class DocumentSetForm extends React.PureComponent<IDocumentSetGroupFormProps> {
    formEnd: HTMLDivElement | null = null;

    preCheck = (cb: ValidateCallback<IDocumentSetCreate>) => {
        this.props.form.validateFieldsAndScroll(cb);
    }

    trans = () => {
        const {
            i18n
        } = this.props;
        return i18n.getFixedT(null, ['textManage']);
    };

    renderWaitToFillItem = () => {
        const { waitToFillArr } = this.props;

        return (
            waitToFillArr && waitToFillArr.map((item) => (
                <div key={item.key}>
                    <span>key: </span>
                    <span style={{ marginRight: 20 }}>{item.key}</span>
                    <span>value: </span>
                    <span style={{ marginRight: 20 }}>{item.desc}</span>
                    <span>from: </span>
                    <span>{item.from}</span>
                </div>
            ))
        );
    };

    remove = (k: IGroupKeyValue) => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        if (keys.length === 1) {
            return;
        }

        form.setFieldsValue({
            keys: keys.filter((val: IGroupKeyValue) => val.key !== k.key),
        });
    };

    filter = () => {
        const { form, waitToFillArr } = this.props;
        const keys = form.getFieldValue('keys');
        const existKeys = waitToFillArr?.map((item: IGroupKeyValue) => item.val) || [];
        form.setFieldsValue({
            keys: keys.filter((val: any) => existKeys.includes(val.key)),
        });
    };

    scrollToBottom = () => {
        // eslint-disable-next-line
        this?.formEnd?.scrollIntoView({ behavior: 'smooth' });
    };

    add = () => {
        const { form } = this.props;
        const keys = form.getFieldValue('keys');
        const nextKeys = [
            ...keys,
            {
                key: keys[keys.length - 1].key + 1,
                desc: keys[keys.length - 1].desc + 1,
                from: keys[keys.length - 1].from + 1,
            }];
        form.setFieldsValue({
            keys: nextKeys,
        }, () => {
            this.scrollToBottom();
        });
    };

    render() {
        const {
            form,
            dataSource,
            waitToFillArr = [],
            keyUniqueCheck,
            isCreatingDocument,
            groupId,
            groupList
        } = this.props;
        const { getFieldDecorator, getFieldValue } = form;
        const trans = this.trans();

        getFieldDecorator('keys', {
            initialValue: [{
                key: 1,
                desc: 1,
                from: 1,
            }]
        });
        const keys = getFieldValue('keys');
        const formItems = keys.map((k: IGroupKeyValue, index: number) => (
            <div
                key={k.key}
                ref={(ref) => {
                    if (index === keys.length - 1) {
                        this.formEnd = ref;
                    }
                }}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                }}>
                {(
                    <div style={{
                        width: '80%',
                        border: '1px dashed #eee',
                    }}>
                        <Form.Item
                            label={`key${index + 1}`}
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 18 }}
                            key={`key${k.key}`}>
                            {getFieldDecorator(`key${k.key}`, {
                                validateFirst: true,
                                validateTrigger: ['onSubmit', 'onBlur'],
                                rules: [
                                    {
                                        required: true,
                                        message: ` ${trans('notNull')}`,
                                    },
                                    {
                                        max: 255,
                                        message: `${trans('lengthLimit')}`,
                                    },
                                    {
                                        pattern: /^[0-9a-zA-Z_]+$/,
                                        message: ` ${trans('textLimit')}`,
                                    },
                                    {
                                        validator: (_, value: string, cb) => {
                                            if (keyUniqueCheck && value) {
                                                keyUniqueCheck(value)
                                                    .then((result: boolean) => {
                                                        if (!result) {
                                                            cb(trans('existKey'));
                                                            return;
                                                        }
                                                        cb();
                                                    })
                                                    .catch((err) => {
                                                        cb(err.message || trans('checkFail'));
                                                    });
                                            } else {
                                                cb();
                                            }
                                        },
                                    },
                                ],
                            })(
                                <Input disabled={isCreatingDocument} />
                            )}
                        </Form.Item>
                        <Form.Item
                            label={`描述${index + 1}`}
                            key={`desc${k.desc}`}
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 18 }}>
                            {getFieldDecorator(`desc${k.desc}`, {
                                validateFirst: true,
                                rules: [
                                    {
                                        required: true,
                                        message: ` ${trans('notNull')}`,
                                    },
                                    {
                                        max: 255,
                                        message: `${trans('lengthLimit')}`,
                                    },
                                ],
                            })(
                                <Input disabled={isCreatingDocument} />
                            )}
                        </Form.Item>
                        <Form.Item
                            label={`${trans('requestFrom')}${index + 1}`}
                            key={`from${k.from}`}
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 18 }}>
                            {getFieldDecorator(`from${k.from}`, {
                                validateFirst: false,
                            })(
                                <Input disabled={isCreatingDocument} />
                            )}
                        </Form.Item>
                    </div>
                )}
                {
                    keys.length > 1 && !isCreatingDocument ? (
                        <Icon
                            className="dynamic-delete-button"
                            style={{
                                marginLeft: '20px',
                                fontSize: '25px',
                            }}
                            type="minus-circle-o"
                            onClick={() => this.remove(k)} />
                    ) : null
                }
            </div>
        ));

        return (
            <>
                {
                    waitToFillArr?.length > 0
                        ? (
                            <div>
                                {
                                    !isCreatingDocument ? (
                                        <Button
                                            type="primary"
                                            onClick={() => this.filter()}
                                            style={{ width: '20%' }}>
                                            清除已添加的文案
                                        </Button>
                                    ) : null
                                }
                                <div>
                                    <p>当前未被填充的键值对:</p>
                                    {this.renderWaitToFillItem()}
                                </div>

                            </div>
                        ) : null
                }
                <Form
                    {...formItemLayout}
                    layout="horizontal">
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginBottom: '10px',
                    }}>
                        <Button
                            type="primary"
                            onClick={() => this.add()}
                            style={{ width: '20%' }}
                            icon="plus-circle">
                            {trans('addText')}
                        </Button>
                    </div>
                    <Form.Item
                        label={trans('moduleSelect')}
                        key="groupId">
                        {getFieldDecorator('groupId', {
                            validateFirst: true,
                            initialValue: groupList.some((el) => el.id === groupId) ? groupId : undefined,
                            rules: [
                                {
                                    required: true,
                                    message: ` ${trans('notNull')}`,
                                },
                            ],
                        })(
                            <Select
                                showSearch
                                filterOption={(input, option) => {
                                    if (!option) return false;
                                    const children = option.props.children;
                                    return (Array.isArray(children)
                                        ? children.join('')
                                        : (children as string))
                                        .toLocaleLowerCase()
                                        .indexOf(input.toLocaleLowerCase()) >= 0;
                                }}
                            >
                                {groupList.map((item: any) => (
                                    <Select.Option key={item.id} value={item.id}>
                                        {item.name}{item.key ? `(${item.key})` : ''}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item
                        label={trans('client')}
                        key="client">
                        {getFieldDecorator('client', {
                            validateFirst: true,
                            initialValue: get(dataSource, 'client'),
                            rules: [
                                {
                                    required: true,
                                    message: ` ${trans('notNull')}`,
                                },
                            ],
                        })(
                            <Select mode="multiple">
                                {clientMap.map((item: { key: string, value: number }) => (
                                    <Select.Option key={item.key} value={item.value}>
                                        {item.key}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                    <div
                        style={{
                            maxHeight: '300px',
                            overflow: 'auto',
                        }}>
                        {formItems}
                    </div>
                </Form>
            </>
        );
    }
}

export default Form.create<IDocumentSetGroupFormProps>()(DocumentSetForm);
export const DocumentSetFormGroupWithoutFormProps = DocumentSetForm;
