/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */

import React, { FC } from 'react';
import {
    Timeline
} from 'antd';
import {
    IApply
} from '@/typings/IServiceModel';
import { formatDate } from '@/util';

interface IStatus {
    [key: number]: string;
}

const userIdentity: IStatus = {
    100: '成员',
    200: '管理员',
    300: '超级管理员',
    50: '翻译人员',
};

const STATUS_COLOR: IStatus = {
    0: 'red',
    1: 'blue',
    2: 'green'
};

const ExpandedRow: FC<IApply> = (props: IApply) => {
    const records = props.apply_records || [];
    return (
        <Timeline>
            {
                records.map((item) => {
                    const logContent = JSON.parse(item.snapshot);
                    return (
                        <Timeline.Item key={item.id} color={STATUS_COLOR[logContent.status]}>
                            {
                                logContent.status === 2 && (
                                    <>
                                        <p> 操作类型：审核通过</p>
                                        <p>
                                            操作人：
                                            {logContent.operator}
                                        </p>
                                        <p>
                                            申请应用：
                                            {logContent.sceneName}
                                            (
                                            {logContent.sceneAliasName}
                                            )
                                        </p>
                                        <p>
                                            授权权限：
                                            {userIdentity[logContent.pms]}
                                        </p>
                                        <p>
                                            操作时间：
                                            {formatDate(logContent.updatedAt, 'yyyy-MM-dd hh:mm:ss')}
                                        </p>
                                    </>
                                )
                            }
                            {
                                logContent.status === 1 && (
                                    <>
                                        <p>操作类型：发起申请</p>
                                        <p>
                                            申请人：
                                            {logContent.nickName}
                                        </p>
                                        <p>
                                            申请应用：
                                            {logContent.sceneName}
                                            (
                                            {logContent.sceneAliasName}
                                            )
                                        </p>
                                        <p>
                                            申请理由：
                                            {logContent.reason || '无'}
                                        </p>
                                        <p>
                                            申请权限：
                                            {userIdentity[logContent.pms]}
                                        </p>
                                        <p>
                                            申请时间：
                                            {formatDate(logContent.updatedAt, 'yyyy-MM-dd hh:mm:ss')}
                                        </p>
                                    </>
                                )
                            }
                            {
                                logContent.status === 0 && (
                                    <>
                                        <p>操作类型：拒绝</p>
                                        <p>
                                            操作人：
                                            {logContent.operator}
                                        </p>
                                        <p>
                                            拒绝理由：
                                            {logContent.reason}
                                        </p>
                                        <p>
                                            操作时间：
                                            {formatDate(logContent.updatedAt, 'yyyy-MM-dd hh:mm:ss')}
                                        </p>
                                    </>
                                )
                            }
                        </Timeline.Item>
                    );
                })
            }
        </Timeline>
    );
};

export default ExpandedRow;
