import { API_BASE } from '@/util/const';
import fetch from '@/util/fetch';
import { IServiceResult, ILanguage } from '@/typings/IServiceModel';

export const getList = (): PromiseLike<IServiceResult> => {
    return fetch(`${API_BASE}/languages`);
};

export const create = (data: ILanguage): Promise<IServiceResult> => {
    return fetch(`${API_BASE}/languages`, {
        method: 'post',
        data,
    });
};

export const edit = (data: ILanguage): Promise<IServiceResult> => {
    return fetch(`${API_BASE}/languages`, {
        method: 'put',
        data,
    });
};

export const deleteLang = (data: ILanguage): Promise<IServiceResult> => {
    return fetch(`${API_BASE}/languages/delete`, {
        method: 'post',
        data,
    });
};

export const createBatch = (data: any): PromiseLike<IServiceResult> => {
    return fetch(`${API_BASE}/languages/batch`, {
        method: 'post',
        data,
    });
};
