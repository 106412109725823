import React, { Fragment } from 'react';
import { FormComponentProps, ValidateCallback } from 'antd/lib/form';
import { IDocumentCreate, ILanguage } from '@/typings/IServiceModel';
import {
    Input, Form, Spin, message
} from 'antd';
import { get } from 'lodash';

interface IDocumentFormProps extends FormComponentProps {
    onChange?: (value: IDocumentCreate) => void;
    isNew: boolean;
    dataSource?: IDocumentCreate;
    languageList: ILanguage[];
    dataSourcePrefix?: string;
    updateService?: (value: IDocumentCreate, dataSource: IDocumentCreate) => Promise<boolean>;
}

interface IDocumentFormState {
    isUpdating: boolean;
}

class DocumentForm extends React.PureComponent<IDocumentFormProps, IDocumentFormState> {
    state = {
        isUpdating: false,
    };

    preCheck = (cb: ValidateCallback<IDocumentCreate>) => {
        this.props.form.validateFields(cb);
    }

    onBlur = () => {
        const {
            isNew,
            dataSource,
            updateService,
            dataSourcePrefix,
        } = this.props;

        const { isUpdating } = this.state;

        if (isNew || !updateService || isUpdating || !dataSource) {
            return;
        }

        this.preCheck((err, value) => {
            if (err) {
                return;
            }
            this.setState({
                isUpdating: true,
            });

            updateService({
                text: get(value, `${dataSourcePrefix}text`),
                id: get(value, `${dataSourcePrefix}id`),
                languageCode: get(value, `${dataSourcePrefix}languageCode`),
            }, dataSource).then((result) => {
                if (!result) {
                    throw new Error();
                }
                this.setState({
                    isUpdating: false,
                });
            }).catch((err) => {
                message.error(err.message || '文案更新失败');
                this.setState({
                    isUpdating: false,
                });
            });
        });
    }

    render() {
        const {
            form,
            dataSource,
            languageList,
            dataSourcePrefix = '',
        } = this.props;

        const { isUpdating } = this.state;

        if (!dataSource) {
            return null;
        }
        const { getFieldDecorator } = form;

        const label = languageList?.find((lang) => lang.code === dataSource.languageCode)?.desc
            || dataSource.languageCode;

        return (
            <Fragment>
                <Form.Item
                    style={{ display: 'none' }}
                    key="id">
                    {getFieldDecorator(`${dataSourcePrefix}id`, {
                        initialValue: dataSource.id || 0,
                    })(
                        <Input type="hidden" />
                    )}
                </Form.Item>
                <Form.Item
                    style={{ display: 'none' }}
                    key="languageCode">
                    {getFieldDecorator(`${dataSourcePrefix}languageCode`, {
                        initialValue: dataSource.languageCode,
                        rules: [
                            {
                                required: true,
                                message: '不得为空',
                            },
                        ],
                    })(
                        <Input type="hidden" />
                    )}
                </Form.Item>
                <Spin spinning={isUpdating}>
                    <Form.Item
                        label={`${label}: `}
                        key="text">
                        {getFieldDecorator(`${dataSourcePrefix}text`, {
                            validateFirst: true,
                            initialValue: get(dataSource, 'text', ''),
                        })(
                            <Input onBlur={this.onBlur} />
                        )}
                    </Form.Item>
                </Spin>
            </Fragment>
        );
    }
}

export default Form.create<IDocumentFormProps>()(DocumentForm);
export const DocumentFormWithoutFormProps = DocumentForm;
