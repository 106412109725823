import React from 'react';
import { FormComponentProps, ValidateCallback } from 'antd/lib/form';
import { ISceneCreate, ILanguage, ILogin } from '@/typings/IServiceModel';
import {
    Input, Form, Checkbox,
    Modal, message, Tooltip, Icon
} from 'antd';
import { get } from 'lodash';
import copy from 'copy-to-clipboard';

interface ISceneFormProps extends FormComponentProps {
    onChange?: (value: ISceneCreate) => void;
    isNew: boolean;
    dataSource?: ISceneCreate;
    languageList?: ILanguage[];
    login?: ILogin;
}

interface ISceneFormStates {
    isModalVisible: boolean;
    checkedLanguages: string[];
    languageMap: Record<string, string>;
    receiver: Record<string, string>;
}

export interface ISceneFormRef extends JSX.Element {
    preCheck: (cb: ValidateCallback<ISceneCreate>) => {};
}

const IMG_CONFIG = 'https://p5.music.126.net/obj/wonDlsKUwrLClGjCm8Kx/11838140143/75bb/68fa/06b3/855ed48e13a699cba175d1d70a4e4269.gif';
const IMG_ADD_ROBOT = 'https://p6.music.126.net/obj/wonDlsKUwrLClGjCm8Kx/11837843022/4350/2292/7905/816bb1dabb5750f6fadd16efeb5a03cf.gif';
const IMG_SEND = 'https://p6.music.126.net/obj/wonDlsKUwrLClGjCm8Kx/11838181945/b8cd/3d9e/86df/80f04799e34c28bf4a314eecc225acfc.jpg';
const IMG_RESULT = 'https://p5.music.126.net/obj/wonDlsKUwrLClGjCm8Kx/11838149471/d209/9fcb/585f/3cf9205548e7a454c7bc48efa4aa368d.jpg';

class SceneForm extends React.PureComponent<ISceneFormProps, ISceneFormStates> {

    static defaultProp = {
        isNew: false,
    };

    constructor(props: ISceneFormProps) {
        super(props);
        const { languageList = [], dataSource } = props;
        const languageMap = languageList?.reduce((acc, language) => {
            acc[language.code] = language.desc;
            return acc;
        }, {} as Record<string, string>);
        let receiver: Record<string, string>;

        try {
            receiver = JSON.parse(get(dataSource, 'receiver', '{}') || '{}');
        } catch (err) {
            receiver = {};
        }

        this.state = {
            isModalVisible: false,
            checkedLanguages: get(dataSource, 'languages', []),
            languageMap,
            receiver
        };
    }

    preCheck = (cb: ValidateCallback<ISceneCreate>) => {
        this.props.form.validateFieldsAndScroll((errors, value) => {
            const receiver = Object.keys(value.receiver).reduce((acc, code) => {
                if (value.receiver[code]) {
                    acc[code] = value.receiver[code];
                }
                return acc;
            }, {} as Record<string, string>);
            value.receiver = JSON.stringify(receiver);
            cb(errors, value);
        });
    };

    menu = () => (
        <div style={{
            boxSizing: 'border-box',
            background: '#fff',
            height: '450px',
            width: '1000px',
            overflowY: 'scroll',
        }}>
            <div style={{ marginTop: '10px' }}>
                <p>1.首先在多语言系统添加或创建应用时可配置添加完文案之后的推送人(具体邮箱)或者群，用小写的逗号分隔</p>
                <img src={IMG_CONFIG} />
            </div>
            <div style={{ marginTop: '10px' }}>
                <p>2.如果需要推送到群组，请先把推送机器人账号添加至群组内(grp.alarm.center@corp.netease.com)</p>
                <img src={IMG_ADD_ROBOT} />
            </div>
            <div style={{ marginTop: '10px' }}>
                <p>3.选择相应的模块，新增完所有文案之后点击推送</p>
                <img style={{ width: '960px' }} src={IMG_SEND} />
            </div>
            <div style={{ marginTop: '10px' }}>
                <p>4.推送结果：</p>
                <img style={{ width: '960px' }} src={IMG_RESULT} />
            </div>
        </div>
    );

    render() {
        const {
            isNew,
            form,
            languageList = [],
            dataSource,
            login,
        } = this.props;
        const { isModalVisible, checkedLanguages, languageMap, receiver } = this.state;
        const defaultLanguage: string[] = get(dataSource, 'languages', []);
        const currentScene = login?.userInfo?.scenes?.find((el) => el.id === dataSource?.id);
        let pms = 0;
        if (currentScene) {
            pms = currentScene.scene_user?.pms;
        } else {
            pms = login?.userInfo?.permission || 0;
        }

        const defaultEnableLanguage: string[] = defaultLanguage?.filter(
            (lng) => dataSource?.scene?.languages?.some(
                (language: any) => language.code === lng && language?.scene_language?.enable
            )
        );
        const { getFieldDecorator } = form;

        const toggleModal = () => {
            this.setState({
                isModalVisible: !isModalVisible
            });
        };

        const copyRobotMail = () => {
            if (copy('grp.alarm.center@corp.netease.com')) {
                message.success('复制成功');
            } else {
                message.error('复制失败');
            }
        };

        const onChange = (checkedValues: any[]) => {
            if (checkedValues?.length > defaultLanguage?.length) {
                message.warn('该操作不可逆，请谨慎操作！！');
            }
            this.setState({
                checkedLanguages: checkedValues,
            });
        };

        const onChangeEnable = (checkedValues: any[]) => {
            const sceneLanguage = form.getFieldValue('languages');
            if (checkedValues.some((item) => !sceneLanguage.includes(item))) {
                message.error('请先选择对应的应用语言，否则改动无效!!');
                // eslint-disable-next-line no-unused-expressions
                form?.setFieldsValue({
                    enableLanguages: checkedValues?.filter(
                        (lng: string) => sceneLanguage.includes(lng)
                    )
                });
            }
        };

        return (
            <>
                <Modal
                    title="推送配置引导"
                    width="1050px"
                    visible={isModalVisible}
                    onOk={toggleModal}
                    onCancel={toggleModal}>
                    {this.menu()}
                </Modal>
                <Form
                    hideRequiredMark>
                    <Form.Item
                        label="应用名(用于展示)"
                        key="name">
                        {getFieldDecorator('name', {
                            validateFirst: true,
                            initialValue: get(dataSource, 'name', ''),
                            rules: [
                                {
                                    required: true,
                                    message: ' 不得为空',
                                },
                                {
                                    max: 255,
                                    message: '不得大于255个字符',
                                },
                            ],
                        })(
                            <Input />
                        )}
                    </Form.Item>
                    <Form.Item
                        label="应用英文名(用于生成语言包)"
                        key="aliasName">
                        {getFieldDecorator('aliasName', {
                            validateFirst: true,
                            initialValue: get(dataSource, 'aliasName', ''),
                            rules: [
                                {
                                    required: true,
                                    message: ' 不得为空',
                                },
                                {
                                    max: 255,
                                    message: '不得大于255个字符',
                                },
                                {
                                    pattern: /^[0-9a-zA-Z_-]+$/,
                                    message: ' 只能使用数字、英文、下划线(_)、中划线(-)',
                                },
                            ],
                        })(
                            <Input disabled={!isNew} />
                        )}
                    </Form.Item>
                    <div className="ant-col ant-form-item-label">
                        <label>推送消息接收人</label>
                    </div>
                    <div className="ant-form-extra">
                        <p style={{ lineHeight: '20px' }}>
                            <span>推送群组请先往群组中添加机器人账号</span>
                            <span
                                style={{
                                    marginLeft: 5, color: '#1890ff', cursor: 'pointer', lineHeight: '20px'
                                }}
                                onClick={copyRobotMail}>
                                grp.alarm.center@corp.netease.com (点此复制账号)
                            </span>
                        </p>
                    </div>
                    <Form.Item
                        label={
                            <span>
                                全量&nbsp;
                                <Tooltip title="全量推送时，会列出所有语种待翻译文案。且当单语种未设置推送人时，以全量配置兜底。">
                                    <Icon type="question-circle-o" />
                                </Tooltip>
                            </span>
                        }
                        key="receiver.all"
                        labelCol={{ span: 5 }}
                        wrapperCol={{ span: 19 }}>
                        {getFieldDecorator('receiver.all', {
                            initialValue: receiver.all || '',
                        })(
                            <Input placeholder="请填写推送接收人crop邮箱地址/群号，以英文逗号分隔" />
                        )}
                    </Form.Item>
                    {checkedLanguages.map((code) => (
                        <Form.Item
                            label={languageMap[code]}
                            key={code}
                            labelCol={{ span: 5 }}
                            wrapperCol={{ span: 19 }}>
                            {getFieldDecorator(`receiver[${code}]`, {
                                initialValue: receiver[code] || '',
                                rules: [
                                    {
                                        max: 255,
                                        message: '不得大于255个字符',
                                    },
                                    {
                                        pattern: /^[0-9a-zA-Z@,\\.]+$/,
                                        message: ' 只能使用数字、英文、小写逗号以及邮箱字符',
                                    },
                                ],
                            })(
                                <Input placeholder="请填写推送接收人crop邮箱地址/群号，以英文逗号分隔" />
                            )}
                        </Form.Item>
                    ))}
                    <Form.Item
                        label=" 应用语言"
                        key="languages">
                        {getFieldDecorator('languages', {
                            validateFirst: true,
                            initialValue: defaultLanguage,
                            rules: [
                                {
                                    required: true,
                                    message: '不得为空',
                                },
                            ],
                        })(
                            <Checkbox.Group onChange={(val) => onChange(val)}>
                                {languageList.map((language: ILanguage) => {
                                    if (!language) {
                                        return null;
                                    }
                                    return (
                                        <Checkbox
                                            disabled={![200, 300].includes(+pms)
                                            || (!isNew && defaultLanguage.includes(language.code))}
                                            key={language.code}
                                            value={language.code}>
                                            {language.desc}
                                        </Checkbox>
                                    );
                                })}
                            </Checkbox.Group>
                            // <Select mode="multiple">
                            //     {languageList.map((language: ILanguage) => {
                            //         return (
                            //             <Select.Option key={language.code}
                            //            value={language.code}>{language.desc}</Select.Option>
                            //         );
                            //     })}
                            // </Select>,
                        )}
                    </Form.Item>
                    <Form.Item
                        label=" 是否启用(仅针对调用了平台的语种列表接口)"
                        key="enableLanguages">
                        {getFieldDecorator('enableLanguages', {
                            initialValue: defaultEnableLanguage,
                        })(
                            <Checkbox.Group onChange={(val) => onChangeEnable(val)}>
                                {languageList.map((language: ILanguage) => {
                                    if (!language) {
                                        return null;
                                    }
                                    return (
                                        <Checkbox
                                            disabled={![200, 300].includes(+pms)}
                                            key={language.code}
                                            value={language.code}>
                                            {language.desc}
                                        </Checkbox>
                                    );
                                })}
                            </Checkbox.Group>
                        )}
                    </Form.Item>
                </Form>
            </>
        );
    }
}

export default Form.create<ISceneFormProps>()(SceneForm);
