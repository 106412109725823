/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import {
    Button,
    Form,
    Input,
    message,
    Radio
} from 'antd';
import { connect } from 'dva';
import { IRouteProps } from '@/typings/IRoute';
import { routeMapStateToProps } from '@/util/helper';

interface IPublishPageState {
    confirmLoading: boolean;
    versions: any;
    currentVersion: string;
}

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};
const labelMap = {
    STATUS_SUCESS: '发布成功',
    STATUS_FAILURE: '发布失败',
    MESSAGE_UNSELECTED_LANGUAGE: '未选择下载语言',
};

type IPublishFormProps = IRouteProps & {
    sceneId: string;
    form: any;
    hideModal: any;
    publishVersions: any;
};

@connect(routeMapStateToProps)
export default class PublishForm extends React.PureComponent<IPublishFormProps, IPublishPageState> {
    state = {
        confirmLoading: false,
        versions: [],
        currentVersion: '',
    };

    onPublish = (e: any) => {
        e.preventDefault();
        const { form, dispatch, sceneId } = this.props;
        form.validateFields((err: any) => {
            if (!err) {
                this.setState({
                    confirmLoading: true,
                });

                const params = form.getFieldsValue() || {};

                dispatch({
                    type: 'publish/create',
                    payload: {
                        sceneId,
                        ...params,
                    },
                }).then((res: any) => {
                    if (res.code === 200) {
                        message.info(labelMap.STATUS_SUCESS);
                        form.resetFields();
                        this.setState({
                            confirmLoading: false,
                        });
                        this.props.hideModal();
                    } else {
                        message.info(res.message, 5000);
                        this.setState({
                            confirmLoading: true,
                        });
                    }
                }).catch((err) => {
                    message.error(err.message);
                    this.setState({
                        confirmLoading: false,
                    });
                });
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { confirmLoading } = this.state;

        return (
            <Form {...layout}>
                <Form.Item
                    label="发布描述"
                    required>
                    {
                        getFieldDecorator('description', {
                            rules: [
                                {
                                    required: true,
                                    message: '请输入发布描述，最长500个字！!',
                                },
                            ],
                        })(
                            <Input.TextArea
                                placeholder="请输入发布描述，最长500个字！"
                                autoSize={{ minRows: 3, maxRows: 8 }}
                                maxLength={500} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label="是否同时上传国内CDN："
                    required>
                        {
                        getFieldDecorator('uploadInland', {
                            initialValue: false,
                            rules: [
                                {
                                    required: true,
                                    message: '',
                                },
                            ],
                        })(
                            <Radio.Group defaultValue={false}>
                                {[false, true]?.map((opt, idx) => (
                                    <Radio value={opt} key={idx}>
                                        {opt ? '是' : '否'}
                                    </Radio>
                                ))}
                            </Radio.Group>
                        )
                    }
                </Form.Item>
                <Form.Item
                    label="name不包含模块key\n且不需要文案特殊处理"
                    required>
                        {
                        getFieldDecorator('isFileUnSpecialDeal', {
                            initialValue: false,
                            rules: [
                                {
                                    required: true,
                                    message: '',
                                },
                            ],
                        })(
                            <Radio.Group defaultValue={false}>
                                {[false, true]?.map((opt, idx) => (
                                    <Radio value={opt} key={idx}>
                                        {opt ? '是' : '否'}
                                    </Radio>
                                ))}
                            </Radio.Group>
                        )
                    }
                </Form.Item>
                <Form.Item
                    label="是否发布前端CDN？"
                    required>
                        {
                        getFieldDecorator('includeFrontendData', {
                            initialValue: false,
                            rules: [
                                {
                                    required: true,
                                    message: '',
                                },
                            ],
                        })(
                            <Radio.Group defaultValue={false}>
                                {[false, true]?.map((opt, idx) => (
                                    <Radio value={opt} key={idx}>
                                        {opt ? '是' : '否'}
                                    </Radio>
                                ))}
                            </Radio.Group>
                        )
                    }
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        onClick={this.onPublish}
                        loading={confirmLoading}>
                        发布
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}
