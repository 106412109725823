import * as userService from '@/service/user';
import { IUserModel } from '@/typings/IServiceModel';
import { message } from 'antd';

// 曲库标签数据
const scene: IUserModel = {
    namespace: 'user',

    state: {
        data: null,
    },

    effects: {
        * get(_0, { call, put }) {
            try {
                const result = yield call(userService.get);
                yield put({
                    type: 'setState',
                    payload: {
                        data: result.data,
                    },
                });
            } catch (err) {
                message.error(err.message);
                yield put({
                    type: 'setState',
                    payload: {
                        data: null,
                    },
                });
            }
        },
    },

    reducers: {
        setState(state, { payload }) {
            return { ...state, ...payload };
        },
    },
};

export default scene;
