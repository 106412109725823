import * as service from '@/service/publish';
import {
    IServiceResult,
    IDocumentCreate,
    IPublishModel,
    IDocument
} from '@/typings/IServiceModel';

// 发布管理
const publish: IPublishModel = {
    namespace: 'publish',

    state: {
        data: null,
        sceneId: 0,
        versions: [],
        total: 0,
        size: 10,
        current: 1,
    },

    effects: {
        * getList(action, { call, put }) {
            const payload = action.payload;
            try {
                const result: IServiceResult = yield call(service.getList, payload);

                yield put({
                    type: 'setState',
                    payload: {
                        data: result!.data!.data,
                        sceneId: payload.sceneId,
                        current: result!.data!.page!.current,
                        size: result!.data!.page!.size,
                        total: result!.data!.page!.total,
                    },
                });
            } catch (err) {
                yield put({
                    type: 'setState',
                    payload: {
                        data: null,
                        sceneId: payload.sceneId,
                        current: 1,
                    },
                });
                throw err;
            }
        },
        * getVersions(action, { call, put }) {
            const payload = action.payload;
            try {
                const result: IServiceResult = yield call(service.getVersions, payload);

                yield put({
                    type: 'setState',
                    payload: {
                        versions: result.data,
                    },
                });
            } catch (err) {
                yield put({
                    type: 'setState',
                    payload: {
                        versions: [],
                    },
                });
                throw err;
            }
        },
        * update(action, { call }) {
            const payload = action.payload as IDocument;
            return yield call(service.update, payload);
        },
        * sync(action, { call }) {
            const payload = action.payload as IDocument;
            return yield call(service.sync, payload);
        },
        * query(action, { call, put }) {
            const payload = action.payload as IDocument;
            try {
                const result: IServiceResult = yield call(service.query, payload);

                yield put({
                    type: 'setState',
                    payload: {
                        data: result.data,
                    },
                });
            } catch (err) {
                // yield put({
                //     type: 'setState',
                //     payload: {
                //         data: [],
                //     },
                // });
                throw err;
            }
        },
        * create(action, { call }) {
            const payload = action.payload as IDocumentCreate;
            return yield call(service.create, payload);
        },
        * getFebaseApps(action, { call }) {
            const payload = action.payload as IDocument;
            return yield call(service.getFebaseApps, payload);
        },
        * getProjectBranch(action, { call }) {
            const payload = action.payload as IDocument;
            return yield call(service.getProjectBranch, payload);
        },
        * publishApp(action, { call }) {
            const payload = action.payload as IDocument;
            return yield call(service.publishApp, payload);
        },
    },

    reducers: {
        setState(state, { payload }) {
            return { ...state, ...payload };
        },
    },
};

export default publish;
