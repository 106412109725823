import { IRouteMapStateToProps, IRouteModel } from '@/typings/IRoute';
import memoize from 'memoize-one';
import { ILanguage, ILanguageMap } from '@/typings/IServiceModel';
import { pick } from 'lodash';
import XLSX from 'xlsx';

const getLanguageMap: (languageList: ILanguage[]) => ILanguageMap = memoize((languageList: ILanguage[]): ILanguageMap => {
    if (languageList.length === 0) {
        return {};
    }
    return languageList.reduce((pre: ILanguageMap, item: ILanguage) => {
        pre[item.code] = item.desc;
        return pre;
    }, {});
});

export const routeMapStateToProps = (state: IRouteMapStateToProps): IRouteModel => ({
    login: state.login.data,
    language: state.language.data || [],
    user: (state.user.data || null),
    loading: state.loading || {},
    sceneList: state.sceneList.data || [],
    scene: state.scene.data || null,
    groupList: state.groupList.data || [],
    publishList: state.publish || {},
    appsList: state.apps || {},
    publishVersions: state.publish.versions || [],
    group: state.group.data || null,
    documentSet: state.documentSet.data || null,
    documentSetList: state.documentSetList.data || [],
    logList: state.logList.data || [],
    packageList: state.packageList.data || [],
    languageMap: getLanguageMap(state.language.data || []),
    sceneListPagination: pick(state.sceneList, ['total', 'limit', 'offset']),
    groupListPagination: pick(state.groupList, ['total', 'limit', 'offset']),
    documentSetListPagination: pick(state.documentSetList, ['total', 'limit', 'offset']),
    logListPagination: pick(state.logList, ['total', 'limit', 'offset']),
    packageListPagination: pick(state.packageList, ['total', 'limit', 'offset']),
});

type ReactText = string | number;

export const downloadXLSX = ({ columnNames, rowDatas, fileName }: {
    columnNames: ReactText[],
    rowDatas: ReactText[][],
    fileName: string
}) => {
    const worksheet = XLSX.utils.aoa_to_sheet([
        columnNames,
        ...rowDatas,
    ]);
    // 导出并下载
    const newWorkbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(newWorkbook, worksheet, 'SheetJS');
    XLSX.writeFile(newWorkbook, `${fileName}.xlsx`);
};

// 以json的方式自动对齐表头信息
export const downloadXLSXOfJson = ({ rowDatas, fileName }: {
    rowDatas: ReactText[][],
    fileName: string
}) => {
    const worksheet = XLSX.utils.json_to_sheet([
        ...rowDatas,
    ]);
    // 导出并下载
    const newWorkbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(newWorkbook, worksheet, 'SheetJS');
    XLSX.writeFile(newWorkbook, fileName + '.xlsx');
};
