import * as languageService from '@/service/language';
import { ILanguageModel, ILanguage, IServiceResult } from '@/typings/IServiceModel';

// 曲库标签数据
const scene: ILanguageModel = {
    namespace: 'language',

    state: {
        data: [],
    },

    effects: {
        * get(...args) {
            const { call, put } = args[1];
            try {
                const result: IServiceResult = yield call(languageService.getList);
                yield put({
                    type: 'setState',
                    payload: {
                        data: result.data as ILanguage[],
                    },
                });
            } catch (err) {
                yield put({
                    type: 'setState',
                    payload: {
                        data: [],
                    },
                });
                throw err;
            }
        },
    },

    reducers: {
        setState(state, { payload }) {
            return { ...state, ...payload };
        },
    },
};

export default scene;
