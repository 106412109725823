import React from 'react';
import { ModalProps } from 'antd/lib/modal';
import { Modal, message } from 'antd';
import IDocumentSetForm, { IDocumentSetFormRef } from '@/component/DocumentSetForm';
import IDocumentSetGroupForm, { IDocumentSetGroupFormRef } from '@/component/DocumentSetGroupForm';
import { IDocumentSetCreate, ILanguage, ILanguageMap, IGroupKeyValue, IGroup } from '@/typings/IServiceModel';
import { docsValid, clientEnum } from '@/util/docsValid';

interface IDocumentSetFormModalProps extends ModalProps {
    onSubmit: (value: IDocumentSetCreate) => void;
    isNew?: boolean;
    groupId?: number;
    groupList: IGroup[];
    isBatch?: boolean;
    sceneLanguage: string[];
    languageList: ILanguage[];
    dataSource?: IDocumentSetCreate;
    languageMap: ILanguageMap;
    keyUniqueCheck?: (value: string) => Promise<boolean>;
    keyGenerator?: () => Promise<string>;
    waitToFillArr?: IGroupKeyValue[];
    isCreatingDocument?: boolean;
    i18n: any;
}

export default class DocumentSetFormModal extends React.PureComponent<IDocumentSetFormModalProps> {
    private ref: IDocumentSetFormRef | IDocumentSetGroupFormRef | null = null;

    trans = () => {
        const {
            i18n
        } = this.props;
        return i18n.getFixedT(null, ['textManage']);
    };

    onOk = (): void => {
        if (!this.ref) {
            return;
        }

        this.ref.preCheck((errors, value: IDocumentSetCreate) => {
            const { onSubmit } = this.props;
            const trans = this.trans();

            if (errors) {
                return;
            }

            const { client, documents } = value;
            if (client && documents) {
                for (const cItem of client) {
                    const clientItem = parseInt(cItem);
                    for (const dItem of documents) {
                        const docItemText = dItem.text;
                        const validMsg = docsValid(clientItem, docItemText);
                        if (validMsg) {
                            message.error(`${trans('hasUselessWord')}${clientEnum[clientItem].client} ${validMsg}`);
                            return;
                        }
                    }
                }
            }

            if (onSubmit) {
                onSubmit(value);
            }
        });
    }

    modalTitle = (): string => {
        const trans = this.trans();
        const { isBatch, isNew } = this.props;
        if (isBatch) return trans('batchTextCreate');
        if (isNew) return trans('textCreate');
        return trans('textEdit');
    }

    render() {
        const {
            onOk,
            onSubmit,
            isNew,
            dataSource,
            languageMap,
            languageList,
            sceneLanguage,
            keyUniqueCheck,
            keyGenerator,
            isBatch,
            waitToFillArr,
            isCreatingDocument,
            i18n,
            groupId,
            groupList,
            ...rest
        } = this.props;
        return (
            <Modal
                keyboard={false}
                destroyOnClose
                maskClosable={false}
                onOk={this.onOk}
                width={760}
                title={this.modalTitle()}
                {...rest}
            >
                {
                    isBatch
                        ? <IDocumentSetGroupForm
                            i18n={i18n}
                            groupId={groupId}
                            groupList={groupList}
                            isCreatingDocument={isCreatingDocument!}
                            keyUniqueCheck={keyUniqueCheck}
                            waitToFillArr={waitToFillArr}
                            keyGenerator={keyGenerator}
                            sceneLanguage={sceneLanguage}
                            dataSource={dataSource}
                            wrappedComponentRef={(ref: IDocumentSetFormRef) => {
                                this.ref = ref;
                            }} />
                        : <IDocumentSetForm
                            i18n={i18n}
                            groupId={groupId}
                            groupList={groupList}
                            isNew={isNew!}
                            keyUniqueCheck={keyUniqueCheck}
                            keyGenerator={keyGenerator}
                            languageList={languageList}
                            languageMap={languageMap}
                            sceneLanguage={sceneLanguage}
                            dataSource={dataSource}
                            wrappedComponentRef={(ref: IDocumentSetGroupFormRef) => {
                                this.ref = ref;
                            }}
                        />
                }
            </Modal>
        );
    }
}
